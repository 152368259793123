//eslint-disable-next-line
import React, { Component, useState } from 'react'
import Header from '../../../../components/Header/Header'
import './InputView.scss'
import '../../../../indexroi.scss'
import inputBar from '../../../../assets/images/roi/inputBar.svg'
import back from '../../../../assets/images/back.svg'
import roiInactive from '../../../../assets/images/roi/roiInactive.svg'
import roiActive from '../../../../assets/images/roi/roiActive.svg'
import spin from '../../../../assets/images/roi/spin.svg'
import ok from '../../../../assets/images/roi/ok.svg'

class InputView extends Component {
  constructor (props) {
    super(props)
    let local = JSON.parse(localStorage.getItem('udgbl'))
    let currencyValue = parseFloat(
      local.roiData[localStorage.getItem('currentProg')].companyDetails
        .currencyValue
    )
    let selPrograms = localStorage.getItem('currentSelectedProg').split(',')
    let fromPage = localStorage.getItem('from')
    if (fromPage === 'add') {
      selPrograms = localStorage.getItem('currentSelectedProgAdd').split(',')
    }
    if (fromPage === 'bundle') {
      let selProgramsTemp = localStorage
        .getItem('currentSelectedProgBundle')
        .split(',')
      selPrograms.forEach((item, i) => {
        if (selProgramsTemp[i] === 'true' && item === 'true') {
          selProgramsTemp[i] = 'false'
        }
        if (selProgramsTemp[i] === 'true' && item === 'false') {
          selPrograms[i] = 'true'
        }
      })
      localStorage.setItem('currentSelectedProg', selPrograms)
      selPrograms = selProgramsTemp
    }
    selPrograms.forEach((item, i) => {
      selPrograms[i] = item === 'false' ? false : true
    })
    let roi = local.roiData[localStorage.getItem('currentProg')]
    this.state = {
      isRoiActive: false,
      showLimit: false,
      showLimitText: '',
      selected: selPrograms,
      userId: local.id,
      local: local,
      showCalculateRoi: false,
      currency:
        local.roiData[localStorage.getItem('currentProg')].companyDetails
          .currency,
      projectionName:
        local.roiData[localStorage.getItem('currentProg')].projectionName,
      forWho: local.roiData[localStorage.getItem('currentProg')].forWho,
      from: fromPage,
      sanitary:
        roi.programmeDetails && roi.programmeDetails[0]
          ? roi.programmeDetails[0]
          : {
              programNo: 0,
              costPerPad:
                Math.round((0.3 * currencyValue + Number.EPSILON) * 100) / 100,
              percentageCoveredByCompany: 100,
              miscAdminCost: 0,
              absentNo: 0,
              costSavingsAbsenteeism: 0,
              savingsFromIntervention: 0,
              totalCost: 0,
              annualRoi: 0,
              projectedRoi: 0,
              percentAbsent: 21
            },
      srh:
        roi.programmeDetails && roi.programmeDetails[1]
          ? roi.programmeDetails[1]
          : {
              programNo: 1,
              hoursOfTraining: 160,
              hourlyTrainingCost:
                Math.round((20 * currencyValue + Number.EPSILON) * 100) / 100,
              totalStationeryCost:
                Math.round((400 * currencyValue + Number.EPSILON) * 100) / 100,
              costOfRefreshment:
                Math.round((400 * currencyValue + Number.EPSILON) * 100) / 100,
              miscAdminCost: 0,
              workerTurnover: 0,
              replacementCost: 0,
              totalCost: 0,
              costSavingsWorkerTurnover: 0,
              absentNoLB: 0,
              absentNoUB: 0,
              costSavingsAbsenteeismLB: 0,
              costSavingsAbsenteeismUB: 0,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              savingsFromInterventionUB: 0,
              savingsFromInterventionLB: 0,
              annualRoiUB: 0,
              annualRoiLB: 0,
              projectedRoiUB: 0,
              projectedRoiLB: 0,
              lossOfProductivityFromTraining: 0,
              percentAbsentUB: 25,
              percentAbsentLB: 24,
              percentProductivity: 9.5,
              percentWorkerTurnover: 6
            },
      peer:
        roi.programmeDetails && roi.programmeDetails[2]
          ? roi.programmeDetails[2]
          : {
              programNo: 2,
              hoursOfTraining: 80,
              hourlyTrainingCost:
                Math.round((20 * currencyValue + Number.EPSILON) * 100) / 100,
              totalStationeryCost:
                Math.round((500 * currencyValue + Number.EPSILON) * 100) / 100,
              costOfRefreshment:
                Math.round((200 * currencyValue + Number.EPSILON) * 100) / 100,
              miscAdminCost: 0,
              workerTurnoverUB: 0,
              workerTurnoverLB: 0,
              replacementCost: 0,
              costSavingsWorkerTurnoverUB: 0,
              costSavingsWorkerTurnoverLB: 0,
              absentNo: 0,
              costSavingsAbsenteeism: 0,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              savingsFromInterventionUB: 0,
              savingsFromInterventionLB: 0,
              totalCost: 0,
              annualRoiUB: 0,
              annualRoiLB: 0,
              projectedRoiUB: 0,
              projectedRoiLB: 0,
              lossOfProductivityFromTraining: 0,
              percentAbsent: 11,
              percentProductivity: 22,
              percentWorkerTurnoverUB: 46,
              percentWorkerTurnoverLB: 4.5
            },
      subsidize:
        roi.programmeDetails && roi.programmeDetails[3]
          ? roi.programmeDetails[3]
          : {
              programNo: 3,
              healthPersonnelSalary:
                Math.round((1600 * currencyValue + Number.EPSILON) * 100) / 100,
              transportCost:
                Math.round((2400 * currencyValue + Number.EPSILON) * 100) / 100,
              medicalConsumables:
                Math.round((16000 * currencyValue + Number.EPSILON) * 100) /
                100,
              miscExpenses:
                Math.round((4000 * currencyValue + Number.EPSILON) * 100) / 100,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              savingsFromIntervention: 0,
              totalCost: 0,
              annualRoi: 0,
              projectedRoi: 0,
              percentProductivity: 15
            },
      clinic:
        roi.programmeDetails && roi.programmeDetails[4]
          ? roi.programmeDetails[4]
          : {
              programNo: 4,
              oneTimeConstructionCost:
                Math.round((1000 * currencyValue + Number.EPSILON) * 100) / 100,
              annualMedicineInventoryCost:
                Math.round((12000 * currencyValue + Number.EPSILON) * 100) /
                100,
              medicalInstrumentCost:
                Math.round((1000 * currencyValue + Number.EPSILON) * 100) / 100,
              workerTurnover: 0,
              miscAdminCost: 0,
              replacementCost: 0,
              costSavingsWorkerTurnover: 0,
              absentNo: 0,
              costSavingsAbsenteeism: 0,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              savingsFromIntervention: 0,
              totalCost: 0,
              annualRoi: 0,
              projectedRoi: 0,
              percentWorkerTurnover: 1.78,
              percentAbsent: 1.4,
              percentProductivity: 5
            },
      tablets:
        roi.programmeDetails && roi.programmeDetails[5]
          ? roi.programmeDetails[5]
          : {
              programNo: 5,
              hoursOfTraining: 24,
              hourlyTrainingCost:
                Math.round((20 * currencyValue + Number.EPSILON) * 100) / 100,
              totalStationeryCost:
                Math.round((200 * currencyValue + Number.EPSILON) * 100) / 100,
              testingCostPerWorker: 20,
              medicationCostPerWorker:
                Math.round((1.3 * currencyValue + Number.EPSILON) * 100) / 100,
              miscAdminCost: 0,
              attendanceNo: 0,
              costSavingsAttendance: 0,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              savingsFromIntervention: 0,
              totalTrainingCost: 0,
              totalTestingCost: 0,
              totalCost: 0,
              annualRoi: 0,
              projectedRoi: 0,
              percentProductivity: 7,
              percentAttendance: '120 hrs'
            },
      lifeSkill:
        roi.programmeDetails && roi.programmeDetails[6]
          ? roi.programmeDetails[6]
          : {
              programNo: 6,
              hoursOfTraining: 160,
              hourlyTrainingCost:
                Math.round((20 * currencyValue + Number.EPSILON) * 100) / 100,
              totalStationeryCost:
                Math.round((400 * currencyValue + Number.EPSILON) * 100) / 100,
              costOfRefreshment:
                Math.round((400 * currencyValue + Number.EPSILON) * 100) / 100,
              miscAdminCost: 0,
              lateComingNo: 0,
              costSavingsLateComing: 0,
              annualProductivity: 0,
              annualChangeInProductivity: 0,
              costSavingsProductivity: 0,
              workerTurnover: 0,
              replacementCost: 0,
              costSavingsWorkerTurnover: 0,
              savingsFromIntervention: 0,
              totalCost: 0,
              annualRoi: 0,
              projectedRoi: 0,
              lossOfProductivityFromTraining: 0,
              percentProductivity: 5,
              percentWorkerTurnover: 23,
              percentLateComing: 62
            }
    }
  }

  componentDidMount = () => {
    this.checkCalculateRoiActive()
    if (this.state.from && this.state.from === 'bundle') {
      let sel = this.state.selected.every(v => v === false)
      if (sel) {
        this.saveInputData('mount')
      }
    }
  }

  saveInputData = from => {
    if (from !== 'back') {
      this.setState({ showCalculateRoi: true })
    }
    let that = this
    let newRoiData = this.state.local
    let roiCurrentProgram =
      newRoiData.roiData[localStorage.getItem('currentProg')]
    let companyDetails = roiCurrentProgram.companyDetails

    let programData = roiCurrentProgram.programmeDetails
      ? roiCurrentProgram.programmeDetails
      : []
    let programs = []
    this.state.selected.forEach((item, i) => {
      if (item) {
        programs.push(i)
      }
    })
    let b7 =
      companyDetails.productionTargetType === 'annual'
        ? parseFloat(companyDetails.productionTarget) /
          parseFloat(companyDetails.annualWorkingDays)
        : parseFloat(companyDetails.productionTarget)
    let costOfLabor = parseFloat(companyDetails.costOfLabor)
    let costOfLaborTotal = parseFloat(companyDetails.costOfLaborTotal)
    let annualProductivity =
      companyDetails.productionTargetType === 'annual'
        ? Math.round(companyDetails.productionTarget)
        : Math.round(companyDetails.productionTarget) *
          Math.round(companyDetails.annualWorkingDays)
    programs.forEach((item, i) => {
      if (item === 0) {
        let sanitary = this.state.sanitary
        sanitary.absentNo =
          companyDetails.absenteeismType === 'annual'
            ? (Math.round(companyDetails.absenteeism) * 21) / 100
            : (Math.round(companyDetails.absenteeism) * 21 * 12) / 100

        sanitary.absentNo = Math.round(sanitary.absentNo)
        sanitary.costSavingsAbsenteeism = sanitary.savingsFromIntervention =
          companyDetails.wageBillType === 'annual'
            ? (Math.round(sanitary.absentNo) *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill)) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))
            : (Math.round(sanitary.absentNo) *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill) *
                12) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))

        sanitary.totalCost =
          (Math.round(sanitary.percentageCoveredByCompany) / 100) *
            (300 *
              parseFloat(sanitary.costPerPad) *
              Math.round(companyDetails.womenWorkers)) +
          Math.round(sanitary.miscAdminCost)

        sanitary.annualRoi =
          sanitary.savingsFromIntervention / sanitary.totalCost
        sanitary.projectedRoi = sanitary.annualRoi * 100

        this.setState({ sanitary: sanitary })
        programData[item] = this.state.sanitary
      } else if (item === 1) {
        let srh = this.state.srh
        srh.workerTurnover =
          companyDetails.workerTurnoverType === 'annual'
            ? (6 * Math.round(companyDetails.workerTurnover)) / 100
            : (6 * Math.round(companyDetails.workerTurnover) * 12) / 100
        srh.replacementCost =
          Math.round(companyDetails.avgRecruitmentCost) +
          Math.round(companyDetails.trainingCostOfNewWorker) +
          Math.round(companyDetails.miscRecruitmentExpenditure)
        srh.costSavingsWorkerTurnover = srh.workerTurnover * srh.replacementCost
        srh.absentNoLB =
          companyDetails.absenteeismType === 'annual'
            ? (Math.round(companyDetails.absenteeism) * 24) / 100
            : (Math.round(companyDetails.absenteeism) * 24 * 12) / 100
        srh.absentNoUB =
          companyDetails.absenteeismType === 'annual'
            ? (Math.round(companyDetails.absenteeism) * 25) / 100
            : (Math.round(companyDetails.absenteeism) * 25 * 12) / 100
        srh.costSavingsAbsenteeismLB =
          companyDetails.wageBillType === 'annual'
            ? (srh.absentNoLB *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill)) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))
            : (srh.absentNoLB *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill) *
                12) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))
        srh.costSavingsAbsenteeismUB =
          companyDetails.wageBillType === 'annual'
            ? (srh.absentNoUB *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill)) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))
            : (srh.absentNoUB *
                Math.round(companyDetails.womenWorkers) *
                Math.round(companyDetails.wageBill) *
                12) /
              (Math.round(companyDetails.annualWorkingDays) *
                Math.round(companyDetails.noOfWorkers))
        srh.annualProductivity = annualProductivity
        srh.annualChangeInProductivity = (srh.annualProductivity * 9.5) / 100
        srh.costSavingsProductivity =
          srh.annualChangeInProductivity * costOfLabor
        srh.savingsFromInterventionUB =
          srh.costSavingsWorkerTurnover +
          srh.costSavingsAbsenteeismUB +
          srh.costSavingsProductivity
        srh.savingsFromInterventionLB =
          srh.costSavingsWorkerTurnover +
          srh.costSavingsAbsenteeismLB +
          srh.costSavingsProductivity
        srh.lossOfProductivityFromTraining =
          (srh.hoursOfTraining / Math.round(companyDetails.avgWorkingHours)) *
          costOfLabor *
          b7

        srh.totalCost =
          parseFloat(srh.hoursOfTraining) * parseFloat(srh.hourlyTrainingCost) +
          parseFloat(srh.totalStationeryCost) +
          parseFloat(srh.costOfRefreshment) +
          parseFloat(srh.lossOfProductivityFromTraining) +
          parseFloat(srh.miscAdminCost)

        srh.annualRoiUB = srh.savingsFromInterventionUB / srh.totalCost
        srh.annualRoiLB = srh.savingsFromInterventionLB / srh.totalCost
        srh.projectedRoiUB = srh.annualRoiUB * 100
        srh.projectedRoiLB = srh.annualRoiLB * 100
        this.setState({ srh: srh })
        programData[item] = this.state.srh
      } else if (item === 2) {
        let peer = this.state.peer
        peer.workerTurnoverUB =
          companyDetails.workerTurnoverType === 'annual'
            ? (46 * parseFloat(companyDetails.workerTurnover)) / 100
            : (46 * parseFloat(companyDetails.workerTurnover) * 12) / 100
        peer.workerTurnoverLB =
          companyDetails.workerTurnoverType === 'annual'
            ? (4.5 * parseFloat(companyDetails.workerTurnover)) / 100
            : (4.5 * parseFloat(companyDetails.workerTurnover) * 12) / 100
        peer.replacementCost =
          parseFloat(companyDetails.avgRecruitmentCost) +
          parseFloat(companyDetails.trainingCostOfNewWorker) +
          parseFloat(companyDetails.miscRecruitmentExpenditure)
        peer.costSavingsWorkerTurnoverUB =
          peer.workerTurnoverUB * peer.replacementCost
        peer.costSavingsWorkerTurnoverLB =
          peer.workerTurnoverLB * peer.replacementCost

        peer.absentNo =
          companyDetails.absenteeismType === 'annual'
            ? (11 * parseFloat(companyDetails.absenteeism)) / 100
            : (11 * parseFloat(companyDetails.absenteeism) * 12) / 100

        peer.costSavingsAbsenteeism =
          companyDetails.wageBillType === 'annual'
            ? (parseFloat(peer.absentNo) *
                parseFloat(companyDetails.wageBill)) /
              parseFloat(companyDetails.annualWorkingDays)
            : (parseFloat(peer.absentNo) *
                parseFloat(companyDetails.wageBill) *
                12) /
              parseFloat(companyDetails.annualWorkingDays)

        peer.annualProductivity = annualProductivity
        peer.annualChangeInProductivity = (peer.annualProductivity * 22) / 100
        peer.costSavingsProductivity =
          peer.annualChangeInProductivity * costOfLabor
        peer.savingsFromInterventionUB =
          peer.costSavingsWorkerTurnoverUB +
          peer.costSavingsAbsenteeism +
          peer.costSavingsProductivity
        peer.savingsFromInterventionLB =
          peer.costSavingsWorkerTurnoverLB +
          peer.costSavingsAbsenteeism +
          peer.costSavingsProductivity

        peer.lossOfProductivityFromTraining =
          (parseFloat(peer.hoursOfTraining) /
            parseFloat(companyDetails.avgWorkingHours)) *
          costOfLabor *
          25 *
          (b7 / parseFloat(companyDetails.noOfWorkers))

        peer.totalCost =
          parseFloat(peer.hoursOfTraining) *
            parseFloat(peer.hourlyTrainingCost) +
          parseFloat(peer.totalStationeryCost) +
          parseFloat(peer.costOfRefreshment) +
          parseFloat(peer.lossOfProductivityFromTraining) +
          parseFloat(peer.miscAdminCost)

        peer.annualRoiUB = peer.savingsFromInterventionUB / peer.totalCost
        peer.annualRoiLB = peer.savingsFromInterventionLB / peer.totalCost
        peer.projectedRoiUB = peer.annualRoiUB * 100
        peer.projectedRoiLB = peer.annualRoiLB * 100
        this.setState({ peer: peer })
        programData[item] = this.state.peer
      } else if (item === 3) {
        let subsidize = this.state.subsidize
        subsidize.annualProductivity = annualProductivity
        subsidize.annualChangeInProductivity =
          (subsidize.annualProductivity * 15) / 100
        subsidize.costSavingsProductivity = subsidize.savingsFromIntervention =
          subsidize.annualChangeInProductivity * costOfLaborTotal
        subsidize.totalCost =
          parseFloat(subsidize.healthPersonnelSalary) +
          parseFloat(subsidize.transportCost) +
          parseFloat(subsidize.medicalConsumables) +
          parseFloat(subsidize.miscExpenses)
        subsidize.annualRoi =
          subsidize.savingsFromIntervention / subsidize.totalCost
        subsidize.projectedRoi = subsidize.annualRoi * 100
        this.setState({ subsidize: subsidize })
        programData[item] = this.state.subsidize
      } else if (item === 4) {
        let clinic = this.state.clinic
        clinic.workerTurnover =
          companyDetails.workerTurnoverType === 'annual'
            ? (1.78 * Math.round(companyDetails.workerTurnover)) / 100
            : (1.78 * Math.round(companyDetails.workerTurnover) * 12) / 100
        clinic.replacementCost =
          Math.round(companyDetails.avgRecruitmentCost) +
          Math.round(companyDetails.trainingCostOfNewWorker) +
          Math.round(companyDetails.miscRecruitmentExpenditure)
        clinic.costSavingsWorkerTurnover =
          clinic.workerTurnover * clinic.replacementCost
        clinic.absentNo =
          companyDetails.absenteeismType === 'annual'
            ? (1.4 * Math.round(companyDetails.absenteeism)) / 100
            : (1.4 * Math.round(companyDetails.absenteeism) * 12) / 100
        clinic.costSavingsAbsenteeism =
          companyDetails.wageBillType === 'annual'
            ? (Math.round(clinic.absentNo) *
                Math.round(companyDetails.wageBill)) /
              Math.round(companyDetails.annualWorkingDays)
            : (Math.round(clinic.absentNo) *
                Math.round(companyDetails.wageBill) *
                12) /
              Math.round(companyDetails.annualWorkingDays)
        clinic.annualProductivity = annualProductivity
        clinic.annualChangeInProductivity =
          (clinic.annualProductivity * 5) / 100
        clinic.costSavingsProductivity =
          clinic.annualChangeInProductivity * costOfLaborTotal
        clinic.savingsFromIntervention =
          clinic.costSavingsWorkerTurnover +
          clinic.costSavingsAbsenteeism +
          clinic.costSavingsProductivity
        clinic.totalCost =
          parseFloat(clinic.oneTimeConstructionCost) +
          parseFloat(clinic.annualMedicineInventoryCost) +
          parseFloat(clinic.medicalInstrumentCost) +
          parseFloat(clinic.miscAdminCost)
        clinic.annualRoi = clinic.savingsFromIntervention / clinic.totalCost
        clinic.projectedRoi = clinic.annualRoi * 100
        this.setState({ clinic: clinic })
        programData[item] = this.state.clinic
      } else if (item === 5) {
        let tablets = this.state.tablets
        tablets.attendanceNo = parseFloat(
          120 / parseFloat(companyDetails.avgWorkingHours)
        )
        tablets.costSavingsAttendance =
          companyDetails.wageBillType === 'annual'
            ? (parseFloat(companyDetails.wageBill) * tablets.attendanceNo) /
              parseFloat(companyDetails.annualWorkingDays)
            : (parseFloat(companyDetails.wageBill) *
                tablets.attendanceNo *
                12) /
              parseFloat(companyDetails.annualWorkingDays)

        tablets.annualProductivity = annualProductivity
        tablets.annualChangeInProductivity =
          (tablets.annualProductivity * 7) / 100
        tablets.costSavingsProductivity =
          tablets.annualChangeInProductivity * costOfLabor

        tablets.savingsFromIntervention =
          tablets.costSavingsProductivity + tablets.costSavingsAttendance

        tablets.totalTrainingCost =
          parseFloat(tablets.hoursOfTraining) +
          parseFloat(tablets.hourlyTrainingCost) +
          parseFloat(tablets.totalStationeryCost)

        //Question B14 and B53 not clear what the value is
        let anemic =
          (parseFloat(companyDetails.ironValue) *
            parseFloat(companyDetails.womenWorkers)) /
          100

        tablets.totalTestingCost =
          parseFloat(companyDetails.womenWorkers) *
            parseFloat(tablets.testingCostPerWorker) +
          anemic * parseFloat(tablets.medicationCostPerWorker) +
          parseFloat(tablets.miscAdminCost)

        tablets.totalCost =
          parseFloat(tablets.totalTrainingCost) +
          parseFloat(tablets.totalTestingCost)
        tablets.annualRoi = tablets.savingsFromIntervention / tablets.totalCost
        tablets.projectedRoi = tablets.annualRoi * 100

        this.setState({ tablets: tablets })
        programData[item] = this.state.tablets
      } else if (item === 6) {
        let lifeSkill = this.state.lifeSkill

        lifeSkill.lateComingNo =
          companyDetails.lateComingType === 'annual'
            ? (62 * parseFloat(companyDetails.lateComingHours)) / 100
            : (62 * parseFloat(companyDetails.lateComingHours) * 12) / 100

        lifeSkill.costSavingsLateComing = lifeSkill.savingsFromIntervention =
          companyDetails.wageBillType === 'annual'
            ? (parseFloat(lifeSkill.lateComingNo) *
                parseFloat(companyDetails.wageBill)) /
              (parseFloat(companyDetails.annualWorkingDays) *
                parseFloat(companyDetails.avgWorkingHours) *
                companyDetails.noOfWorkers)
            : (parseFloat(lifeSkill.lateComingNo) *
                parseFloat(companyDetails.wageBill) *
                12) /
              (parseFloat(companyDetails.annualWorkingDays) *
                parseFloat(companyDetails.avgWorkingHours) *
                companyDetails.noOfWorkers)

        lifeSkill.annualProductivity = annualProductivity
        lifeSkill.annualChangeInProductivity =
          (lifeSkill.annualProductivity * 5) / 100
        lifeSkill.costSavingsProductivity =
          lifeSkill.annualChangeInProductivity * costOfLaborTotal

        lifeSkill.workerTurnover =
          companyDetails.workerTurnoverType === 'annual'
            ? (23 * Math.round(companyDetails.workerTurnover)) / 100
            : (23 * Math.round(companyDetails.workerTurnover) * 12) / 100

        lifeSkill.replacementCost =
          Math.round(companyDetails.avgRecruitmentCost) +
          Math.round(companyDetails.trainingCostOfNewWorker) +
          Math.round(companyDetails.miscRecruitmentExpenditure)
        lifeSkill.costSavingsWorkerTurnover =
          lifeSkill.workerTurnover * lifeSkill.replacementCost

        lifeSkill.savingsFromIntervention =
          lifeSkill.costSavingsWorkerTurnover +
          lifeSkill.costSavingsProductivity +
          lifeSkill.costSavingsLateComing

        lifeSkill.lossOfProductivityFromTraining =
          (lifeSkill.hoursOfTraining /
            parseFloat(companyDetails.avgWorkingHours)) *
          costOfLaborTotal *
          b7

        lifeSkill.totalCost =
          parseFloat(lifeSkill.hoursOfTraining) *
            parseFloat(lifeSkill.hourlyTrainingCost) +
          parseFloat(lifeSkill.totalStationeryCost) +
          parseFloat(lifeSkill.costOfRefreshment) +
          parseFloat(lifeSkill.lossOfProductivityFromTraining) +
          parseFloat(lifeSkill.miscAdminCost)

        lifeSkill.annualRoi =
          Math.round(lifeSkill.savingsFromIntervention) /
          Math.round(lifeSkill.totalCost)
        lifeSkill.projectedRoi = lifeSkill.annualRoi * 100

        this.setState({ lifeSkill: lifeSkill })
        programData[item] = this.state.lifeSkill
      }
    })

    roiCurrentProgram.programmeDetails = programData
    let bundleDataList = []
    let bundleData = []
    roiCurrentProgram.bundleDetails &&
      roiCurrentProgram.bundleDetails.forEach(item => {
        let tempBundle = {}
        tempBundle = Object.assign(tempBundle, {
          programIds: item.programIds
        })
        bundleDataList.push(tempBundle)
      })

    if (this.state.from === 'bundle') {
      let bundleList = localStorage
        .getItem('bundleList')
        .split(',')
        .map(function (item) {
          return Math.round(item, 10)
        })
        .sort()
      let tempBundle = {}
      tempBundle = Object.assign(tempBundle, {
        programIds: bundleList
      })
      bundleDataList.push(tempBundle)
    }

    bundleDataList.forEach(item => {
      let tempBundle = {}
      item.programIds.forEach(bundle => {
        let relProg = roiCurrentProgram.programmeDetails[parseInt(bundle)]
        for (const key in relProg) {
          if (relProg.hasOwnProperty(key)) {
            if (tempBundle[key]) {
              tempBundle[key] =
                parseFloat(tempBundle[key]) + parseFloat(relProg[key])
            } else {
              tempBundle = Object.assign(tempBundle, {
                [key]: parseFloat(relProg[key])
              })
            }
          }
        }
      })
      tempBundle = Object.assign(tempBundle, {
        programIds: item.programIds
      })
      if (tempBundle.absentNoUB && tempBundle.absentNo) {
        tempBundle.absentNoUB =
          parseFloat(tempBundle.absentNoUB) + parseFloat(tempBundle.absentNo)
        tempBundle.absentNoLB =
          parseFloat(tempBundle.absentNoLB) + parseFloat(tempBundle.absentNo)
        tempBundle.percentAbsentUB =
          parseFloat(tempBundle.percentAbsentUB) +
          parseFloat(tempBundle.percentAbsent)
        tempBundle.percentAbsentLB =
          parseFloat(tempBundle.percentAbsentLB) +
          parseFloat(tempBundle.percentAbsent)

        tempBundle.absentNo = null
        tempBundle.percentAbsent = null
      }

      if (tempBundle.workerTurnoverUB && tempBundle.workerTurnover) {
        tempBundle.workerTurnoverUB =
          parseFloat(tempBundle.workerTurnoverUB) +
          parseFloat(tempBundle.workerTurnover)
        tempBundle.workerTurnoverLB =
          parseFloat(tempBundle.workerTurnoverLB) +
          parseFloat(tempBundle.workerTurnover)
        tempBundle.percentWorkerTurnoverUB =
          parseFloat(tempBundle.percentWorkerTurnoverUB) +
          parseFloat(tempBundle.percentWorkerTurnover)
        tempBundle.percentWorkerTurnoverLB =
          parseFloat(tempBundle.percentWorkerTurnoverLB) +
          parseFloat(tempBundle.percentWorkerTurnover)

        tempBundle.workerTurnover = null
        tempBundle.percentWorkerTurnover = null
      }

      if (
        tempBundle.savingsFromInterventionUB &&
        tempBundle.savingsFromIntervention
      ) {
        tempBundle.savingsFromInterventionUB =
          parseFloat(tempBundle.savingsFromInterventionUB) +
          parseFloat(tempBundle.savingsFromIntervention)
        tempBundle.savingsFromInterventionLB =
          parseFloat(tempBundle.savingsFromInterventionLB) +
          parseFloat(tempBundle.savingsFromIntervention)
        tempBundle.savingsFromIntervention = null
      }

      if (
        tempBundle.costSavingsWorkerTurnoverUB &&
        tempBundle.costSavingsWorkerTurnover
      ) {
        tempBundle.costSavingsWorkerTurnoverUB =
          parseFloat(tempBundle.costSavingsWorkerTurnoverUB) +
          parseFloat(tempBundle.costSavingsWorkerTurnover)
        tempBundle.costSavingsWorkerTurnoverLB =
          parseFloat(tempBundle.costSavingsWorkerTurnoverLB) +
          parseFloat(tempBundle.costSavingsWorkerTurnover)
        tempBundle.costSavingsWorkerTurnover = null
      }

      if (
        tempBundle.costSavingsAbsenteeismUB &&
        (tempBundle.costSavingsAbsenteeism ||
          parseInt(tempBundle.costSavingsAbsenteeism) === 0)
      ) {
        tempBundle.costSavingsAbsenteeismUB =
          parseFloat(tempBundle.costSavingsAbsenteeismUB) +
          parseFloat(tempBundle.costSavingsAbsenteeism)
        tempBundle.costSavingsAbsenteeismLB =
          parseFloat(tempBundle.costSavingsAbsenteeismLB) +
          parseFloat(tempBundle.costSavingsAbsenteeism)
        tempBundle.costSavingsAbsenteeism = null
      }

      tempBundle.annualRoi = tempBundle.savingsFromInterventionUB
        ? this.average(
            tempBundle.savingsFromInterventionUB,
            tempBundle.savingsFromInterventionLB
          ) / parseFloat(tempBundle.totalCost)
        : parseFloat(tempBundle.savingsFromIntervention) /
          parseFloat(tempBundle.totalCost)

      tempBundle.projectedRoi = parseFloat(tempBundle.annualRoi) * 100

      tempBundle.annualRoiUB = null
      tempBundle.annualRoiLB = null
      tempBundle.projectedRoiUB = null
      tempBundle.projectedRoiLB = null

      bundleData.push(tempBundle)
      roiCurrentProgram.bundleDetails = bundleData
    })

    var data = JSON.stringify({
      roiData: newRoiData.roiData
    })
    localStorage.setItem('udgbl', JSON.stringify(newRoiData))

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        setTimeout(function () {
          that.setState({ showCalculateRoi: false })
          window.location.href = '/roi'
        }, 3000)
      }
    })

    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/user/updateROI/' +
        this.state.userId
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
    )

    xhr.send(data)
  }

  average = (num1, num2) => {
    return (parseFloat(num1) + parseFloat(num2)) / 2
  }

  checkCalculateRoiActive = () => {
    let programs = []
    let checkedProg = []
    this.state.selected.forEach((item, i) => {
      if (item) {
        programs.push(i)
      }
    })
    programs.forEach((item, i) => {
      if (item === 0) {
        checkedProg.push(this.sanitaryDone())
      }
      if (item === 1) {
        checkedProg.push(this.srhDone())
      }
      if (item === 2) {
        checkedProg.push(this.peerDone())
      }
      if (item === 3) {
        checkedProg.push(this.subsidizeDone())
      }
      if (item === 4) {
        checkedProg.push(this.clinicDone())
      }
      if (item === 5) {
        checkedProg.push(this.tabletsDone())
      }
      if (item === 6) {
        checkedProg.push(this.lifeSkillDone())
      }
    })
    this.setState({ isRoiActive: checkedProg.every(Boolean) })
  }

  sanitaryDone = () => {
    return !(
      this.state.sanitary.costPerPad === 0 ||
      this.state.sanitary.costPerPad === '' ||
      this.state.sanitary.percentageCoveredByCompany === 0 ||
      this.state.sanitary.percentageCoveredByCompany === '' ||
      this.state.sanitary.miscAdminCost === ''
    )
  }

  srhDone = () => {
    return this.srhPeerLifeSkillDone('srh')
  }

  peerDone = () => {
    return this.srhPeerLifeSkillDone('peer')
  }

  subsidizeDone = () => {
    return !(
      this.state.subsidize.healthPersonnelSalary === 0 ||
      this.state.subsidize.healthPersonnelSalary === '' ||
      this.state.subsidize.transportCost === '' ||
      this.state.subsidize.medicalConsumables === 0 ||
      this.state.subsidize.medicalConsumables === '' ||
      this.state.subsidize.miscExpenses === ''
    )
  }

  clinicDone = () => {
    return !(
      this.state.clinic.oneTimeConstructionCost === 0 ||
      this.state.clinic.oneTimeConstructionCost === '' ||
      this.state.clinic.annualMedicineInventoryCost === 0 ||
      this.state.clinic.annualMedicineInventoryCost === '' ||
      this.state.clinic.medicalInstrumentCost === 0 ||
      this.state.clinic.medicalInstrumentCost === '' ||
      this.state.clinic.miscAdminCost === ''
    )
  }

  tabletsDone = () => {
    return !(
      this.state.tablets.hoursOfTraining === 0 ||
      this.state.tablets.hoursOfTraining === '' ||
      this.state.tablets.hourlyTrainingCost === '' ||
      this.state.tablets.totalStationeryCost === '' ||
      this.state.tablets.testingCostPerWorker === '' ||
      this.state.tablets.medicationCostPerWorker === 0 ||
      this.state.tablets.medicationCostPerWorker === '' ||
      this.state.tablets.miscAdminCost === ''
    )
  }

  lifeSkillDone = () => {
    return this.srhPeerLifeSkillDone('lifeSkill')
  }

  srhPeerLifeSkillDone = program => {
    return !(
      this.state[program].hoursOfTraining === 0 ||
      this.state[program].hoursOfTraining === '' ||
      this.state[program].hourlyTrainingCost === 0 ||
      this.state[program].hourlyTrainingCost === '' ||
      this.state[program].totalStationeryCost === '' ||
      this.state[program].costOfRefreshment === '' ||
      this.state[program].miscAdminCost === ''
    )
  }

  rangeError = () => {
    this.setState({
      showLimit: true,
      showLimitText: 'Range is not allowed'
    })
  }

  render () {
    let state = this.state
    return (
      <div className='container noover'>
        <Header />
        <div
          className={this.state.showLimit ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popup popupCardTop35 left-txt'>
            <div className='h9 padBot16'>Error</div>
            <div className='h11'>
              {this.state.showLimitText}
              <br />
              <br />
            </div>
            <div className='width100 flex justifyContentFlexEnd'>
              <img
                alt='ok'
                className='pointer height40px padLeft24'
                onClick={() => {
                  this.setState({ showLimit: false })
                }}
                src={ok}
              />
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showCalculateRoi ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popupSmall left-txt'>
            <img alt='spin' className='height48' src={spin} />
            <div className='h9'>Calculating Impact and ROI</div>
            <div className='h11'>
              We are calculating the projected ROI along with the impact on
              Absenteeism, Worker Turnover and Productivity for your selected
              programme(s)
            </div>
          </div>
        </div>
        <div className='blackWhite-bg height100'>
          <div className='bar flex justifyContentSpaceBetween  alignItemsCenter'>
            <div className='width60'>
              <img alt='input bar' className='width100' src={inputBar} />
            </div>
            <div className='flex alignItemsCenter'>
              <div
                onClick={() => {
                  this.saveInputData('back')
                  window.location.href = '/company'
                }}
              >
                <img
                  alt='back'
                  className={
                    this.state.from === 'bundle' || this.state.from === 'add'
                      ? 'dispNone pointer height32 margRight8 margBot4'
                      : 'pointer height32 margRight8 margBot4'
                  }
                  src={back}
                />
              </div>
              {this.state.isRoiActive ? (
                <img
                  alt='roi active'
                  className='pointer height32'
                  onClick={() => {
                    localStorage.setItem('calculateRoi', 'true')
                    this.saveInputData('proceed')
                  }}
                  src={roiActive}
                />
              ) : (
                <img
                  alt='roi inactive'
                  className='height32'
                  src={roiInactive}
                />
              )}
            </div>
          </div>
          <div className='flex padTop24'>
            <div className='bar width20 padTop48'>
              <div className='h7 padRight24 left-txt nowrap'>Step 3</div>
              <br />
              <div className='h4 left-txt'>Input Costs of Programmes</div>
              <br />
              <div className='h7 left-txt padBot3rem'>
                Review, modify or add costs related to the implementation of
                selected programme(s) in your factory. The default values
                provided are based on global estimates but you can modify these
                according to your own estimation.
              </div>
              <div className='left-txt'>
                <div className='h6'>{this.state.projectionName}</div>
                <div className='h7'>{this.state.forWho}</div>
              </div>
            </div>
            <div className='rightSection'>
              <div
                id='program0'
                className={
                  this.state.selected[0]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>SANITARY PADS</div>
                    <div className='h7'>
                      Distribute free sanitary pads to women workers of
                      reproductive age to improve menstrual health
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Cost of 1 Pad</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The cost to purchase one sanitary pad. We estimate it to
                      be $0.30
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.sanitary.costPerPad}
                        onChange={event => {
                          state.sanitary.costPerPad = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (
                            parseFloat(event.target.value) < 0.00001 ||
                            event.target.value === ''
                          ) {
                            state.sanitary.costPerPad = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Cost per pad should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>% of Cost shared by company </div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      Percentage of costs for purchasing the pads which will be
                      covered by the company. This percentage is set by default
                      at 100%, as the cost of the interventions should be free
                      for the workers. However, this cost-sharing percentage can
                      be changed by the user - up to a lower bound of 20%. This
                      is because going below 20% would affect programme uptake
                      in your factory, as well as ROI calculations.
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        placeholder='100'
                        value={this.state.sanitary.percentageCoveredByCompany}
                        onChange={event => {
                          state.sanitary.percentageCoveredByCompany =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) > 100) {
                            state.sanitary.percentageCoveredByCompany = '100'
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'User cannot enter less than 20% and more than 100%.'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 21) {
                            state.sanitary.percentageCoveredByCompany = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'User cannot enter less than 20% and more than 100%.'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Misc Admin Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The other costs involved in running the program,
                      especially the administration cost of monitoring the
                      programme.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.sanitary.miscAdminCost}
                        onChange={event => {
                          state.sanitary.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program1'
                className={
                  this.state.selected[1]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>SRH TRAINING</div>
                    <div className='h7'>
                      Train workers on sexual and reproductive health to boost
                      awareness on SRH issues
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Total hours of Training</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The number of hours required for SRH awareness training.
                      We fixed it at 160 hours annually - in line with the
                      intervention design, where the training was held over 4
                      sessions (once per quarter), with 40 hours of content to
                      be covered in each session (approximately 5 working days
                      per quarter).
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        placeholder=''
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) > 240) {
                            let srh = this.state.srh
                            srh.hoursOfTraining = ''
                            this.setState({
                              srh: srh,
                              showLimit: true,
                              showLimitText:
                                'The total training hours should be less than 240 hours (30 days * 8 hours)'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                        value={this.state.srh.hoursOfTraining}
                        onChange={event => {
                          state.srh.hoursOfTraining = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Hourly training cost </div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The cost for an hour of training session, according to our
                      research is $20.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.srh.hourlyTrainingCost}
                        onChange={event => {
                          state.srh.hourlyTrainingCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.srh.hourlyTrainingCost = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Hourly Training Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Total Stationery Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing stationery used for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.srh.totalStationeryCost}
                        onChange={event => {
                          state.srh.totalStationeryCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Cost of Refreshment </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing refreshments for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.srh.costOfRefreshment}
                        onChange={event => {
                          state.srh.costOfRefreshment = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Miscellaneous Administrative Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The other costs involved in running the program,
                      especially the administration cost of monitoring the
                      program.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.srh.miscAdminCost}
                        onChange={event => {
                          state.srh.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5 margTop32'>
                      Estimated Productivity Lost Costs
                    </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      For this programme, estimated costs of productivity loss
                      during the training have been calculated by the ROI Tool.
                      These will be included in the final programme costs which
                      you will see in the next section, "View Impact and ROI".
                      More information on how these costs are calculated is
                      provided in the&nbsp;
                      <a
                        className='treePoppy-txt pointer'
                        href='/roi-tGuide/#guide'
                      >
                        ROI Tool Guide
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program2'
                className={
                  this.state.selected[2]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>PEER OUTREACH</div>
                    <div className='h7'>
                      Train select workers on sexual and reproductive health to
                      spread the word among others
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Total hours of Training</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The number of hours required for Peer Outreach. We fixed
                      it at 80 hours - in line with the intervention design
                      where the training was held over 2 sessions , with 40
                      hours of content to be covered in each session
                      (approximately 5 working days each session).
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        placeholder=''
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) > 240) {
                            let peer = this.state.peer
                            peer.hoursOfTraining = ''
                            this.setState({
                              peer: peer,
                              showLimit: true,
                              showLimitText:
                                'The total training hours should be less than 240 hours (30 days * 8 hours)'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                        value={this.state.peer.hoursOfTraining}
                        onChange={event => {
                          let peer = this.state.peer
                          peer.hoursOfTraining = event.target.value
                          this.setState({
                            peer: peer
                          })
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Hourly training cost </div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The cost for an hour of training session, according to our
                      research is $20.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.peer.hourlyTrainingCost}
                        onChange={event => {
                          state.peer.hourlyTrainingCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.peer.hourlyTrainingCost = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Hourly Training Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Total Stationery Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing stationery used for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.peer.totalStationeryCost}
                        onChange={event => {
                          state.peer.totalStationeryCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Cost of Refreshment </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing refreshments for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.peer.costOfRefreshment}
                        onChange={event => {
                          state.peer.costOfRefreshment = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Miscellaneous Administrative Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The other costs involved in running the program,
                      especially the administration cost of monitoring the
                      program.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.peer.miscAdminCost}
                        onChange={event => {
                          state.peer.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5 margTop32'>
                      Estimated Productivity Lost Costs
                    </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      For this programme, estimated costs of productivity loss
                      during the training have been calculated by the ROI Tool.
                      These will be included in the final programme costs which
                      you will see in the next section, "View Impact and ROI".
                      More information on how these costs are calculated is
                      provided in the&nbsp;
                      <a
                        className='treePoppy-txt pointer'
                        href='/roi-tGuide/#guide'
                      >
                        ROI Tool Guide
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program3'
                className={
                  this.state.selected[3]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>
                      SUBSIDISED SRH SERVICES & PRODUCTS
                    </div>
                    <div className='h7'>
                      Channel a mobile clinic to distribute subsidised SRH
                      services and products to workers after SRH awareness
                      sessions
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Health Personnel Salary</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The salary paid to hire two nurses/midwives. The per
                      quarter salary cost is $400 (for 5 days of work per
                      quarter), so for the annual cost is 4 times at $1600
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder  margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.subsidize.healthPersonnelSalary}
                        onChange={event => {
                          state.subsidize.healthPersonnelSalary =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.subsidize.healthPersonnelSalary = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Health Personnel Salary should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Transport cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost for setting up the mobile clinic, paying for the
                      nurses/midwives travel and other operational costs is
                      estimated at $600 per quarter. The annual cost is $2400.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.subsidize.transportCost}
                        onChange={event => {
                          state.subsidize.transportCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Medical Consumables </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing medical equipment and essential SRH
                      medications and products to run the clinic is estimated at
                      $4000 per quarter, which makes it $16000 per annum.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.subsidize.medicalConsumables}
                        onChange={event => {
                          state.subsidize.medicalConsumables =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.subsidize.medicalConsumables = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Medical Consumables should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Miscellaneous expenses </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost for purchasing stationery, refreshments and other
                      cost heads not included in other budget lines will fall in
                      this category. The annual estimated cost is $4000.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.subsidize.miscExpenses}
                        onChange={event => {
                          state.subsidize.miscExpenses = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program4'
                className={
                  this.state.selected[4]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>
                      SET UP FACTORY CLINIC
                    </div>
                    <div className='h7'>
                      Setup Factory Clinics for general health checkups, first
                      aid, pregnancy checkups and testing or medication for
                      frequently occuring ailments
                      <br />
                      <br />* The costs for the health provider salary are
                      assumed to be covered through your existing Factory Nurse
                      or other health provider in charge of First Aid. If you do
                      not have a health provider in your factory, please add
                      their estimated salary costs in the ‘Miscellaneous Costs’
                      budget line, as well as any other miscellaneous expenses
                      you foresee.
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>
                      One Time Construction Cost of Clinic
                    </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of building the clinic, or of repurposing an area
                      within the factory/company to use as clinic. We estimate
                      this to be $1000
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.clinic.oneTimeConstructionCost}
                        onChange={event => {
                          state.clinic.oneTimeConstructionCost =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.clinic.oneTimeConstructionCost = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'One Time Construction Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Annual Medicine Inventory Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The annual cost of buying medicines (including first aid,
                      general medication and basic SRH Medication such as
                      contraceptive pills) to run the clinic. The cost is
                      estimated to be $1000 per month, so for a year it would
                      add up to $12000.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.clinic.annualMedicineInventoryCost}
                        onChange={event => {
                          state.clinic.annualMedicineInventoryCost =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.clinic.oneTimeannualMedicineInventoryCostConstructionCost =
                              ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Annual Medicine Inventory Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Medical Instruments Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing medical machinery for diagnostic
                      purposes, e.g. BP machine, thermometer. This would cost
                      approximately $1000.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.clinic.medicalInstrumentCost}
                        onChange={event => {
                          state.clinic.medicalInstrumentCost =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.clinic.medicalInstrumentCost = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Medical Instruments Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5 margTop32'>
                      Miscellaneous Administrative Cost
                    </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      If you do not have a health provider in your factory,
                      please add estimated salary costs.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.clinic.miscAdminCost}
                        onChange={event => {
                          state.clinic.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program5'
                className={
                  this.state.selected[5]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>FREE IRON TABLETS</div>
                    <div className='h7'>
                      Testing, followed up with awareness and medication for
                      Anaemia
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Total hours of Training</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The number of hours required for anaemia awareness
                      training. We fix it at 24 hours
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        placeholder=''
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) > 240) {
                            let tablets = this.state.tablets
                            tablets.hoursOfTraining = ''
                            this.setState({
                              tablets: tablets,
                              showLimit: true,
                              showLimitText:
                                'The total training hours should be less than 240 hours (30 days * 8 hours)'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                        value={this.state.tablets.hoursOfTraining}
                        onChange={event => {
                          state.tablets.hoursOfTraining = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Hourly Training Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The cost for an hour of training session, according to our
                      research is $20
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.tablets.hourlyTrainingCost}
                        onChange={event => {
                          state.tablets.hourlyTrainingCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Total Stationery Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing stationery used for the training
                      participants. We estimate a ballpark figure of $200
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.tablets.totalStationeryCost}
                        onChange={event => {
                          state.tablets.totalStationeryCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Testing Cost per worker</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The cost involved in testing each worket for Anaemia. This
                      includes the cost of human resources, testing kit and
                      testing camp set up.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.tablets.testingCostPerWorker}
                        onChange={event => {
                          state.tablets.testingCostPerWorker =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Medication Cost per Worker</div>
                    <div className='h13 margTop8 matterhorn-txt  width90'>
                      The cost of purchasing iron tablets to treat Anaemia for
                      one female worker for one year. The estimated cost of each
                      treatment is $1.30
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.tablets.medicationCostPerWorker}
                        onChange={event => {
                          state.tablets.medicationCostPerWorker =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.tablets.medicationCostPerWorker = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Medication Cost per worker should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Miscellaneous Administrative Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The administration cost involved in tablet distribution,
                      program monitoring and evaluation.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.tablets.miscAdminCost}
                        onChange={event => {
                          state.tablets.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='program6'
                className={
                  this.state.selected[6]
                    ? 'companyCard'
                    : 'companyCard dispNone'
                }
              >
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>
                      LIFE SKILLS TRAINING
                    </div>
                    <div className='h7'>
                      Train workers on hygiene, reproductive health,
                      occupational health, safety and gender sensitivity
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Total hours of Training</div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The number of hours required for Life Skills Training. We
                      fixed it at 160 hours annually - in line with the
                      intervention design, where the training was held over 4
                      sessions (once per quarter), with 40 hours of content to
                      be covered in each session (approximately 5 working days
                      per quarter).
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        placeholder=''
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) > 240) {
                            let lifeSkill = this.state.lifeSkill
                            lifeSkill.hoursOfTraining = ''
                            this.setState({
                              lifeSkill: lifeSkill,
                              showLimit: true,
                              showLimitText:
                                'The total training hours should be less than 240 hours (30 days * 8 hours)'
                            })
                            this.checkCalculateRoiActive()
                          }
                        }}
                        value={this.state.lifeSkill.hoursOfTraining}
                        onChange={event => {
                          state.lifeSkill.hoursOfTraining = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Hourly training cost </div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The cost for an hour of training session, according to our
                      research is $20.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.lifeSkill.hourlyTrainingCost}
                        onChange={event => {
                          state.lifeSkill.hourlyTrainingCost =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                        onBlur={event => {
                          if (parseInt(event.target.value) < 1) {
                            state.lifeSkill.hourlyTrainingCost = ''
                            this.setState({
                              showLimit: true,
                              showLimitText:
                                'Hourly Training Cost should be greater than 0'
                            })
                          }
                        }}
                      />
                    </div>

                    <div className='h5'>Total Stationery Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing stationery used for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.lifeSkill.totalStationeryCost}
                        onChange={event => {
                          state.lifeSkill.totalStationeryCost =
                            event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Cost of Refreshment </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The cost of purchasing refreshments for the training
                      participants. We estimate a ballpark figure of $400, with
                      $100 spent per session
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={this.state.lifeSkill.costOfRefreshment}
                        onChange={event => {
                          state.lifeSkill.costOfRefreshment = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Miscellaneous Administrative Cost </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      The other costs involved in running the program,
                      especially the administration cost of monitoring the
                      program.
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>{this.state.currency}</div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace(
                              '-',
                              ''
                            )
                            this.rangeError()
                          }
                        }}
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        placeholder=''
                        value={this.state.lifeSkill.miscAdminCost}
                        onChange={event => {
                          state.lifeSkill.miscAdminCost = event.target.value
                          this.checkCalculateRoiActive()
                        }}
                      />
                    </div>

                    <div className='h5 margTop32'>
                      Estimated Productivity Lost Costs
                    </div>
                    <div className='h13 margTop8 matterhorn-txt width90'>
                      For this programme, estimated costs of productivity loss
                      during the training have been calculated by the ROI Tool.
                      These will be included in the final programme costs which
                      you will see in the next section, "View Impact and ROI".
                      More information on how these costs are calculated is
                      provided in the&nbsp;
                      <a
                        className='treePoppy-txt pointer'
                        href='/roi-tGuide/#guide'
                      >
                        ROI Tool Guide
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default InputView
