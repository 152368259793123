//eslint-disable-next-line 
import React, { Component } from 'react';
import Header from '../../../../components/Header/Header';
import Button from '../../../../components/Button/Button';
import '../../../../index.scss';
import './ProgramsView.scss';
import ClinicIcon from '../../../../assets/images/Clinic-icon.png';
import IronTab from '../../../../assets/images/IronTab-icon.png';
import LifeSkills from '../../../../assets/images/LifeSkills-icon.png';
import PeerOutreach from '../../../../assets/images/PeerOutreach-icon.png';
import SanitaryPads from '../../../../assets/images/SanitaryPads-icon.png';
import SRH from '../../../../assets/images/SRH-icon.png';
import SRHProducts from '../../../../assets/images/SRHProducts-icon.png';
import Footer from '../../../../components/Footer/Footer';

class ProgramsView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let programmeIcons = [SanitaryPads, SRH, SRHProducts, PeerOutreach, ClinicIcon, IronTab, LifeSkills]
        return (
            <div id="programsTop">
                <Header />
                <div className="programs-section1">
                    <div className="h2 buffer-bottom2">{this.props.programsTitle1}</div>
                    <div className="h11">{this.props.programsSection1Txt}</div>
                </div>

                <div>
                    <div className="programs-section2">
                        {this.props.programmes && this.props.programmes.map((value, index) =>
                            <div className="programs-box" key={index}>
                                <div className="programs-section2-row ">
                                    <div className="col1"><img src={programmeIcons[index]} alt="Programme icon" /></div>
                                    <div className="left-txt col2">
                                        <div className="h2 buffer-bottom1">{value.programTitles}</div>
                                        <div className="h11 buffer-bottom2">CATEGORY  <span>{value.programCategories}</span></div>
                                        <div className="h11">{value.programDescriptions}</div>
                                    </div>
                                </div>
                            </div>

                        )}

                    </div>
                </div>
                <div id="ourResearch" className="programs-section3">
                    <div className="h2 buffer-bottom2">
                        {this.props.programsTitle2}
                    </div>
                    <div className="h11">
                        <p>{this.props.programsSection3Para1}</p>
                        <p>{this.props.programsSection3Para2}</p>
                    </div>

                </div>
                <div className="table-wrapper">
                    <div className="table-view">
                        <div className="table-grid">
                            <div className="h10 emperor-txt table-grid-header table-sticky-col" style={{ borderRadius: "8px 0px 0px 0px" }}>Programmes</div>
                            <div className="h10 emperor-txt table-grid-header">Targeted Population</div>
                            <div className="h10 emperor-txt table-grid-header">% Reduction in Absenteeism</div>
                            <div className="h10 emperor-txt table-grid-header">% Reduction in Staff Turnover</div>
                            <div className="h10 emperor-txt table-grid-header" style={{ borderRadius: "0px 8px 0px 0px" }}>% Increase in Productivity</div>

                            <div className="table-grid-data h10 table-sticky-col">Sanitary Pads</div>
                            <div className="table-grid-data h11">Women in reproductive age (18-53) </div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">6</div>
                            <div className="table-grid-data h11">9.5 - 6*</div>
                            <div className="table-grid-data h10 table-sticky-col">SRH Training</div>
                            <div className="table-grid-data h11">Women employees of reproductive age </div>
                            <div className="table-grid-data h11">21</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h10 table-sticky-col buffer-bottom2">Subsidised SRH Products & Services</div>
                            <div className="table-grid-data h11">All workers</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">15</div>
                            <div className="table-grid-data h10 table-sticky-col">Peer Outreach</div>
                            <div className="table-grid-data h11">Women in reproductive age (18-53)</div>
                            <div className="table-grid-data h11">18 - 10.7*</div>
                            <div className="table-grid-data h11">46 - 4.5*</div>
                            <div className="table-grid-data h11">22</div>
                            <div className="table-grid-data h10 table-sticky-col">Setup Factory Clinic</div>
                            <div className="table-grid-data h11">All workers, mainly women </div>
                            <div className="table-grid-data h11">1.4</div>
                            <div className="table-grid-data h11">1.78</div>
                            <div className="table-grid-data h11">5.7</div>
                            <div className="table-grid-data h10 table-sticky-col">Free Iron Tablets</div>
                            <div className="table-grid-data h11">Anaemic women</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">-</div>
                            <div className="table-grid-data h11">7</div>
                            <div className="table-grid-data h10 table-sticky-col">Life Skills Training</div>
                            <div className="table-grid-data h11">All workers, mainly women</div>
                            <div className="table-grid-data h11">62</div>
                            <div className="table-grid-data h11">23</div>
                            <div className="table-grid-data h11">5</div>
                            <div className="whiteLinen-bg" style={{ gridColumn: "1/-4", padding: "25px 0 0 25px", borderRadius: "0px 0px 0px 8px" }}>
                            </div>
                            <div className="table-item emperor-txt whiteLinen-bg h11" >
                                *Entries in columns C-E with a range (i.e. X-Y) depict the upper limit (X) and lower limit (Y) respectively that is estimated for that variable.</div>
                        </div>
                    </div>
                </div>

                <div className="home-section8 spaceBetween gallery-bg">
                    <div className="h3 left-txt col1">{this.props.programsTitle3}</div>
                    <div className="col2"><Button className="home-section8-btn h4" buttonText="Get Started Now" onClick={() => this.props.goLogin()} /></div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default ProgramsView;