//eslint-disable-next-line
import React, { Component } from 'react'
import Header from '../../../../components/Header/Header'
import './ProjectionsView.scss'
import '../../../../indexroi.scss'
import { TextInput, Select } from '../../../../components/MaterialInput'
import projectionBtn from '../../../../assets/images/roi/projectionBtn.svg'
import saveInactive from '../../../../assets/images/roi/saveInactive.svg'
import saveActive from '../../../../assets/images/roi/saveActive.svg'
import continueInactive from '../../../../assets/images/roi/continueInactive.svg'
import continueActive from '../../../../assets/images/roi/continueActive.svg'
import defaultPic from '../../../../assets/images/login/defaultPic.png'
import closeBtn from '../../../../assets/images/close2.png'
import addImg from '../../../../assets/images/addImg.png'

class ProjectionsView extends Component {
  constructor(props) {
    super(props)
    let local = JSON.parse(localStorage.getItem('udgbl'))
    this.state = {
      showLoginPopup: local.name !== '' ? false : true,
      showProjectionPopup: false,
      showExitPopup: false,
      exitProjectionIndex: '',
      isSaveActive: false,
      isContinueActive: false,
      name: local.name !== '' ? local.name : '',
      email: local.email ? local.email : '',
      emailType: local.emailType,
      organization: local.organization !== '' ? local.organization : '',
      roleInOrganization: local.roleInOrganization !== '' ? local.roleInOrganization : '',
      otherRole: local.otherRole !== '' ? local.otherRole : '',
      userId: local.id,
      projectionName: '',
      forWho: '',
      local: local,
      base64Logo: local.base64Logo !== '' ? local.base64Logo : defaultPic,
      showChangePassword: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      savePasswordActive: false,
      apiUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
      passwordErrorMsg: ''
    }
    this.inputOpenFileRef = React.createRef()
  }

  componentDidMount() {
    //this.setState({ local: JSON.parse(localStorage.getItem('udgbl')) })
    this.setState({ projectionName: '', forWho: '' })
    this.checkPath()
  }

  checkPath() {
    if (window.location.hash && window.location.hash === '#editProfile') {
      this.setState({ showLoginPopup: true })
    } else if (
      window.location.hash &&
      window.location.hash === '#changePassword'
    ) {
      this.setState({ showChangePassword: true })
    }
  }

  showOpenFileDlg = () => {
    this.inputOpenFileRef.current.click()
  }

  closePopup = type => {
    type === 'projection'
      ? this.setState({ showProjectionPopup: false })
      : this.setState({ showLoginPopup: false })
  }

  openPopup = type => {
    type === 'projection'
      ? this.setState({ showLoginPopup: false })
      : this.setState({ showLoginPopup: false })
  }

  checkSaveActive = (inputValue) => {
    if (inputValue.length === 0) {
      this.setState({ isSaveActive: false })
    } else {
      if (
        this.state.name.length > 0 &&
        this.state.email.length > 0 &&
        ((this.state.roleInOrganization.length > 0 &&
          this.state.roleInOrganization !== 'Other') ||
          (this.state.roleInOrganization === 'Other' &&
            this.state.otherRole !== '')) &&
        this.state.organization.length > 0
      ) {
        this.setState({ isSaveActive: true })
      }
    }
  }

  uploadImage = () => {
    var input = document.getElementById('myProfilePic')
    var fReader = new FileReader()
    fReader.readAsDataURL(input.files[0])
    fReader.onloadend = function (event) {
      var img = document.getElementById('previewImg')
      img.src = event.target.result
      this.setState({ base64Logo: event.target.result })
    }.bind(this)
  }
  saveProfile = () => {
    var data = JSON.stringify({
      name: this.state.name,
      organization: this.state.organization,
      roleInOrganization: this.state.roleInOrganization,
      base64Logo: this.state.base64Logo,
      email: this.state.email
    })

    let newRoiData = this.state.local
    newRoiData.name = this.state.name
    newRoiData.organization = this.state.organization
    newRoiData.roleInOrganization = this.state.roleInOrganization
    newRoiData.base64Logo = this.state.base64Logo
    newRoiData.email = this.state.email
    localStorage.setItem('udgbl', JSON.stringify(newRoiData))
    this.setState({ local: newRoiData })

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
      }
    })

    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/user/updateUser/' +
      this.state.userId
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '09e7d2b1-9d9c-4e81-5db9-dc61a38de79c'
    )
    xhr.send(data)
  }

  checkContinueActive = () => {
    if (this.state.projectionName === '' || this.state.forWho === '') {
      this.setState({ isContinueActive: false })
    } else {
      this.setState({ isContinueActive: true })
    }
  }

  deleteProjection = id => {
    let newRoiData = this.state.local
    newRoiData.roiData[id].deleted = true
    var data = JSON.stringify({
      roiData: newRoiData.roiData
    })
    localStorage.setItem('udgbl', JSON.stringify(newRoiData))
    this.setState({ local: newRoiData })

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.addEventListener('readystatechange', function () { })
    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/user/updateROI/' +
      this.state.userId
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
    )
    xhr.send(data)
  }

  saveProjection = () => {
    let newRoiData = this.state.local
    newRoiData.roiData.push({
      projectionName: this.state.projectionName,
      forWho: this.state.forWho,
      date: new Date()
    })
    var data = JSON.stringify({
      roiData: newRoiData.roiData
    })

    localStorage.setItem('udgbl', JSON.stringify(newRoiData))
    localStorage.setItem('currentProg', newRoiData.roiData.length - 1)
    localStorage.setItem(
      'currentSelectedProg',
      [false, false, false, false, false, false, false].toString()
    )
    localStorage.setItem('from', 'projections')

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        localStorage.setItem('calculateRoi', 'false')
        window.location.href = '/select-programmes'
      }
    })

    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/user/updateROI/' +
      this.state.userId
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
    )
    xhr.send(data)
  }

  showDate(itemDate) {
    let lastDate = new Date(itemDate.substring(0, 10))
    return lastDate.toString().substring(0, 15)
  }
  LinkedInDisable() {
    if (this.state.emailType === 'LINKEDIN') {
      if (this.state.local.email && this.state.local.email !== '') {
        return false
      } else {
        return true
      }
    }
  }
  checkPasswordField = inputValue => {
    if (inputValue.length === 0) {
      this.setState({ savePasswordActive: false })
    } else {
      if (
        this.state.currentPassword.length > 0 &&
        this.state.newPassword.length > 0 &&
        this.state.confirmPassword.length > 0
      ) {
        if (this.state.newPassword === this.state.confirmPassword) {
          this.setState({ savePasswordActive: true })
        }
      }
    }
  }
  saveNewPassword() {
    this.checkCurrentPassword(this.state.email, this.state.currentPassword)
    this.setState({ showChangePassword: false })
  }

  checkCurrentPassword = (email, password) => {
    var data = JSON.stringify({
      username: email,
      password: password
    })

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    let that = this

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        let response = JSON.parse(this.responseText)
        if (!response.accessToken) {
          that.setState({
            passwordChangeSuccess: false,
            passwordChangeError: true,
            passwordErrorMsg: 'Your current password entered is incorrect'
          })
        } else {
          var data = JSON.stringify({
            password: that.state.newPassword
          })
          var xhr = new XMLHttpRequest()
          xhr.withCredentials = true

          xhr.addEventListener('readystatechange', function () {
            if (this.readyState === 4) {
              if (this.status === 200) {
                that.setState({
                  passwordChangeSuccess: true,
                  passwordChangeError: false
                })
              } else {
                console.log(
                  'Password update response failed',
                  JSON.parse(this.responseText)
                )
                that.setState({
                  passwordChangeSuccess: false,
                  passwordChangeError: true,
                  passwordErrorMsg:
                    'Password change failed. Contact your Administrator for further information.'
                })
              }
            }
          })
          xhr.open(
            'POST',
            that.state.apiUrl +
            '/api/user/updateGBLUNFPAUserPassword/' +
            that.state.userId
          )
          xhr.setRequestHeader('content-type', 'application/json')
          xhr.setRequestHeader('cache-control', 'no-cache')
          xhr.setRequestHeader(
            'postman-token',
            'a3efeb73-fc43-facf-34d2-c0016c07e63c'
          )
          xhr.send(data)
        }
      }
    })

    xhr.open('POST', this.state.apiUrl + '/api/auth/signin')
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      'postman-token',
      '997598a1-af66-ee8f-b642-957ad94e1f96'
    )

    xhr.send(data)
  }

  handleEvent = (e, i) => {
    e.stopPropagation()
    this.setState({
      showExitPopup: true,
      exitProjectionIndex: i
    })
  }
  testState = (selectedOption) => {
    let { roleInOrganization } = this.state;
    roleInOrganization = selectedOption.toString;
    this.setState({ roleInOrganization }, () => {
      console.log("Role in Organization");
    });
  }

  render() {
    let state = this.state
    let roi = JSON.parse(JSON.stringify(state.local.roiData))
    
    return (
      <div className='relativeElement'>
        <Header />
        {this.state.showExitPopup && (
          <div className='popupCard'>
            <div className='popup width30'>
              <div className='width100 h9 left-txt'>Delete Projection</div>
              <p className='h11 width80 left-txt buffer-bottom1'>
                You will lose all associated data from this projection. Are you
                sure?
              </p>
              <div className='row justifyContentFlexEnd'>
                <button
                  className='h6 justifyContentFlexEnd del-projection-btn'
                  onClick={() => this.setState({ showExitPopup: false })}
                >
                  No
                </button>
                <button
                  className='h6 justifyContentFlexEnd del-projection-btn'
                  onClick={e => {
                    this.setState({ showExitPopup: false })
                    this.deleteProjection(this.state.exitProjectionIndex)
                    e.stopPropagation()
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          id='loginPopup'
          className={
            this.state.showLoginPopup ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popupHigher'>
            <div className='row'>
              <div className='h5 pacificBlue-txt width100 left-txt buffer-bottom'>
                Setup User Profile
              </div>
              {this.state.local.name !== '' &&
                this.state.local.organization !== '' && (
                  <button
                    className='icon-btn pointer'
                    onClick={() => this.setState({ showLoginPopup: false })}
                  >
                    <img src={closeBtn} alt='close profile' />
                  </button>
                )}
            </div>
            <br />
            <input
              className='h11'
              ref={this.inputOpenFileRef}
              type='file'
              style={{ display: 'none' }}
              onChange={() => this.uploadImage()}
              id='myProfilePic'
            />
            <div
              className='width35 previewDiv relativeElement'
              onClick={() => {
                this.showOpenFileDlg()
                // this.checkSaveActive('image')
              }}
            >
              <img
                id='previewImg'
                className='previewImg pointer width100 buffer-bottom1'
                src={state.base64Logo}
                alt='Add your profile pic'
              />
              <img
                className='addImg pointer'
                src={addImg}
                alt='click to add '
              />
            </div>
            <div className='left-txt pacificBlue-txt h13'>
              Only jpeg, png format with max size of 5mb
            </div>
            <br />
            <br />
            <TextInput
              type='text'
              label='Your Name'
              value={this.state.name}
              onChange={event => {
                const name = event.target.value;
                this.setState({ name: name }, () => { this.checkSaveActive(name) })
              }}
            />
            <div
              className='mdc-text-field material-input'
              style={{ marginBottom: '1.5rem' }}
            >
              <input
                className={
                  'mdc-text-field__input h11 email-input ' +
                  (this.state.local.email ? 'notAllowed' : 'autoCursor')
                }
                type='text'
                id='emailInput'
                value={this.state.email}
                disabled={this.state.local.email}
                onChange={event => {
                  const email = event.target.value
                  this.setState({ email: email }, () => { this.checkSaveActive(email) })
                }}
              />
              <label
                className='mdc-floating-label mdc-floating-label--float-above'
                htmlFor='emailInput'
              >
                Email ID
              </label>
              <div className='mdc-line-ripple'></div>
            </div>
            <TextInput
              type='text'
              label='Organization'
              value={this.state.organization}
              onChange={event => {
                const org = event.target.value
                this.setState({ organization: org }, () => { this.checkSaveActive(org) })
              }}
            />
            <div className='h11 floatingLabel'>Role In Organization</div>
            <Select
              className='h11 width100 gallery-bg select-org'
              onChange={event => {
                const roleInOrg = event.target.value
                this.setState({ roleInOrganization: roleInOrg }, () => { this.checkSaveActive(roleInOrg) })
              }}
            >
              {this.state.roleInOrganization === '' ? (
                <option value='Role In Organization'>
                  Role in Organization
                </option>
              ) : (
                <option value={this.state.roleInOrganization}>
                  {this.state.roleInOrganization}
                </option>
              )}
              <option value='General Manager'>General Manager</option>
              <option value='Government Official'>Government Official</option>
              <option value='Human Resources Personnel'>
                Human Resources Personnel
              </option>
              <option value='NGO Representative'>NGO Representative</option>
              <option value='Organisational Development Personnel'>
                Organisational Development Personnel
              </option>
              <option value='Program Manager'>Program Manager</option>
              <option value='Researcher'>Researcher</option>
              <option value='Sustainability / CSR Officer'>
                Sustainability / CSR Officer
              </option>
              <option value='Other'>Other</option>
            </Select>
            {this.state.roleInOrganization === 'Other' && (
              <TextInput
                type='text'
                label='Please Specify'
                value={this.state.otherRole}
                onChange={event => {
                  this.checkSaveActive(event.target.value)
                  this.setState({ otherRole: event.target.value })
                }}
              />
            )}
            {this.state.isSaveActive ? (
              <div className='width50 margLeft25'>
                <img
                  className='pointer'
                  onClick={() => {
                    this.closePopup('login')
                    this.saveProfile()
                  }}
                  alt='Save details'
                  src={saveActive}
                />
              </div>
            ) : (
              <div className='width50 margLeft25'>
                <img
                  className='pointer width100'
                  src={saveInactive}
                  alt='Save info'
                />
              </div>
            )}
          </div>
        </div>
        {this.state.showChangePassword && (
          <div>
            <div
              className={
                this.state.showChangePassword
                  ? 'popupCard'
                  : 'popupCard dispNone'
              }
            >
              <div className='newProjectionPopUp'>
                <div className='row spaceBetween h5 pacificBlue-txt width100 left-txt'>
                  <div>Change Password</div>
                  <button
                    className='icon-btn pointer'
                    onClick={() => {
                      this.setState({ showChangePassword: false })
                    }}
                  >
                    <img src={closeBtn} alt='close' />
                  </button>
                </div>
                <br />
                <TextInput
                  type='password'
                  label='Current Password'
                  value={this.state.currentPassword}
                  onChange={event => {
                    this.setState({ currentPassword: event.target.value }, () =>
                      this.checkPasswordField(this.state.currentPassword)
                    )
                  }}
                />
                <TextInput
                  type='password'
                  label='New Password'
                  value={this.state.newPassword}
                  onChange={event => {
                    this.setState({ newPassword: event.target.value }, () =>
                      this.checkPasswordField(this.state.newPassword)
                    )
                  }}
                />
                <TextInput
                  type='password'
                  label='Confirm Password'
                  value={this.state.confirmPassword}
                  onChange={event => {
                    this.setState({ confirmPassword: event.target.value }, () =>
                      this.checkPasswordField(this.state.confirmPassword)
                    )
                  }}
                />
                {this.state.savePasswordActive ? (
                  <div className='width75 margLeft125'>
                    <img
                      style={{ maxWidth: '15rem' }}
                      className='pointer'
                      onClick={() => {
                        this.setState({ showChangePassword: false })
                        this.saveNewPassword()
                      }}
                      alt='Save new Password'
                      src={continueActive}
                    />
                  </div>
                ) : (
                  <div className='width75 margLeft125'>
                    <img
                      className='pointer'
                      src={continueInactive}
                      alt='Save new Password'
                      style={{ maxWidth: '15rem' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.passwordChangeSuccess && (
          <div>
            <div
              className={
                this.state.passwordChangeSuccess
                  ? 'popupCard'
                  : 'popupCard dispNone'
              }
            >
              <div className='newProjectionPopUp'>
                <div className='row spaceBetween h5 pacificBlue-txt width100 left-txt'>
                  <div>Your password has been changed</div>
                </div>
                <br />

                <div className='width75 margLeft125'>
                  <img
                    style={{ maxWidth: '15rem' }}
                    className='pointer'
                    onClick={() => {
                      this.setState({ passwordChangeSuccess: false })
                    }}
                    alt='Ok'
                    src={continueActive}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.passwordChangeError && (
          <div>
            <div
              className={
                this.state.passwordChangeError
                  ? 'popupCard'
                  : 'popupCard dispNone'
              }
            >
              <div className='newProjectionPopUp'>
                <div className='row spaceBetween h5 pacificBlue-txt width100 left-txt'>
                  <div>{this.state.passwordErrorMsg}</div>
                </div>
                <br />

                <div className='width75 margLeft125'>
                  <img
                    style={{ maxWidth: '15rem' }}
                    className='pointer'
                    onClick={() => {
                      this.setState({ passwordChangeError: false })
                    }}
                    alt='Ok'
                    src={continueActive}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          id='projectionPopup'
          className={
            this.state.showProjectionPopup ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='newProjectionPopUp'>
            <div className='row spaceBetween h5 pacificBlue-txt width100 left-txt'>
              <div> New Projection</div>
              <button
                className='icon-btn pointer'
                onClick={() => {
                  this.setState({ showProjectionPopup: false })
                  this.setState({ projectionName: '' })
                  this.setState({ forWho: '' })
                }}
              >
                <img src={closeBtn} alt='close' />
              </button>
            </div>
            <br />
            <TextInput
              type='text'
              label='Name your projection'
              value={this.state.projectionName}
              onChange={event => {
                this.checkContinueActive()
                this.setState({ projectionName: event.target.value })
              }}
            />
            <div className='h11 margBot16 left-txt buffer-bottom'>
              Organise your projections by naming them so that you can access
              them with ease on your dashboard
              <br />
              <a
                target='_blank'
                rel='noreferrer'
                href='/roi-tGuide#projectionName'
                className='treePoppy-txt'
              >
                Learn more
              </a>
            </div>
            <TextInput
              type='text'
              label='Who is this for?'
              value={this.state.forWho}
              onChange={event => {
                this.checkContinueActive()
                this.setState({ forWho: event.target.value })
              }}
            />
            <div className='h11  left-txt buffer-bottom2'>
              Specific workers’ group for whom you intend to implement the
              programme (for example, Women aged 18-50)
            </div>
            {this.state.isContinueActive ? (
              <div className='width75 margLeft125'>
                <img
                  style={{ maxWidth: '15rem' }}
                  className='pointer'
                  onClick={() => {
                    this.closePopup('projection')
                    this.saveProjection()
                  }}
                  alt='Continue'
                  src={continueActive}
                />
              </div>
            ) : (
              <div className='width75 margLeft125'>
                <img
                  className='pointer'
                  src={continueInactive}
                  alt='Continue'
                  style={{ maxWidth: '15rem' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='whisper-bg flex minHeight90'>
          <div className='bar width50'>
            <br />
            <br />
            <div className='flex alignItemsCenter  buffer-bottom'>
              <div className='h9 padRight24 nowrap'>My Projections</div>
              <div
                style={{ maxWidth: '15rem' }}
                onClick={() => {
                  this.setState({ showProjectionPopup: true })
                }}
              >
                <img
                  className='pointer'
                  src={projectionBtn}
                  alt='Start new projection'
                />
              </div>
            </div>
            <br />
            <div className='h11 left-txt emperor-txt '>
              Each projection you are trying out should be for a single factory.{' '}
              <br />
              <br />
              How does it work?
              <ol>
                <li className='emperor-txt '>Select/shortlist programme/s</li>
                <li className='emperor-txt '>
                  Compare the impacts & Projected ROI for these programmes
                </li>
                <li className='emperor-txt '>Exports reports</li>
              </ol>
            </div>
            <br />
            <br />
            <div className='margBot16 padRight48 flexReverse'>
              {roi.map((item, i) => {
                if (!item.deleted) {
                  return (
                    <div
                      key={'projectionsKey' + i}
                      className='card flex alignItemsCenter justifyContentSpaceBetween relativeElement pointer'
                      onClick={() => {
                        localStorage.setItem('currentProg', i)
                        localStorage.setItem(
                          'currentSelectedProg',
                          this.state.local.roiData[i].selPrograms
                            ? this.state.local.roiData[i].selPrograms
                            : [
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false
                            ].toString()
                        )
                        localStorage.setItem('from', 'projections')
                        if (
                          this.state.local.roiData[i].selPrograms &&
                          !this.state.local.roiData[i].companyDetails
                        ) {
                          window.location.href = '/company'
                        } else if (
                          this.state.local.roiData[i].companyDetails &&
                          (!this.state.local.roiData[i].programmeDetails ||
                            localStorage.getItem('calculateRoi') === 'false')
                        ) {
                          window.location.href = '/input'
                        } else if (
                          this.state.local.roiData[i].programmeDetails ||
                          localStorage.getItem('calculateRoi') === 'true'
                        ) {
                          window.location.href = '/roi'
                        } else {
                          window.location.href = '/select-programmes'
                        }
                      }}
                    >
                      <div className='width80'>
                        <div className='h11 left-txt nowrap buffer-bottom1'>
                          {item.projectionName}
                        </div>
                        <div className='row spaceBetween'>
                          <div className='h12 left-txt nowrap'>
                            {item.forWho}
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <button
                          className='buffer-bottom1 icon-btn buffer-bottom1'
                          onClick={event => this.handleEvent(event, i)}
                        >
                          <img src={closeBtn} alt='delete projection' />
                        </button>
                        <div className='h14 matterhorn-txt nowrap'>
                          Last edited on {this.showDate(item.date)}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return <div key={'projectionsKey' + i}></div>
                }
              })}
            </div>
          </div>
          <div className='rightSection'>
            <br />
            <br />
            <br />
            <br />
            <div className='h6 right-txt  buffer-bottom'>
              <a
                href='/caseStudies'
                target='_blank'
                className='pointer treePoppy-txt'
              >
                Learn how
              </a>
              <span>
                {' '}
                this Philippines-based business <br />
                empowers employees with Family Planning.
              </span>
            </div>
            <br />
            {state.local.roiData.length > 0 && (
              <div className='h6 right-txt'>
                <span>
                  <a href="/contact" target="_blank" className=" treePoppy-txt pointer">Contact UNFPA</a>
                  <br />
                </span>{' '}
                to implement your programmes.
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default ProjectionsView
