//eslint-disable-next-line 
import React, { Component } from 'react';
import LoginView from '../../../../views/Users/ROITool/Login/LoginView'

class LoginController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            existingUserLogin: false,
            email: '',
            password: '',
            repeatPassword: '',
            existingEmail: '',
            existingPassword: '',
            showForgotPassword: false,
            showPopup: false,
            weakPassword: false,
            popupMsg: '',
            errorPopHeader: '',
            emailFrom: 'others',
            apiUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL
        };
        this.redirect = this.redirect.bind(this);
    }
    redirect(path) {
        return this.props.history.push(path);
    }

    signup = () => {
        let that = this;
        // if (this.state.emailFrom === "LINKEDIN" || this.state.emailFrom === "MICROSOFT") {
        //     this.openPopup('Access denied');
        //     return
        // }
        if (this.state.emailFrom === "others") {
            if (
                this.state.email === '' ||
                this.state.password === '' ||
                this.state.repeatPassword === ''
            ) {
                this.setState({ showForgotPassword: false });
                this.setState({ errorPopHeader: "Invalid Entry" });
                this.openPopup('All the fields are required');
                return
            }

            if (this.state.password !== this.state.repeatPassword) {
                this.setState({ showForgotPassword: false });
                this.setState({ errorPopHeader: "Invalid Entry" });
                this.openPopup('Password and Confirm Passwords do not match')
                return
            }
            if (this.state.weakPassword) {
                this.setState({ showForgotPassword: false });
                this.setState({ errorPopHeader: "Weak Password" });
                this.openPopup('Please include 1 capital letter, 1 lower case, 1 number, 1 special character and minimum password length of  8 characters.');
                return
            }

        }

        var data = JSON.stringify({
            username: this.state.email,
            email: this.state.email,
            password: this.state.password,
            roles: ['user'],
            emailType: this.state.emailFrom
        })

        var xhr = new XMLHttpRequest()
        xhr.withCredentials = true

        xhr.addEventListener('readystatechange', function () {            
            if (this.status === 200) {
                that.loginUser(that.state.email, that.state.password)
            } else if (this.status > 200) {
                that.setState({ errorPopHeader: 'Username exists' })
                that.openPopup('This username already exists')
            }
        });
        xhr.open(
            'POST',
            this.state.apiUrl + '/api/auth/signup'
        )
        xhr.setRequestHeader('content-type', 'application/json')
        xhr.setRequestHeader('cache-control', 'no-cache')
        xhr.setRequestHeader(
            'postman-token',
            '0afac474-45ae-c5be-1a0d-68e7dac09adc'
        )

        xhr.send(data)
    }


    login = () => {
        if (this.state.existingEmail === '' || this.state.existingPassword === '') {
            this.setState({ showForgotPassword: false })
            this.openPopup('All the fields are required')
            return
        }
        this.loginUser(this.state.existingEmail, this.state.existingPassword)
    }

    loginUser = (email, password) => {
        var data = JSON.stringify({
            username: email,
            password: password
        })

        localStorage.clear();

        let that = this

        var xhr = new XMLHttpRequest()
        xhr.withCredentials = true

        xhr.addEventListener('readystatechange', function () {
            if (this.readyState === 4) {
                let response = JSON.parse(this.responseText);                
                if (!response.accessToken) {
                    that.setState({ showForgotPassword: false })
                    that.openPopup('The username/ password entered is incorrect. Please check your entered details.')
                } else {
                    localStorage.setItem('udgbl', this.responseText)
                    var data = null

                    var xhr = new XMLHttpRequest()
                    xhr.withCredentials = true

                    xhr.addEventListener('readystatechange', function () {
                        if (this.readyState === 4) {
                            localStorage.setItem('programmes', this.responseText)
                            window.location.href = '/projections'
                        }
                    })

                    xhr.open('GET', that.state.apiUrl + '/api/programmes/allProgrammes')
                    xhr.setRequestHeader('cache-control', 'no-cache')
                    //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                    xhr.setRequestHeader(
                        'postman-token',
                        '06c68b89-3180-9426-2f89-0b3a540242fe'
                    )

                    xhr.send(data)
                }
            }
        })

        xhr.open('POST', this.state.apiUrl + '/api/auth/signin')
        xhr.setRequestHeader('content-type', 'application/json')
        xhr.setRequestHeader('cache-control', 'no-cache')
        //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader(
            'postman-token',
            '997598a1-af66-ee8f-b642-957ad94e1f96'
        )

        xhr.send(data)
        localStorage.emailType = this.state.emailFrom;
    }
    closePopup = () => {
        this.setState({ showPopup: false });
    }

    openPopup = msg => {
        this.setState({ popupMsg: msg, showPopup: true })
    }


    handleAuthSignUpSuccess = (res) => {        
        if (res && res.from && res.data) {
            this.setState({
                emailFrom: res.from,
                email: res.data.email && res.data.email,
                username: res.data.fullName && res.data.fullName
            }, this.signup);
        }
    }
    handleAuthSignUpError = (res) => {
        console.log('handleAuthSignUpError', res);
    }
    handleAuthSignInSuccess = (res) => {
        console.log('handleAuthSignInSuccess', res);
    }
    handleAuthSignInError = (res) => {
        console.log('handleAuthSignInError', res);
    }
    checkPassword = (event) => { }
    setPassword(event) {
        let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        let goodPassword = passwordRegex.test(event.target.value);
        if (goodPassword) {
            this.setState({ weakPassword: false });
        } else {
            this.setState({ weakPassword: true });
        }
        this.setState({ password: event.target.value });
    }
    signupAnyway = () => {
        this.closePopup();
        this.setState({ showPopup: false, weakPassword: false }, this.signup);
    }
    render() {
        return (
            <LoginView
                {...this.state}
                redirect={this.redirect}
                onAuthSignUpSuccess={this.handleAuthSignUpSuccess}
                onAuthSignUpFailure={this.handleAuthSignUpError}
                onAuthSignInSuccess={this.handleAuthSignInSuccess}
                onAuthSignInFailure={this.handleAuthSignInError}
                signup={this.signup}
                signupAnyway={this.signupAnyway}
                closePopup={this.closePopup}
                login={this.login}
                checkExistingUserLogin={() => this.setState({ existingUserLogin: true })}
                setEmail={(event) => this.setState({ email: event.target.value })}
                setPassword={(event) => this.setPassword(event)}
                setRepeatPassword={(event) => this.setState({ repeatPassword: event.target.value }, this.checkPassword)}
                openExistingUserLogin={() => this.setState({ existingUserLogin: false })}
                setExisitingEmail={(event) => this.setState({ existingEmail: event.target.value })}
                setExisitingPassword={(event) => this.setState({ existingPassword: event.target.value })}
            />
        )
    }
}
export default LoginController;