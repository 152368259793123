//eslint-disable-next-line 
import React, { Component } from 'react';
import './AboutView.scss';
import '../../../../index.scss';
import Header from '../../../../components/Header/Header';
import Button from '../../../../components/Button/Button';
import about1 from '../../../../assets/images/about1.png';
import about3 from '../../../../assets/images/about3.png';
import Footer from '../../../../components/Footer/Footer';

class AboutView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    redirect(path) {
        window.location.href = path;
    }

    render() {
        return (
            <div id="aboutTop">
                <Header />
                <div className="about-section">
                    <div className="col1">
                        <div className="h2 buffer-bottom2">{this.props.aboutTitle1}</div>
                        <div className="h11">
                            <p>{this.props.aboutSection1Para1}</p>

                            <p>{this.props.aboutSection1Para2}  <b> {this.props.aboutSection1Para2b}</b></p>

                            <p>{this.props.aboutSection1Para3}</p>

                        </div>
                    </div>
                    <div className="col2">
                        <figure>
                            <img src={about1} alt=" Fahim Tajrin" /><figcaption className="h13 left-txt emperor-bg white-txt">© Photo Credits: UNFPA Bangladesh / Fahim Tajrin </figcaption></figure>
                    </div>
                </div>
                <div className="gallery-bg about-section2">
                    <div className="h3">{this.props.aboutTitle2}</div>
                    <br />
                    <div className="row">
                        <Button buttonText="ROI-T Guide" className="h5 treePoppy-txt" onClick={() => this.props.redirect("/roi-tGuide")} />

                        <Button buttonText="Our Research" className="h5 treePoppy-txt" onClick={() => this.props.redirect("/programmes#ourResearch")} />
                    </div>

                </div>
                <div className="about-section">
                    <div className="col1">
                        <div className="h2 buffer-bottom2">{this.props.aboutTitle3}</div>
                        <div className="h11">
                            <p><b>{this.props.aboutSection3Para1}</b>{this.props.aboutSection3Para1b}</p>

                            <p>{this.props.aboutSection3Para2}</p>

                            <p>{this.props.aboutSection3Para3}</p>

                            <div>{this.props.aboutSection3Para4 && this.props.aboutSection3Para4.map((value, index) => <div key={index}>{value}<br /></div>)}</div>

                            <p>{this.props.aboutSection3Para5} <span><b><a target="_blank" rel="noreferrer" href={this.props.aboutSection3Link} className="treePoppy-txt">{this.props.aboutSection3LinkText}</a></b></span></p>


                        </div>
                    </div>
                    <div className="col2">
                        <iframe className="aboutIframe"
                            src="https://www.youtube.com/embed/zjKjb-XPoGQ" title="9500 Afghan women gave birth safely" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className="about-section gallery-bg">
                    <div className="col1">
                        <div className="h2 buffer-bottom2">{this.props.aboutTitle4}</div>
                        <div className="h11">
                            <p>{this.props.aboutSection4Para1}<b> {this.props.aboutSection4Para1b}</b></p>

                            <p>{this.props.aboutSection4Para2}</p>

                            <p>{this.props.aboutSection4Para3}</p>



                        </div>
                    </div>
                    <div className="col2">
                        <figure>
                            <img src={about3} alt=" © UNFPA Bangladesh" /><figcaption className="h13 left-txt emperor-bg white-txt"> © UNFPA Bangladesh</figcaption></figure>
                    </div>
                </div>
                <div className="home-section8 spaceBetween">
                    <div className="h3 left-txt col1">{this.props.aboutTitle5}</div>
                    <div className="col2"><Button className="home-section8-btn h4" buttonText="Get Started Now" onClick={() => this.props.goLogin()} /></div>
                </div>
                <Footer />
            </div >
        )
    }
}
export default AboutView;