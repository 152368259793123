//eslint-disable-next-line
import React, { Component } from 'react'
import Header from '../../../../components/Header/Header'
import Button from '../../../../components/Button/Button'
import './RIOTGuideView.scss'
import '../../../../index.scss'
// import about2 from '../../../../assets/images/about2.png'
import downArrow from '../../../../assets/images/downArrow.png'
import RIOTGuide1 from '../../../../assets/images/RIOTGuide1.png'
import RIOTGuide2 from '../../../../assets/images/RIOTGuide2.png'
import downArrow2 from '../../../../assets/images/downArrowGrey.png'
import Footer from '../../../../components/Footer/Footer'
import checklist from '../../../../assets/files/Mega checklist.xlsx'

class RIOTGuideView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: [false, false, false, false],
      faqCards: [false, false, false, false, false, false, false, false, false]
    }
  }
  redirect(path) {
    window.location.href = path;
  }

  expandCard(cardNo) {
    let { cards } = this.state
    cards[cardNo - 1] = !cards[cardNo - 1]
    this.setState({ cards })
  }
  expandFAQ(FAQNo) {
    let { faqCards } = this.state
    faqCards[FAQNo] = !faqCards[FAQNo]
    this.setState({ faqCards })
  }
  render() {
    return (
      <div id='roitTop' style={{ overflowX: 'hidden' }}>
        <Header />
        <div className='guide-section1'>
          <div className='col1'>
            <div className='h2 buffer-bottom2' style={{ width: '21rem' }}>
              {this.props.roitTitle1}
            </div>
            <div className='h11'>{this.props.roitSection1Txt}</div>
          </div>
          <div className='col2'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BXZ8PcGnhP4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <img src={about2} alt='Placeholder' /> */}
          </div>
        </div>
        <div className='gallery-bg guide-section2'>
          <div className='row1'>
            <div className='h2 buffer-bottom2'>{this.props.roitTitle2}</div>
            <div className='h9 buffer-bottom3'>
              {this.props.roitSection2Txt}
            </div>
            <Button
              className='treePoppy-bg white-txt h5'
              buttonText='Get Started Now'
              onClick={() => this.props.goLogin()}
            />
          </div>
          <div className='row2'>
            <div className='col'>
              <div>
                <figure>
                  <img
                    src={RIOTGuide1}
                    alt='Identify suitable health or health-related programmes based on your workers’ welfare objectives, to check their ROI and more.'
                    className='buffer-bottom'
                  />
                  <figcaption className='h9 guide-caption'>
                    Identify suitable health or health-related programmes based
                    on your workers’ welfare objectives, to check their ROI and
                    more.
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className='col'>
              <div>
                <figure>
                  <img
                    src={RIOTGuide2}
                    alt='Compare real-time values through a guided process and make the most of your workspace.'
                    className='buffer-bottom'
                  />
                  <figcaption className='h9 guide-caption'>
                    Compare real-time values through a guided process and make
                    the most of your workspace.
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className='guide-section5' >
          <div className='row1'>
            <div className='h2 buffer-bottom'>{this.props.roitTitle7}</div>
            <div className='h9'>{this.props.roitSection7Txt}</div>
          </div>
        </div>
        <div className='guide-section6'>
          <div className='row1 buffer-bottom3'>
            <div className='h2 buffer-bottom' id="projectionName">{this.props.roitTitle8}</div>
            <div className='h9'>{this.props.roitSection8Txt1}</div><br /><br />
            <div className='h9'>{this.props.roitSection8Txt2}</div>
          </div>
          <div className="row justifyContentSpaceBetween guide-section6-example">
            {this.props.roitSection8Example && this.props.roitSection8Example.map((value) => {
              return (<div key={value[0]}>
                <div className="h9">{value[0]}</div>
                <div className="h10 buffer-bottom3">{value[1]}</div>
                <div className="h10">{value[2]}</div>
                <div className="h11 buffer-bottom3">{value[3]}</div>
                <div className="h10">{value[4]}</div>
                <div className="h11">{value[5]}</div></div>)
            })}
          </div>
        </div>
        <div className='guide-section2' id="guide" >
          <div className='row1 buffer-bottom3'>
            <div className='h2 buffer-bottom2'>{this.props.roitTitle3}</div>
            <div className='h9'>{this.props.roitSection3Txt}</div>
          </div>
          <div className='row2 buffer-bottom1'>
            <div className='gallery-bg col guide-cards'>
              <div className='h5 buffer-bottom'>
                {this.props.dataFieldTitle1}
              </div>
              <div
                className={
                  'h11 buffer-bottom1 ' +
                  (this.state.cards[0] ? 'dataAnswer' : 'dataQues')
                }
              >
                <div>{this.props.dataFieldTxt1}</div>
              </div>
              <span
                className='h10'
                style={{ display: this.state.cards[0] ? 'none' : 'block' }}
              >
                ...
              </span>
              <button
                className='h6 row readMore'
                onClick={() => this.expandCard(1)}
              >
                <div
                  className='treePoppy-txt'
                  style={{ paddingRight: '0.938rem' }}
                >
                  {this.state.cards[0] ? 'Read Less' : 'Read More'}{' '}
                </div>
                <div
                  className={
                    this.state.cards[0] ? 'downArrow-transform' : 'downArrow'
                  }
                >
                  <img src={downArrow} alt='Down arrow' />
                </div>
              </button>
            </div>

            <div className='gallery-bg col guide-cards'>
              <div className='h5 buffer-bottom'>
                {this.props.dataFieldTitle3}
              </div>
              <div
                className={
                  'h11 buffer-bottom1 ' +
                  (this.state.cards[1] ? 'dataAnswer' : 'dataQues')
                }
              >
                <div>{this.props.dataFieldTxt3}</div>
              </div>
              <span
                className='h10'
                style={{ display: this.state.cards[1] ? 'none' : 'block' }}
              >
                ...
              </span>
              <button
                className='h6 readMore row'
                onClick={() => this.expandCard(2)}
              >
                <div
                  className='treePoppy-txt'
                  style={{ paddingRight: '0.938rem' }}
                >
                  {this.state.cards[1] ? 'Read Less' : 'Read More'}{' '}
                </div>
                <div
                  className={
                    this.state.cards[1] ? 'downArrow-transform' : 'downArrow'
                  }
                >
                  <img src={downArrow} alt='Down arrow' />
                </div>
              </button>
            </div>
          </div>
          <div className='row2'>
            <div className='gallery-bg col guide-cards'>
              <div className='h5 buffer-bottom'>
                {this.props.dataFieldTitle2}
              </div>
              <div
                className={
                  'h11 buffer-bottom1 ' +
                  (this.state.cards[2] ? 'dataAnswer' : 'dataQues')
                }
              >
                <div>{this.props.dataFieldTxt2}</div>
              </div>
              <span
                className='h10'
                style={{ display: this.state.cards[2] ? 'none' : 'block' }}
              >
                ...
              </span>
              <button
                className='h6 row readMore'
                onClick={() => this.expandCard(3)}
              >
                <div
                  className='treePoppy-txt'
                  style={{ paddingRight: '0.938rem' }}
                >
                  {this.state.cards[3] ? 'Read Less' : 'Read More'}{' '}
                </div>
                <div
                  className={
                    this.state.cards[2] ? 'downArrow-transform' : 'downArrow'
                  }
                >
                  <img src={downArrow} alt='Down arrow' />
                </div>
              </button>
            </div>
            <div className='gallery-bg col guide-cards'>
              <div className='h5 buffer-bottom'>
                {this.props.dataFieldTitle4}
              </div>
              <div
                className={
                  'h11 buffer-bottom1 ' +
                  (this.state.cards[3] ? 'dataAnswer' : 'dataQues')
                }
              >
                <div>{this.props.dataFieldTxt4}</div>
              </div>
              <span
                className='h10'
                style={{ display: this.state.cards[3] ? 'none' : 'block' }}
              >
                ...
              </span>
              <button
                className='h6 row readMore'
                onClick={() => this.expandCard(4)}
              >
                <div
                  className='treePoppy-txt'
                  style={{ paddingRight: '0.938rem' }}
                >
                  {this.state.cards[4] ? 'Read Less' : 'Read More'}{' '}
                </div>
                <div
                  className={
                    this.state.cards[3] ? 'downArrow-transform' : 'downArrow'
                  }
                >
                  <img src={downArrow} alt='Down arrow' />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className='guide-section3'>
          <div className='row1'>
            <div className='h2 buffer-bottom2'>{this.props.roitTitle4}</div>
            <div className='h9 buffer-bottom1'>
              {this.props.roitSection4Txt}
            </div>
          </div>
          <div className='row2 buffer-bottom2'>
            <div className='col'>
              <div className='h5 buffer-bottom1'> {this.props.dataType1}</div>

              <div className='h11'>{this.props.dataType1Txt}</div>
            </div>
            <div className='col'>
              <div className='h5 buffer-bottom1'>{this.props.dataType2}</div>

              <div className='h11'>{this.props.dataType2Txt}</div>
            </div>
            <div className='col'>
              <div className='h5 buffer-bottom1'>{this.props.dataType3}</div>

              <div className='h11'>{this.props.dataType3Txt}</div>
            </div>
          </div>
          <div className='h13 row3'>{this.props.dataType1Txt2}<a href="/programmes" style={{ color: "#f7941e" }}>{this.props.dataType1Txt2Link}</a>{this.props.dataType1Txt2b}
          </div>
        </div>
        <div className='home-section8 spaceBetween gallery-bg'>
          <div className='h3 left-txt col1'>{this.props.roitMegaChecklist}</div>
          <div className='col2'>
            {/* <Button className="home-section8-btn h5" buttonText="Get the Megachecklist" /> */}
            <button className='checklist-btn h5'>
              {' '}
              <a
                className='white-txt'
                href={checklist}
                download='ROI-T invest in her -  Mega checklist'
              >
                Get the Megachecklist{' '}
              </a>
            </button>
          </div>
        </div>
        <div className='guide-section4' id='FAQ'>
          <div className='left-txt h2 buffer-bottom2'>
            {this.props.roitTitle5}
          </div>
          <div className='FAQContainer'>

            {this.props.faq &&
              this.props.faq.map((value, index) => (

                <div className='gallery-bg FAQCard pointer' key={index} onClick={() => this.expandFAQ(index)}>
                  <div className='row spaceBetween buffer-bottom1'>
                    <div className='h5 FAQQues '>{value.question}</div>
                    <button
                      className='readMore'

                    >
                      <div
                        className={
                          this.state.faqCards[index]
                            ? 'downArrow-transform'
                            : 'downArrow'
                        }
                      >
                        <img src={downArrow2} alt='Down arrow' />
                      </div>
                    </button>
                  </div>
                  <div
                    className={
                      'h11 left-txt ' +
                      (this.state.faqCards[index] ? 'FAQAnswer' : 'hideDiv')
                    }
                  >
                    {value.answer}
                  </div>
                  {/* <div className={`h11 left-txt ${index === 0 ? "FAQAnswer" : "hideDiv"}`}>{value.answer}</div> */}
                </div>

              ))}

            {/* {this.props.faq && this.props.faq.map((value, index) => (
              <div className='gallery-bg FAQCard' key={index}>
                <div className='row spaceBetween buffer-bottom1'>
                  <div className='h5 FAQQues'>{value.question}</div>
                  <button
                    className='readMore'
                    onClick={() => this.expandFAQ(index)}
                  >
                    <div
                      className={
                        this.state.faqCards[index]
                          ? 'downArrow-transform'
                          : 'downArrow'
                      }
                    >
                      <img src={downArrow2} alt='Down arrow' />
                    </div>
                  </button>
                </div>
                <div
                  className={
                    'h11 left-txt ' +
                    (this.state.faqCards[index] ? 'FAQAnswer' : 'hideDiv')
                  }
                >
                  {value.answer}
                </div>
                {/* <div className={`h11 left-txt ${index === 0 ? "FAQAnswer" : "hideDiv"}`}>{value.answer}</div> */}
            {/* </div>
            ))} } */}
          </div>
        </div>
        <div className='home-section8 spaceBetween gallery-bg'>
          <div className='h3 left-txt col1'>{this.props.roitTitle6}</div>
          <div className='col2'>
            <Button
              className='home-section8-btn h4'
              buttonText='Get Started Now'
              onClick={() => this.props.goLogin()}
            />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
export default RIOTGuideView
