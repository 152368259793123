//eslint-disable-next-line 
import React from 'react';
import HomeView from '../../../../views/Users/Public/Home/HomeView';
import { useSelector, connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

function HomeController(props) {
    let history = useHistory();
    let local = JSON.parse(localStorage.getItem('udgbl'));
    let showLogin = true;
    let accessToken = '';
    if (local) {
        accessToken = local.accessToken;
        showLogin = !(accessToken && accessToken !== "");

    }
    const homeContent = useSelector(state => {
        if (state.loadCMS.cms[0]) {
            return state.loadCMS.cms[0]["cmsJSON"];
        } else {
            return state.loadCMS;
        }
    });
    useEffect(() => {

        document.getElementById("homeTop").scrollIntoView();

    }, []);

    const redirect = (path) => {
        return history.push(path);
    }

    const goLogin = () => {
        if (showLogin) {
            return history.push("/login");

        } else {
            return history.push("/projections");
        }
    }

    return (
        <HomeView
            {...homeContent}
            redirect={redirect}
            goLogin={goLogin}

        />
    )

}
export default connect()(HomeController);