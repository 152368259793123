//eslint-disable-next-line 
import React from 'react';
import CaseStudiesView from '../../../../views/Users/Public/CaseStudies/CaseStudiesView'
import { useSelector, connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

function CaseStudiesController() {
    let history = useHistory();
    let local = JSON.parse(localStorage.getItem('udgbl'));
    let showLogin = true;
    let accessToken = '';
    if (local) {
        accessToken = local.accessToken;
        showLogin = !(accessToken && accessToken !== "");

    }

    const caseStudiesContent = useSelector(state => {
        if (state.loadCMS.cms[3]) {
            return state.loadCMS.cms[4]["cmsJSON"];
        } else {
            return state.loadCMS;
        }
    });
    useEffect(() => {

        document.getElementById("caseStudyTop").scrollIntoView();

    }, []);

    const goLogin = () => {
        if (showLogin) {
            return history.push("/login");

        } else {
            return history.push("/projections");
        }
    }

    return (
        <div>
            <CaseStudiesView
                {...caseStudiesContent}
                goLogin={goLogin}
            />
        </div>
    )

}

export default connect()(CaseStudiesController);

