//eslint-disable-next-line 
import React, { Component } from 'react';
import './PrivacyView.scss';
import '../../../../index.scss';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';

class PrivacyView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="privacyTop">
                <Header />
                <div className="left-txt privacy-section">
                    <div className="h2">Privacy Policy</div>
                    <div className="h13">
                        <p>Your privacy on the Internet is of utmost importance to the United Nations Population Fund (“UNFPA”). This privacy statement describes UNFPA’s policy concerning the gathering and sharing of visitors’ information through the UNFPA website. It applies to all “UNFPA sites” – that is, all sites within the “UNFPA.org” domain and UNFPA country sites (hereinafter together referred to as the “UNFPA website” or “our website”).</p>

                        <p>This privacy policy describes what information is made available to UNFPA and third parties when you visit the UNFPA website, and how UNFPA uses and stores that information. Any information voluntarily disclosed to UNFPA by users of UNFPA website is held with the utmost care and security and will not be used in ways other than as set forth in this privacy policy.</p>
                    </div>

                    <div className="h13">
                        <p> <b>Anonymous website usage</b><br />
                            By accessing the UNFPA website, general browsing data from standard server logs, such as Internet protocol (“IP”) addresses, navigation through the UNFPA website, domain name, browser type, the software used and the time spent, along with other similar information, will be stored.</p>

                        <p>This information will be used to analyze trends and usage of the UNFPA website and to improve the usefulness of the site. It is not connected with any personal information. However, if you have registered with UNFPA in connection with any activity on the UNFPA website, the information we collect about your normal web usage will be identifiable to you.</p>
                    </div>

                    <div className="h13">
                        <p> <b>Collection and use of personally identifiable information</b><br />
                            Providing personal information is your choice. When you undertake certain actions on our website, such as donating, signing up for our email newsletter, or filling in a form to register your interest in our activities, you knowingly provide us with personal information about you, such as your name, email address, postal address and other information. This information is collected only with your knowledge and permission, and is kept in various UNFPA databases. If you are making a donation through the
                            UNFPA website, you may be asked to provide your credit card details.</p>

                        <p>UNFPA sites with specific requirements to collect personal information may publish a privacy policy specific for that site. In these cases, the site-specific policies will be complementary to this UNFPA privacy policy, but will give additional details for that particular use.</p>

                        <p>We may use the personal information you voluntarily provide to:</p>


                        <p><b>Contact you</b> – either in response to a query or suggestion, or to provide to you relevant services and information (such as newsletters, documents, publications etc.) that we deem may be of interest to you. This may include marketing and fundraising appeals from time to time. If you would not like your data to be used in this way, please send your query here and select “individual giving.”</p>

                        <p> <b>What if I don’t want to provide personal information?</b><br />
                            Providing personal information on the UNFPA website is optional. If you choose not to provide personal information, you can still browse and use the UNFPA website, but you will not be able to carry out certain actions such as registering for a newsletter or applying for a job.</p>

                        <p> <b>Opting out and changing your information</b><br />
                            Users who provide an email address and/or donate via the UNFPA website and opt in to receive email communication from UNFPA may receive UNFPA electronic newsletter and other email updates. We occasionally also communicate via direct mail, phone and SMS.  Each email sent by UNFPA includes an option to remove your address from the mailing list. You may also request assistance here and select “individual giving”.</p>

                    </div>
                    <div className="h13">
                        <p> <b>Cookie Policy</b><br />
                            Aside from the personal information that you actively disclose to us for a specific purpose, UNFPA may also collect and store anonymous information about your browsing behaviour when you visit our website,
                            through the use of cookies. By using the UNFPA website, you agree that we can place the types of cookies set out below on your computer, mobile phone or handheld device and use such information in accordance with this Cookie Policy.</p>

                        <p> <b>What are cookies?</b><br />
                            Cookies are small data files made up of letters and numbers placed onto your computer or other mobile devices when you access the UNFPA website. UNFPA uses cookies to collect information about you to the extent necessary to ensure its website’s functionality and performance as well as for the purpose of targeting its audience and tailoring the content offered to the public both on its website and elsewhere.</p>

                        <p>We send cookies when you visit the UNFPA websites, respond to online surveys or polls, or request information. Accepting the cookies used on the UNFPA website does not give us access to your personally identifiable information, but we can use the cookies to, for example, identify your computer or from where you are visiting the UNFPA website.</p>

                        <p> <b>Disabling cookies</b><br />
                            If you do not wish to have cookies installed on your computer or mobile device, you can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do so, however, some areas of the UNFPA website may not function properly.</p>

                        <p> <b>How cookies are used on UNFPA website</b><br />
                            UNFPA wishes to be transparent with users about how cookies are used, so that you are clear on how your privacy is protected when visiting the UNFPA website.</p>

                        <p>We use cookies for the following reasons:</p>

                        <p><b>1. To improve your experience on our website –</b> Cookies enable us to ensure that we are providing you content that is relevant to your interests while deprioritising content that is not relevant to you. For example, cookies can tell us if you have shown interest in learning about a specific emergency or campaign, so we could ensure that the next time you visit the UNFPA website, information about this emergency or campaign is prioritised over other content. In the same way, cookies can tell us if, for instance, you have filled in a UNFPA form or signed a petition, so that we don’t present this to you the next time you visit our website. Cookies can also provide us with an understanding about other factors relating to your visit that can help us to enhance your website experience, such as: which part of the world you are visiting from, if you came to the site because of an email or social media post we sent you, or whether your website browser supports the different technologies used on the UNFPA website.</p>
                        <p><b>2. To understand how UNFPA website and other communication efforts are performing –</b>  Cookies help to provide us with anonymous statistics that tell us about how many users visit our website, where they came from and how they interact with it. This information is very useful for us to be able to see which pages and content on the UNFPA website is most popular so that we can modify it accordingly. Data gathered through cookies can also help us understand how our other activities, such as social media/ network content and emails affect the use of the UNFPA website.</p>
                        <p><b>3. To improve the relevance of UNFPA campaign messages displayed to you after you leave our website –</b>  Cookies can help us to ensure that you receive personalised advertising banners from advertising services when you browse other websites, based on the content you interacted with on the UNFPA website. For example, cookies can tell us if you visit a web page about a particular humanitarian emergency, so that campaign messages about this particular emergency can be displayed to you when you visit another website. None of UNFPA commercial partners providing such services collect or use any personally identifiable information.</p>
                        <p><b>4. Advertising performance -</b>  We want to monitor our advertising spend carefully to make sure that we are being as cost-effective as possible. UNFPA uses cookies provided by online measurement platforms such as Google Analytics and DoubleClick in order to identify how many people come to our website from different websites that host our media campaigns, which campaign messages have been most effective at driving people to the UNFPA website, and what actions people undertake after clicking on one of our ads.</p>
                        <p><b>5. Reach you on social channels and identify more supporters who want to help women and girls around the
                            world -</b>  Through cookies and email address, we can target messages for you on our social media platforms and identify other people on the Internet who are most likely to support UNFPA work and support women and girls around the world. This may include uploading an encrypted list of email addresses to social media services. This data is not stored or reused by the provider and is only held for the duration of the matching process.</p>

                        <p> <b>Third party cookies</b><br />
                            Some of the cookies you may receive when visiting UNFPA website are not related to UNFPA. For example, if you visit sections of the UNFPA website that contain embedded content, or contain a plugin such as a social media “like button”, you may receive cookies delivered from these websites. As detailed above, we also use third-party cookies for targeted advertising services and for tracking our website awareness-raising and communications performance.</p>

                        UNFPA does not govern the settings or use of these third-party cookies, so we suggest that you visit these websites for more information about their cookies and applicable privacy policies.

                        UNFPA is not responsible for the privacy of data collected by websites or social services/ networks that it does not own or manage, even if these link to the UNFPA website.  Modern web browsers provide the ability to disable all cookies or only third-party cookies.
                    </div>
                    <div className="h13">

                        <p> <b>Security</b><br />
                            We do not sell or share any personally identifiable information volunteered on the UNFPA websites to any third party without prior consent. We do, however, share some user information with our partners and local country offices so that the whole UNFPA family can respond to your needs. Any information provided to UNFPA by users of the UNFPA website is held with the utmost care and security, and will not be used in ways other than as set forth in this privacy policy, or in any site-specific policies, or in ways to which you have explicitly consented. UNFPA employs a range of techniques and security measures to protect the information maintained on our system from loss, misuse, unauthorized access or disclosure, alteration, or destruction.</p>

                        However, UNFPA assumes no responsibility for the security of information.

                        Links to other sites and uploading Content to Social Networks– Disclaimer

                        UNFPA websites may contain links to other sites external to the UNFPA domain. UNFPA assumes no responsibility for the privacy practices, terms of use or the content of such sites.

                        If you upload content to a social network and then tag UNFPA, your submission will be subject to that website’s terms of use and privacy policy. UNFPA assumes no responsibility for the terms of use, privacy practices or the content of such social networks and websites.

                    </div>
                    <div className="h13">
                        <p> <b>Notification of change of privacy policy</b><br />
                            If UNFPA decides to change its privacy policy, UNFPA will post those changes to this page so that you are always aware of what information we collect and how we use it.</p>
                    </div>
                    <div className="h13">

                        <p> <b>Your Consent</b><br />
                            By visiting our website you consent to our collection and use of your personal and other information as described in this Privacy Policy.</p>
                    </div>
                    <div className="h13">

                        <p> <b>Contact us</b><br />
                            If you have any questions or concerns about this UNFPA privacy policy, please use the information at the following URL to contact us: https://www.unfpa.org/contact and select “website inquiries.”</p>
                    </div>

                </div >
                <Footer />
            </div >
        )
    }
}
export default PrivacyView;