import { createStore } from 'redux'
//import thunk from 'redux-thunk'
import allReducers from '../reducers/index'

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
//const store = createStore(applyMiddleware(thunk));
//export default store(allReducers);

export default store
