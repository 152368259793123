//eslint-disable-next-line 
import React, { Component } from 'react';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import Button from '../../../../components/Button/Button';
import './HomeView.scss'
import '../../../../index.scss';
import home1 from '../../../../assets/images/home1.png'
import home3 from '../../../../assets/images/home3.png'
import home4 from '../../../../assets/images/home4.png'
import home5 from '../../../../assets/images/home5.png'
import home7 from '../../../../assets/images/home7.png'
import home8 from '../../../../assets/images/home8.png'

import HomeProgramSlider from "./HomeProgramSlider";

class HomeView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="homeTop">
                <Header />
                <div className="page-container">
                    <div className="home-section1">
                        <div className="col1"><img src={home1} alt="Smiling girl" width="100%" height="auto"/></div>
                        <div className="h3 home-section1-txt left-txt col2"><div><b>{this.props.homeSection1Title}</b>{this.props.homeSection1Txt}
                        </div>  <Button className="btn-style1 h5" buttonText="Get Started Now " onClick={() => this.props.goLogin()} /></div>
                    </div>
                </div>
                <div className="sushi-bg center-txt home-section2">
                    <div className="h1 white-txt ">{this.props.homeSection2Title}</div>
                </div>
                <div className="row mineShaft-bg home-section3 ">
                    <div className="left-txt col1">
                        <div className="treePoppy-txt h2 buffer-bottom1">{this.props.homeSection3Title}</div>
                        <div className="h11 white-txt">
                            {this.props.homeSection3Txt}
                        </div>
                    </div>
                    <div className="col2">
                        {/* <div className="home-section3-slide">
                            <div className="row img-row">
                                <img src={HomeSlideP5Inactive} alt="medicines" className="slide-img" />
                                <img src={HomeSlideP6Active} alt="medicines" className="slide-img active" />
                                <img src={HomeSlideP7Inactive} alt="medicines" className="slide-img" />
                            </div>
                            <div className="row txt-row">
                                <div className="row btn-view left">
                                    <button>
                                        <img src={HomeSlideBtnLeft} alt="Home slide button left" />
                                    </button>
                                </div>
                                <div className="text-area">
                                    <div className="h4 treePoppy-txt buffer-bottom1">{this.props.programsHeader1}</div>
                                    <div className="h11 satinLinen-txt ">{this.props.programsShortDescription1}</div>
                                </div>
                                <div className="row btn-view right">
                                    <button>
                                        <img src={HomeSlideBtnRight} alt="Home slide button Right" />
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        <HomeProgramSlider />
                    </div>
                </div>
                <div className="gallery-bg">
                    <div className="home-section4  ">
                        <div className="home-section4-row ">
                            <div className="left-txt col1">
                                <div className="h2 buffer-bottom2">{this.props.homeSection4Title1}</div>
                                <div className="h11 buffer-bottom3">{this.props.homeSection4Txt1}</div>
                                <Button className="home-section4-btn h5" buttonText="Read ROI-T GUIDE" onClick={() => this.props.redirect("/roi-tGuide")} />
                            </div>
                            <div className="col2"><img width="100%" height="auto" loading="lazy" src={home3} alt="Projections" /></div>
                        </div>
                        <div className="home-section4-row ">
                            <div className="left-txt col1">
                                <div className="h2 buffer-bottom2">{this.props.homeSection4Title2}</div>
                                <div className="h11 buffer-bottom3">{this.props.homeSection4Txt2}</div>
                                <Button className="home-section4-btn h5" buttonText="About The Programmes" onClick={() => this.props.redirect("/programs")} />
                            </div>
                            <div className="col2"><img width="100%" height="auto" loading="lazy" src={home4} alt="Curated List" /></div>
                        </div>

                        <div className="home-section4-row ">
                            <div className="left-txt col1">
                                <div className="h2 buffer-bottom2">{this.props.homeSection4Title3}</div>
                                <div className="h11 buffer-bottom3">{this.props.homeSection4Txt3}</div>
                                <Button className="home-section4-btn2 h5" buttonText="Get Started Now" onClick={() => this.props.goLogin()} />
                            </div>
                            <div className="col2"><img width="100%" height="auto" loading="lazy" src={home5} alt="Goal Setting" /></div>
                        </div>

                    </div>
                </div>
                <div className="treePoppy-bg  spaceBetween  home-section5">
                    <div className=" left-txt col1 ">
                        <div className="home-section5-col1">
                            <div className="h2 buffer-bottom2">{this.props.homeSection5Title1}</div>
                            <div className="h11 buffer-bottom3">{this.props.homeSection5Txt1} </div>
                            <Button className="home-section5-btn h5" buttonText="About Us" onClick={() => this.props.redirect("/about")} />
                        </div>
                    </div>
                    <div className="col2">
                        {/* <img src={home6} alt="" /> */}
                        <iframe className="video-frame " src="https://www.youtube.com/embed/ceiFRSxAd3I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
                <div className="home-section6">
                    <div className="h2 left-txt header">{this.props.homeSection6Title1}</div>
                    <div className="home-section6-row ">
                        <button className="home-section6-col img-btn" onClick={() => this.props.redirect("/caseStudies")}>
                            <div className="buffer-bottom1">
                                <img loading="lazy" src={home8} alt="home8" width="100%" height="auto" /></div>
                            <div className="caseStudiesCol left-txt">
                                <div className="h11 treePoppy-txt buffer-bottom">{this.props.caseStudiesHeader1}</div>
                                <div className="h11">
                                    {this.props.caseStudiesShortDescription1}
                                </div>
                            </div>
                        </button>
                        <button className="home-section6-col img-btn" onClick={() => this.props.redirect("/caseStudies#caseStudies2")}>
                            <div className="buffer-bottom1">
                                <img loading="lazy" src={home7} alt="home7" width="100%" height="auto" /></div>
                            <div className="caseStudiesCol left-txt">
                                <div className="h11 treePoppy-txt buffer-bottom">{this.props.caseStudiesHeader2}</div>
                                <div className="h11">
                                    {this.props.caseStudiesShortDescription2}
                                </div>
                            </div>
                        </button>
                    </div>
                    {/* <div className="right-txt "><Button className=" home-section6-btn h5" buttonText="Read More" />
                    </div> */}
                </div>
                <div className="gallery-bg home-section7">
                    {/* <div className="col1"><img src={home10} alt="" className="home-section7-img" /></div> */}
                    {/* <div className="col2 left-txt"> */}
                    <div className=" left-txt">
                        <div className="h9">{this.props.homeSection7Txt}

                        </div><br />
                        <div className="h9">{this.props.homeSection9Txt}

                        </div><br />
                        <div className="h9">{this.props.homeSection10Txt}

                        </div>
                        <br /><br /><br />
                        <div className="h11"> {this.props.homeSection7Person}</div>
                    </div>

                </div>
                <div className="home-section8 spaceBetween">
                    <div className="h3 left-txt col1">{this.props.homeSection8Txt}</div>
                    <div className="col2"><Button className="home-section8-btn h4" buttonText="Get Started Now" onClick={() => this.props.goLogin()} /></div>
                </div>
                <Footer />

            </div>
        )
    }
}
export default HomeView;