//eslint-disable-next-line 
import React, { Component } from 'react';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import { TextInput } from '../../../../components/MaterialInput';
import './ContactView.scss'

class ContactView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: "",
            contactEmail: "",
            contactOrganisation: "",
            contactReason: "",
            showContactPopup: false
        };
        this.clearForm = this.clearForm.bind(this);
    }
    submitForm() {

        var data = JSON.stringify({
            "contactEmail": this.state.contactEmail,
            "contactName": this.state.contactName,
            "contactOrganisation": this.state.contactOrganisation,
            "contactReason": this.state.contactReason,
        });

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        let that = this;
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                that.setState({
                    showContactPopup: true,
                    contactName: "",
                    contactEmail: "",
                    contactOrganisation: "",
                    contactReason: "",
                });
            }
        });

        xhr.open("POST", window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/api/auth/contactForm");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.send(data);


    }
    clearForm() {
        this.setState({
            contactName: "",
            contactEmail: "",
            contactOrganisation: "",
            contactReason: "",
            showContactPopup: false
        });
    }
    render() {
        return (
            <div>
                <Header />
                {this.state.showContactPopup && <div className="popupCard">
                    <div className="contact-popUp popup left-txt">
                        <div className="h9 buffer-bottom">Submitted!</div>
                        <div className="h11 buffer-bottom2">Thank you! We have received your message and will get back to you at the earliest.</div>
                        <div className="right-txt"><button className="ok-btn white-txt h6" onClick={() => this.setState({ showContactPopup: false })}>Ok</button></div>
                    </div>
                </div>}
                <div className="left-txt contact-container">
                    <div className="h2 contact-header ">Contact</div>
                    <div className="h13 contact-txt">
                        Please leave us a message and we will get back to you at the earliest.
                    </div>
                    <div style={{ width: "31rem" }}>

                        <TextInput
                            type='text'
                            label='Name'
                            value={this.state.contactName}
                            onChange={event => {
                                this.setState({ contactName: event.target.value })
                            }}
                        />
                        <TextInput
                            type='text'
                            label='Email ID'
                            value={this.state.contactEmail}
                            onChange={event => {
                                this.setState({ contactEmail: event.target.value })
                            }}
                        />
                        <TextInput
                            type='text'
                            label='Organisation'
                            value={this.state.contactOrganisation}
                            onChange={event => {
                                this.setState({ contactOrganisation: event.target.value })
                            }}
                        />
                        <TextInput
                            className="buffer-bottom2 contact-reason "
                            type='text'
                            label='Reason for reaching out'
                            value={this.state.contactReason}
                            onChange={event => {
                                this.setState({ contactReason: event.target.value })
                            }}
                        />
                        <div className="center-txt"> <button onClick={() => this.submitForm()}
                            className="h5 contact-btn white-txt">Submit</button></div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default ContactView;