//eslint-disable-next-line
import React, { Component, useState } from 'react'
import Header from '../../../../components/Header/Header'
import './ProgrammesView.scss'
import '../../../../indexroi.scss'
import programmesBar from '../../../../assets/images/roi/programmesBar.svg'
import proceedInactive from '../../../../assets/images/roi/proceedInactive.svg'
import proceedActive from '../../../../assets/images/roi/proceedActive.svg'
import sanitary from '../../../../assets/images/roi/sanitary.svg'
import srh from '../../../../assets/images/roi/srh.svg'
import peer from '../../../../assets/images/roi/peer.svg'
import tablets from '../../../../assets/images/roi/tablets.svg'
import subsidize from '../../../../assets/images/roi/subsidize.svg'
import clinic from '../../../../assets/images/roi/clinic.svg'
import lifeSkill from '../../../../assets/images/roi/lifeSkill.svg'
import checkbox from '../../../../assets/images/roi/checkbox.svg'
import checkboxSelected from '../../../../assets/images/roi/checkboxSelected.svg'
import ok from '../../../../assets/images/roi/ok.svg'
import back from '../../../../assets/images/back.svg'
import yes from '../../../../assets/images/roi/yes.svg'
import cancel from '../../../../assets/images/roi/cancel.svg'

class ProgrammesView extends Component {
  constructor (props) {
    super(props)
    let local = JSON.parse(localStorage.getItem('udgbl'))
    let projectionName =
      local.roiData[localStorage.getItem('currentProg')].projectionName
    let forWho = local.roiData[localStorage.getItem('currentProg')].forWho
    let bundleFor = localStorage.getItem('bundleFor')
    let from =
      localStorage.getItem('from') === 'back'
        ? 'projections'
        : localStorage.getItem('from')
    let selPrograms = localStorage.getItem('currentSelectedProg').split(',')
    selPrograms.forEach((item, i) => {
      selPrograms[i] = item === 'false' ? false : true
    })
    let selProgramsAdd =
      from === 'add' && localStorage.getItem('currentSelectedProgFromAdd')
        ? localStorage.getItem('currentSelectedProgFromAdd').split(',')
        : null

    if (selProgramsAdd) {
      selProgramsAdd.forEach((item, i) => {
        selProgramsAdd[i] = item === 'false' ? false : true
      })
    }

    let bundleList = localStorage.getItem('bundleList')
      ? localStorage.getItem('bundleList').split(',')
      : []
    let programmes = JSON.parse(localStorage.getItem('programmes'))
    let step = 'Step 1'
    let header = 'Please select programmes of interest'
    let description =
      'Click on the cards to select or deselect one or more programmes. You can add more programmes anytime. Some of these can be implemented together as a bundle.'
    if (from === 'add') {
      if (!localStorage.getItem('currentSelectedProgFromAdd')) {
        localStorage.setItem('currentSelectedProgFromAdd', selPrograms)
      }
      step = 'ADD PROGRAMME'
      description =
        'Click on the cards to select or deselect one or more programmes. You can add more programmes anytime. Some of these can be implemented together as a bundle.'
    }
    if (from === 'bundle') {
      step = 'BUNDLE PROGRAM'
      let progName = ''
      programmes.forEach(item => {
        if (item.programmeDetails.programNo === parseInt(bundleFor, 10)) {
          progName = item.programmeDetails.name
        }
      })
      header =
        'You can bundle upto ' +
        bundleList.length +
        ' programmes with ‘' +
        progName +
        '’'
      if (bundleFor === '0' || bundleFor === '4') {
        description =
          'Only one of the three training programmes can be added to the bundle.'
      } else {
        description = ''
      }
    }

    let selProgramsRender = localStorage.getItem('currentSelectedProgAdd')
      ? localStorage.getItem('currentSelectedProgAdd').split(',')
      : null
    if (selProgramsRender) {
      selProgramsRender.forEach((item, i) => {
        selProgramsRender[i] = item === 'false' ? false : true
      })
    }

    let selProgramsRenderBundle = localStorage.getItem(
      'currentSelectedProgBundle'
    )
      ? localStorage.getItem('currentSelectedProgBundle').split(',')
      : null
    if (selProgramsRenderBundle) {
      selProgramsRenderBundle.forEach((item, i) => {
        selProgramsRenderBundle[i] = item === 'false' ? false : true
      })
    }

    this.state = {
      isProceedActive: false,
      selected: selPrograms,
      bundleFor: bundleFor,
      showDuplicate: false,
      addNo: 0,
      selForRenderDisp:
        from === 'add' && selProgramsAdd
          ? JSON.parse(JSON.stringify(selProgramsAdd))
          : JSON.parse(JSON.stringify(selPrograms)),
      selForRender:
        from === 'add' && selProgramsRender
          ? selProgramsRender
          : [false, false, false, false, false, false, false],
      selForRenderBundle:
        from === 'bundle' && selProgramsRenderBundle
          ? selProgramsRenderBundle
          : [false, false, false, false, false, false, false],
      userId: local.id,
      local: local,
      step: step,
      header: header,
      description: description,
      programmes: programmes,
      from: from,
      bundleList: bundleList,
      projectionName: projectionName,
      forWho: forWho,
      confirm: false
    }
  }

  componentDidMount = () => {
    this.state.from === 'add'
      ? this.setState({ isProceedActive: false })
      : this.state.from === 'bundle'
      ? this.checkProceedActiveBundle()
      : this.checkProceedActive()
  }

  selectedAddProgram = id => {
    let programs = this.state.selForRender
    programs[id] = !programs[id]
    let addNo  = programs[id] ? parseInt(this.state.addNo) + 1: parseInt(this.state.addNo) -1;
    this.setState({ selForRender: programs, addNo: addNo })
    document.querySelector('#program' + id).classList.toggle('treePoppy-border')
    this.setState({ isProceedActive: addNo === 0 ? false: true   })
  }

  selectedProgram = id => {
    let programs = this.state.selected
    programs[id] = !programs[id]
    this.setState({ selected: programs })
    document.querySelector('#program' + id).classList.toggle('treePoppy-border')
    this.checkProceedActive()
  }

  selectedBundleProgram = id => {
    let programs = this.state.selForRenderBundle
    programs[id] = !programs[id]
    this.setState({ selForRenderBundle: programs })
    document.querySelector('#program' + id).classList.toggle('treePoppy-border')
    this.checkProceedActiveBundle()
  }

  selectedBundleSingleProgram = (disableIds, id) => {
    let programs = this.state.selForRenderBundle
    disableIds.forEach(it => {
      programs[it] = false
    })
    programs[id] = !programs[id]
    this.setState({ selForRenderBundle: programs })
    document.querySelector('#program' + id).classList.toggle('treePoppy-border')
    this.checkProceedActiveBundle()
  }

  checkProceedActiveBundle = () => {
    let isProceedActive = false
    this.state.selForRenderBundle.forEach(item => {
      if (item) {
        isProceedActive = true
      }
    })
    this.setState({ isProceedActive: isProceedActive })
  }


  checkProceedActive = () => {
    let isProceedActive = false
    this.state.selected.forEach(item => {
      if (item) {
        isProceedActive = true
      }
    })
    this.setState({ isProceedActive: isProceedActive })
  }

  saveSelectedProgram = () => {
    let newRoiData = this.state.local
    let that = this
    newRoiData.roiData[
      localStorage.getItem('currentProg')
    ].selPrograms = this.state.selected.toString()
    var data = JSON.stringify({
      roiData: newRoiData.roiData
    })
    localStorage.setItem('udgbl', JSON.stringify(newRoiData))
    localStorage.setItem('currentSelectedProg', this.state.selected.toString())

    if (that.state.from === 'bundle') {
      let bList = []
      bList.push(that.state.bundleFor)
      that.state.selForRenderBundle.forEach((item, i) => {
        if (item) {
          bList.push(i)
        }
      })
      let bundleList = bList.sort().toString()
      let roiCurrentProgram =
        newRoiData.roiData[localStorage.getItem('currentProg')]
      let bundleData = roiCurrentProgram.bundleDetails
        ? roiCurrentProgram.bundleDetails
        : []
      let duplicate = false
      bundleData.forEach(item => {
        if (item.programIds.sort().toString() === bundleList) {
          duplicate = true
        }
      })
      if (duplicate) {
        this.setState({ showDuplicate: true })
        return
      }
      localStorage.setItem('bundleList', bList)
      localStorage.setItem(
        'currentSelectedProgBundle',
        that.state.selForRenderBundle.toString()
      )
      window.location.href = '/input'
    } else {
      var xhr = new XMLHttpRequest()
      xhr.withCredentials = true

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          if (that.state.from === 'add') {
            localStorage.setItem(
              'currentSelectedProgAdd',
              that.state.selForRender.toString()
            )
            window.location.href = '/input'
          } else {
            window.location.href = '/company'
          }
        }
      })

      xhr.open(
        'POST',
        window.__RUNTIME_CONFIG__.REACT_APP_API_URL +
          '/api/user/updateROI/' +
          this.state.userId
      )
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.setRequestHeader('cache-control', 'no-cache')
      xhr.setRequestHeader(
        'postman-token',
        '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
      )

      xhr.send(data)
    }
  }

  render () {
    return (
      <div className='noover'>
        <Header />
        <div
          className={
            this.state.showDuplicate ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popup left-txt'>
            <div className='h9 padBot16'>Duplicate Bundle</div>
            <div className='h11'>
              Please select a different bundle combination.
              <br />
              <br />
            </div>
            <div className='width100 flex justifyContentFlexEnd'>
              <img
                alt='ok'
                className='pointer height40px padLeft24'
                onClick={() => {
                  this.setState({ showDuplicate: false })
                }}
                src={ok}
              />
            </div>
          </div>
        </div>
        <div
          className={this.state.confirm ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popupConfirm left-txt'>
            <div className='h9 padBot16'>Please confirm selection</div>
            <div className='h11'>
              Once a programme is selected, you won't be able to edit this
              choice to perform your projection. Do you wish to proceed?
              <br />
              <br />
            </div>
            <div className='flex justifyContentFlexEnd'>
              <img
                alt='ok'
                className='pointer height40px padLeft24'
                onClick={() => {
                  this.setState({ confirm: false })
                }}
                src={cancel}
              />

              <img
                alt='ok'
                className='pointer height40px padLeft24'
                onClick={() => {
                  this.setState({ confirm: false })
                  this.saveSelectedProgram()
                }}
                src={yes}
              />
            </div>
          </div>
        </div>
        <div className='blackWhite-bg height100'>
          <div className='bar flex justifyContentSpaceBetween alignItemsCenter'>
            <div className='width60'>
              <img
                alt='programme bar'
                className='width100'
                src={programmesBar}
              />
            </div>
            <div className='flex alignItemsCenter'>
              <div
                onClick={() => {
                  this.state.from === 'add' || this.state.from === 'bundle'
                    ? (window.location.href = '/roi')
                    : (window.location.href = '/projections')
                }}
              >
                <img
                  alt='back'
                  className='pointer height32 margRight8 margBot4'
                  src={back}
                />
              </div>
              {this.state.isProceedActive ? (
                <img
                  alt='proceed'
                  className='pointer height32'
                  onClick={() => {
                    this.setState({ confirm: true })
                  }}
                  src={proceedActive}
                />
              ) : (
                <img
                  className='height32'
                  src={proceedInactive}
                  alt='Inactive button'
                />
              )}
            </div>
          </div>
          <div className='flex padTop24'>
            <div className='bar width20 padTop48'>
              <div className='padBot3rem'>
                <div className='h7 padRight24 left-txt nowrap'>
                  {this.state.step}
                </div>
                <br />
                <div className='h4 left-txt'>{this.state.header}</div>

                <div className='h7 left-txt'>
                  {this.state.description}
                  <br />
                  <br />
                  For a detailed understanding of the programmes, please visit
                  the{' '}
                  <span
                    onClick={() => {
                      window.open('/programmes', '_blank')
                    }}
                    className='h12 treePoppy-txt pointer'
                  >
                    Programmes Page
                  </span>
                </div>
              </div>
              <div className='left-txt'>
                <div className='h6'>{this.state.projectionName}</div>
                <div className='h7'>{this.state.forWho}</div>
              </div>
            </div>
            <div className='rightSection flex'>
              {this.state.from === 'add' &&
                this.state.programmes.map((item, i) => {
                  if (
                    !this.state.selForRenderDisp[
                      item.programmeDetails.programNo
                    ] &&
                    !item.programmeDetails.disable
                  ) {
                    return (
                      <div
                        key={'key' + i}
                        className={
                          this.state.selected[item.programmeDetails.programNo]
                            ? 'programCard treePoppy-border removeTransform'
                            : 'programCard removeTransform'
                        }
                        id={'program' + item.programmeDetails.programNo}
                        onClick={() => {
                          this.selectedProgram(item.programmeDetails.programNo)
                          this.selectedAddProgram(
                            item.programmeDetails.programNo
                          )
                        }}
                      >
                        <div className='programHeader silver-chalice'>
                          <img
                            className='check'
                            alt='checkBox'
                            src={
                              this.state.selected[
                                item.programmeDetails.programNo
                              ]
                                ? checkboxSelected
                                : checkbox
                            }
                          />
                          {item.programmeDetails.programNo === 0 ? (
                            <img
                              className='width50'
                              alt='sanitary'
                              src={sanitary}
                            />
                          ) : item.programmeDetails.programNo === 1 ? (
                            <img alt='srh' className='width50' src={srh} />
                          ) : item.programmeDetails.programNo === 2 ? (
                            <img className='width50' alt='peer' src={peer} />
                          ) : item.programmeDetails.programNo === 3 ? (
                            <img
                              className='width50'
                              alt='subsidize'
                              src={subsidize}
                            />
                          ) : item.programmeDetails.programNo === 4 ? (
                            <img
                              className='width50'
                              alt='clinic'
                              src={clinic}
                            />
                          ) : item.programmeDetails.programNo === 5 ? (
                            <img
                              className='width50'
                              alt='tablet'
                              src={tablets}
                            />
                          ) : item.programmeDetails.programNo === 6 ? (
                            <img
                              alt='life skill'
                              className='width50'
                              src={lifeSkill}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='left-txt pad24'>
                          <div className='h6 margBot8 pacificBlue-txt'>
                            {item.programmeDetails.name}
                          </div>
                          <div className='targetClass'>
                            <div className='h14 margBot8'>
                              CATEGORY{' '}
                              <span className='h12'>
                                {item.programmeDetails.category}
                              </span>
                            </div>
                            <div className='h13'>
                              TARGET{' '}
                              <span className='h12'>
                                {item.programmeDetails.target}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className='h13 matterhorn-txt'>
                            {item.programmeDetails.description}
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return <div key={'key' + i}></div>
                  }
                })}
              {this.state.from === 'bundle' &&
                this.state.programmes.map((item, i) => {
                  if (
                    item &&
                    !item.programmeDetails.disable &&
                    ((this.state.bundleFor === '0' &&
                      !(
                        item.programmeDetails.programNo === 1 ||
                        item.programmeDetails.programNo === 2 ||
                        item.programmeDetails.programNo === 6 ||
                        item.programmeDetails.programNo === 5
                      )) ||
                      (this.state.bundleFor === '4' &&
                        !(
                          item.programmeDetails.programNo === 1 ||
                          item.programmeDetails.programNo === 2 ||
                          item.programmeDetails.programNo === 6
                        )) ||
                      this.state.bundleFor === '1' ||
                      this.state.bundleFor === '2' ||
                      this.state.bundleFor === '3' ||
                      this.state.bundleFor === '5' ||
                      this.state.bundleFor === '6') &&
                    this.state.bundleList.includes(
                      item.programmeDetails.programNo.toString()
                    )
                  ) {
                    return (
                      <div
                        key={'key' + i}
                        className={
                          this.state.selForRenderBundle[
                            item.programmeDetails.programNo
                          ]
                            ? 'programCard treePoppy-border removeTransform'
                            : 'programCard removeTransform'
                        }
                        id={'program' + item.programmeDetails.programNo}
                        onClick={() => {
                          this.selectedBundleProgram(
                            item.programmeDetails.programNo
                          )
                        }}
                      >
                        <div className='programHeader silver-chalice'>
                          <img
                            className='check'
                            alt='checkBox'
                            src={
                              this.state.selForRenderBundle[
                                item.programmeDetails.programNo
                              ]
                                ? checkboxSelected
                                : checkbox
                            }
                          />
                          {item.programmeDetails.programNo === 0 ? (
                            <img
                              className='width50'
                              alt='sanitary'
                              src={sanitary}
                            />
                          ) : item.programmeDetails.programNo === 1 ? (
                            <img alt='arh' className='width50' src={srh} />
                          ) : item.programmeDetails.programNo === 2 ? (
                            <img className='width50' alt='peer' src={peer} />
                          ) : item.programmeDetails.programNo === 3 ? (
                            <img
                              className='width50'
                              alt='subsidize'
                              src={subsidize}
                            />
                          ) : item.programmeDetails.programNo === 4 ? (
                            <img
                              className='width50'
                              alt='clinic'
                              src={clinic}
                            />
                          ) : item.programmeDetails.programNo === 5 ? (
                            <img
                              className='width50'
                              alt='tablet'
                              src={tablets}
                            />
                          ) : item.programmeDetails.programNo === 6 ? (
                            <img
                              alt='life skill'
                              className='width50'
                              src={lifeSkill}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='left-txt pad24'>
                          <div className='h6 margBot8 pacificBlue-txt'>
                            {item.programmeDetails.name}
                          </div>
                          <div className='targetClass'>
                            <div className='h14 margBot8'>
                              CATEGORY{' '}
                              <span className='h12'>
                                {item.programmeDetails.category}
                              </span>
                            </div>
                            <div className='h13'>
                              TARGET{' '}
                              <span className='h12'>
                                {item.programmeDetails.target}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className='h13 matterhorn-txt'>
                            {item.programmeDetails.description}
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return <div key={'key' + i}></div>
                  }
                })}
              {this.state.from === 'bundle' &&
              (this.state.bundleFor === '0' || this.state.bundleFor === '4') ? (
                <div className='flex flexWrap'>
                  <div className='width100 h6 left-txt padLeft24 margBot16'>
                    And/or any ONE training programme from the three below
                  </div>
                  {this.state.from === 'bundle' &&
                    this.state.programmes.map((item, i) => {
                      if (
                        item &&
                        !item.programmeDetails.disable &&
                        ((this.state.bundleFor === '0' &&
                          (item.programmeDetails.programNo === 1 ||
                            item.programmeDetails.programNo === 2 ||
                            item.programmeDetails.programNo === 6 ||
                            item.programmeDetails.programNo === 5)) ||
                          (this.state.bundleFor === '4' &&
                            (item.programmeDetails.programNo === 1 ||
                              item.programmeDetails.programNo === 2 ||
                              item.programmeDetails.programNo === 6))) &&
                        this.state.bundleList.includes(
                          item.programmeDetails.programNo.toString()
                        )
                      ) {
                        return (
                          <div
                            key={'key' + i}
                            className={
                              this.state.selForRenderBundle[
                                item.programmeDetails.programNo
                              ]
                                ? 'programCard treePoppy-border removeTransform'
                                : 'programCard removeTransform'
                            }
                            id={'program' + item.programmeDetails.programNo}
                            onClick={() => {
                              this.selectedBundleSingleProgram(
                                this.state.bundleFor === '0'
                                  ? [1, 2, 5, 6]
                                  : [1, 2, 6],
                                item.programmeDetails.programNo
                              )
                            }}
                          >
                            <div className='programHeader silver-chalice'>
                              <img
                                className='check'
                                alt='checkBox'
                                src={
                                  this.state.selected[
                                    item.programmeDetails.programNo
                                  ]
                                    ? checkboxSelected
                                    : checkbox
                                }
                              />
                              {item.programmeDetails.programNo === 0 ? (
                                <img
                                  className='width50'
                                  alt='sanitary'
                                  src={sanitary}
                                />
                              ) : item.programmeDetails.programNo === 1 ? (
                                <img alt='srh' className='width50' src={srh} />
                              ) : item.programmeDetails.programNo === 2 ? (
                                <img
                                  className='width50'
                                  alt='peer'
                                  src={peer}
                                />
                              ) : item.programmeDetails.programNo === 3 ? (
                                <img
                                  className='width50'
                                  alt='subsidize'
                                  src={subsidize}
                                />
                              ) : item.programmeDetails.programNo === 4 ? (
                                <img
                                  className='width50'
                                  alt='clinic'
                                  src={clinic}
                                />
                              ) : item.programmeDetails.programNo === 5 ? (
                                <img
                                  className='width50'
                                  alt='tablets'
                                  src={tablets}
                                />
                              ) : item.programmeDetails.programNo === 6 ? (
                                <img
                                  alt='lifeSkill'
                                  className='width50'
                                  src={lifeSkill}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='left-txt pad24'>
                              <div className='h6 margBot8 pacificBlue-txt'>
                                {item.programmeDetails.name}
                              </div>
                              <div className='targetClass'>
                                <div className='h14 margBot8'>
                                  CATEGORY{' '}
                                  <span className='h12'>
                                    {item.programmeDetails.category}
                                  </span>
                                </div>
                                <div className='h13'>
                                  TARGET{' '}
                                  <span className='h12'>
                                    {item.programmeDetails.target}
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <div className='h13 matterhorn-txt'>
                                {item.programmeDetails.description}
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return <div key={'key' + i}></div>
                      }
                    })}
                </div>
              ) : (
                ''
              )}

              {this.state.from === 'projections' &&
                this.state.programmes.map((item, i) => {
                  if (
                    !this.state.selForRenderDisp[
                      item.programmeDetails.programNo
                    ] &&
                    !item.programmeDetails.disable
                  ) {
                    return (
                      <div
                        key={'key' + i}
                        className={
                          this.state.selected[item.programmeDetails.programNo]
                            ? 'programCard treePoppy-border removeTransform'
                            : 'programCard removeTransform'
                        }
                        id={'program' + item.programmeDetails.programNo}
                        onClick={() => {
                          this.selectedProgram(item.programmeDetails.programNo)
                        }}
                      >
                        <div className='programHeader silver-chalice'>
                          <img
                            className='check'
                            alt='checkBox'
                            src={
                              this.state.selected[
                                item.programmeDetails.programNo
                              ]
                                ? checkboxSelected
                                : checkbox
                            }
                          />
                          {item.programmeDetails.programNo === 0 ? (
                            <img
                              className='width50'
                              alt='sanitary'
                              src={sanitary}
                            />
                          ) : item.programmeDetails.programNo === 1 ? (
                            <img alt='srh' className='width50' src={srh} />
                          ) : item.programmeDetails.programNo === 2 ? (
                            <img className='width50' alt='peer' src={peer} />
                          ) : item.programmeDetails.programNo === 3 ? (
                            <img
                              className='width50'
                              alt='subsidize'
                              src={subsidize}
                            />
                          ) : item.programmeDetails.programNo === 4 ? (
                            <img
                              className='width50'
                              alt='clinic'
                              src={clinic}
                            />
                          ) : item.programmeDetails.programNo === 5 ? (
                            <img
                              className='width50'
                              alt='tablets'
                              src={tablets}
                            />
                          ) : item.programmeDetails.programNo === 6 ? (
                            <img
                              alt='lifeSkill'
                              className='width50'
                              src={lifeSkill}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='left-txt pad24'>
                          <div className='h6 margBot8 pacificBlue-txt'>
                            {item.programmeDetails.name}
                          </div>
                          <div className='targetClass'>
                            <div className='h14 margBot8'>
                              CATEGORY{' '}
                              <span className='h12'>
                                {item.programmeDetails.category}
                              </span>
                            </div>
                            <div className='h13'>
                              TARGET{' '}
                              <span className='h12'>
                                {item.programmeDetails.target}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className='h13 matterhorn-txt'>
                            {item.programmeDetails.description}
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return <div key={'key' + i}></div>
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ProgrammesView
