//eslint-disable-next-line 
import React, { useEffect } from 'react';
import PageContent from '../../../../views/Admin/PageContent/PageContentView';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getcms } from '../../../../actions/cmsActions'

function PageContentController() {

    //const homeContent = useSelector(state => state.loadCMS);
    const homeContent = useSelector(state => {
        if (state.loadCMS.cms[0]) {
            return state.loadCMS.cms[0]["cmsJSON"];
        } else {
            return state.loadCMS;
        }
    });
    const dispatch = useDispatch();
    var data = null;
    useEffect(() => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                var status = xhr.status;
                if (status === 0 || (status >= 200 && status < 400)) {
                    if (xhr.responseText) {
                        dispatch(getcms(JSON.parse(xhr.responseText)));
                    }
                } else {
                    // Oh no! There has been an error with the request!
                }
            }
        };
        xhr.open("GET", window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/api/cms/allPosts");
        xhr.setRequestHeader("cache-control", "no-cache");
        xhr.setRequestHeader("Postman-Token", "e98f7569-06ee-4323-9bb5-a4dda08e560b");

        xhr.send(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
    );
    const redirect = (path) => {
        return this.props.history.push(path);
    }

    return (

        <PageContent
            {...homeContent}
            redirect={redirect}

        />
    )

}
export default connect()(PageContentController);