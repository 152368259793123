
const initialUserState = {
    cms: {}
}
const cmsReducer = (state = initialUserState, action) => { 
    switch (action.type) {
        case 'GET_CMS':
            return {
                ...state,
                cms: { ...state.cms, ...action.payload }
            }
        default:
            return state;
    }

}

export default cmsReducer;