import React, { useRef } from 'react'
import '@material/react-text-field/dist/text-field.css'
import TextField, { Input } from '@material/react-text-field'
import caret from '../../assets/images/caret.png'

export const TextInput = props => {
  const className = `input-container ${props.error ? 'md-ripple-error' : ''
    } ${props.className || ''}`
  return (
    <div className={className}>
      <TextField label={props.label} className='material-input'>
        <Input
          value={props.value}
          type={props.type}
          onChange={props.onChange}
        />
      </TextField>
      {Boolean(props.error) && (
        <div className='h12 md-error'>{props.error}</div>
      )}
    </div>
  )
}
TextInput.defaultProps = {
  value: '',
  label: 'label',
  type: 'text',
  error: ''
}

export const Select = (props) => {
  const selectRef = useRef();
  const onCaret = () => {
    if (selectRef && selectRef.current) {
    }
  }
  return (
    <div className={`gbl-select ${props.className}`} >
      <img src={caret} className='caret' alt='select-caret' onClick={onCaret} />
      <select ref={selectRef} value={props.value} onChange={props.onChange}>
        {props.children}
      </select>
    </div>
  )
}
export const Checkbox = (props) => {
  return (
    <>
      <input id={props.id} type='checkbox' className='md-check' />
      <label htmlFor={props.id} className='md-check-label'>
        <span></span>
      </label>
    </>
  )
}
export const RadioButton = (props) => {
  const { id, className, ...rest} = props
  return (
    <>
      <input id={props.id} type='radio' className={`md-radio ${className || ''}`} {...rest}/>
      <label htmlFor={props.id} className='md-radio-label'>
        <span></span>
      </label>
    </>
  )
}
