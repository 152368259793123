import React from 'react'
// import Slider from "react-slick";
import HomeSlideBtnLeft from '../../../../assets/images/home-slide/btn-arrow-left.png'
import HomeSlideBtnRight from '../../../../assets/images/home-slide/btn-arrow-right.png'

import HomeSlideP1Active from '../../../../assets/images/home-slide/program-1-active.png'
import HomeSlideP1Inactive from '../../../../assets/images/home-slide/program-1-inactive.png'
import HomeSlideP2Active from '../../../../assets/images/home-slide/program-2-active.png'
import HomeSlideP2Inactive from '../../../../assets/images/home-slide/program-2-inactive.png'
import HomeSlideP3Active from '../../../../assets/images/home-slide/program-3-active.png'
import HomeSlideP3Inactive from '../../../../assets/images/home-slide/program-3-inactive.png'
import HomeSlideP4Active from '../../../../assets/images/home-slide/program-4-active.png'
import HomeSlideP4Inactive from '../../../../assets/images/home-slide/program-4-inactive.png'
import HomeSlideP5Active from '../../../../assets/images/home-slide/program-5-active.png'
import HomeSlideP5Inactive from '../../../../assets/images/home-slide/program-5-inactive.png'
import HomeSlideP6Active from '../../../../assets/images/home-slide/program-6-active.png'
import HomeSlideP6Inactive from '../../../../assets/images/home-slide/program-6-inactive.png'
import HomeSlideP7Active from '../../../../assets/images/home-slide/program-7-active.png'
import HomeSlideP7Inactive from '../../../../assets/images/home-slide/program-7-inactive.png'

const Slides = [
  {
    title: 'Sanitary Pads',
    description:
      'Free Sanitary Pads to improve menstrual health and hygiene, reduce reproductive tract health issues and boost employees’ productivity',
    activeImg: HomeSlideP1Active,
    inActiveImage: HomeSlideP1Inactive
  },
  {
    title: 'SRH Training',
    description:
      'Company-based employees’ training on Sexual and Reproductive Health, to boost awareness and information on SRH issues and improve employees’ engagement',
    activeImg: HomeSlideP2Active,
    inActiveImage: HomeSlideP2Inactive
  },
  {
    title: 'Subsidised SRH Training and Products',
    description:
      'Mobile clinics regularly providing training, information and essential SRH services and products to employees in the factory/company',
    activeImg: HomeSlideP3Active,
    inActiveImage: HomeSlideP3Inactive
  },
  {
    title: 'Peer Outreach',
    description:
      'Training selected employees as Peer Educators, increasing awareness and information on sexual and reproductive health and services amongst their fellow co-workers and boosting employees engagement',
    activeImg: HomeSlideP4Active,
    inActiveImage: HomeSlideP4Inactive
  },
  {
    title: 'Set Up Factory Clinic',
    description:
      'Set up a factory-based health clinic, providing access to counseling and essential SRH service provision - from family planning information, to STIs management and to pre- and post-natal check ups',
    activeImg: HomeSlideP5Active,
    inActiveImage: HomeSlideP5Inactive
  },
  {
    title: 'Free Iron Tablets',
    description:
      'Testing women  employees for anaemia, a serious public health concern, and offering free iron tablets to address the condition and improve women’s health and productivity',
    activeImg: HomeSlideP6Active,
    inActiveImage: HomeSlideP6Inactive
  },
  {
    title: 'Life Skills Training',
    description:
      'Factory-based training on various issues, from health and well-being to safety and occupation health, to empower employees and improve their engagement at work',
    activeImg: HomeSlideP7Active,
    inActiveImage: HomeSlideP7Inactive
  }
]
function getTouches(evt) {
  const t =
    evt.touches || evt.originalEvent.touches // browser API // jQuery
  return t ? t[0] : null
}

class HomeSlideShow extends React.Component {
  constructor(props) {
    super(props)
    let slideNum = 0
    this.state = {
      slideNum: slideNum,
      itemClasses: Slides.map((_, index) => {
        switch (index) {
          case Slides.length - 2:
            return 'prev-next'
          case Slides.length - 1:
            return 'prev'
          case slideNum:
            return 'active'
          case slideNum + 1:
            return 'next'
          case slideNum + 2:
            return 'next-prev'
          default:
            return ''
        }
      })
    }
    this._onMove = false
    this._autoInterValId = null
    this._touchX = null
    this._touchY = null
  }
  componentDidMount() {
    this.clearTimeOut()
    this.createAutoSlide()
  }
  createAutoSlide = () => {
    // this._autoInterValId = setInterval(this.repeat, 2000)
  }
  repeat = () => {
    this.moveSlideNext()
  }
  clearTimeOut = () => {
    if (this._autoInterValId) {
      clearInterval(this._autoInterValId)
    }
    this._autoInterValId = null
  }
  onNext = () => {
    if (this._onMove) {
      return
    }
    this.clearTimeOut()
    this.moveSlideNext()
    this.createAutoSlide()
  }
  onPrev = () => {
    if (this._onMove) {
      return
    }
    this.clearTimeOut()
    this.moveSlidePrev()
    this.createAutoSlide()
  }
  moveSlideNext = () => {
    this._onMove = true
    const { slideNum } = this.state
    let slide = 0
    if (slideNum === Slides.length - 1) {
      slide = 0
    } else {
      slide = slideNum + 1
    }
    const cls = this.moveCarouselTo(slide)
    this.setState(
      {
        slideNum: slide,
        itemClasses: [...cls]
      },
      () => {
        this._onMove = false
      }
    )
  }
  moveSlidePrev = () => {
    const { slideNum } = this.state
    let slide = 0
    if (slideNum === 0) {
      slide = Slides.length - 1
    } else {
      slide = slideNum - 1
    }
    const cls = this.moveCarouselTo(slide)
    this.setState(
      {
        slideNum: slide,
        itemClasses: [...cls]
      },
      () => {
        this._onMove = false
      }
    )
  }
  moveCarouselTo = slide => {
    const { itemClasses } = this.state
    let itemCls = itemClasses.map(() => 'ani-2')
    let totalItems = Slides.length,
      newPrevious = slide - 1,
      newNext = slide + 1,
      oldPrevious = slide - 2,
      oldNext = slide + 2,
      nextPrev
    if (newPrevious <= 0) {
      oldPrevious = totalItems - 1
    } else if (newNext >= totalItems - 1) {
      oldNext = 0
    }
    if (slide === 0) {
      newPrevious = totalItems - 1
      oldPrevious = totalItems - 2
      oldNext = slide + 1
    } else if (slide === totalItems - 1) {
      newPrevious = slide - 1
      newNext = 0
      oldNext = 1
    }
    nextPrev = newNext === totalItems - 1 ? 0 : newNext + 1
    itemCls[oldPrevious] = 'ani prev-next'
    itemCls[oldNext] = ''

    itemCls[slide] = 'ani active'
    itemCls[newPrevious] = 'ani prev'
    itemCls[newNext] = 'ani next'
    itemCls[nextPrev] = 'ani next-prev'
    return itemCls
  }

  handleTouchStart = e => {
    const firstTouch = getTouches(e)
    if (!firstTouch) {
      return
    }
    this._touchX = firstTouch.clientX
    this._touchY = firstTouch.clientY
  }
  handleTouchMove = e => {
    const moveTouch = getTouches(e)
    if (!moveTouch || !this._touchX || !this._touchY) {
      return
    }
    let yUp = moveTouch.clientY
    let xUp = moveTouch.clientX

    var xDiff = this._touchX - xUp
    var yDiff = this._touchY - yUp
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        this.onNext()
      } else {
        this.onPrev()
      }
    }
    this._touchX = null
    this._touchY = null
  }
  handleTouchEnd = e => {
    this._touchX = null
    this._touchY = null
  }
  handleImgClick = e => {
    if (e.target.classList && e.target.classList.value) {
      if (e.target.classList.value.includes('next')) {
        this.onPrev()
      } else if (e.target.classList.value.includes('prev')) {
        this.onNext()
      }
    }
  }
  render() {
    const { slideNum, itemClasses } = this.state
    return (
      <div
        className='home-section3-slide'
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        <div className='row img-row'>
          {Slides.map((slide, index) => {
            const isCurrent = index === slideNum
            return (
              <img
                key={index}
                src={isCurrent ? slide.activeImg : slide.inActiveImage}
                alt='medicines'
                onClick={this.handleImgClick}
                className={`slide-img ${itemClasses[index]}`}
              />
            )
          })}
        </div>
        <div className='row txt-row'>
          <div className='row btn-view left'>
            <button onClick={this.onPrev}>
              <img src={HomeSlideBtnLeft} alt='Home slide button left' />
            </button>
          </div>
          <div className='carousel-wrap'>
            <div className='row text-area'>
              {Slides.map((slide, index) => {
                //const isCurrent = index === slideNum
                return (
                  <div key={index} className={`text-content ${itemClasses[index]}`}>
                    <div className='h4 treePoppy-txt buffer-bottom1'>
                      {slide.title}
                    </div>
                    <div className='h11 satinLinen-txt '>
                      {slide.description}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='row btn-view right'>
            <button onClick={this.onNext}>
              <img src={HomeSlideBtnRight} alt='Home slide button Right' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default HomeSlideShow
