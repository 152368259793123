//eslint-disable-next-line 
import React, { Component } from 'react';
import AboutView from '../../../../views/Users/Public/About/AboutView';
import { useSelector, connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';

function AboutController() {
    // const aboutContent = useSelector(state => state.loadCMS);
    let history = useHistory();
    let local = JSON.parse(localStorage.getItem('udgbl'));
    let showLogin = true;
    let accessToken = '';
    if (local) {
        accessToken = local.accessToken;
        showLogin = !(accessToken && accessToken !== "");

    }
    const aboutContent = useSelector(state => {
        if (state.loadCMS.cms[1]) {
            return state.loadCMS.cms[1]["cmsJSON"];
        } else {
            return state.loadCMS;
        }
    });
    useEffect(() => {

        document.getElementById("aboutTop").scrollIntoView();

    }, []);
    const redirect = (path) => {
        return history.push(path);
    }

    const goLogin = () => {
        if (showLogin) {
            return history.push("/login");

        } else {
            return history.push("/projections");
        }
    }
    return (
        <AboutView
            {...aboutContent}
            redirect={redirect}
            goLogin={goLogin} />
    );
}
export default connect()(AboutController);