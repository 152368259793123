//eslint-disable-next-line
import React, { Component } from 'react'
import {
  GoogleLoginProvider,
  LinkedInProvider
} from '../../../../components/AuthProvider'

import './LoginView.scss'
import { TextInput } from '../../../../components/MaterialInput'
import login from '../../../../assets/images/login/login.png'
import newUser from '../../../../assets/images/login/newUser.svg'
import existingUser from '../../../../assets/images/login/existingUser.svg'
import signup from '../../../../assets/images/login/signup.svg'
import loginBtn from '../../../../assets/images/login/loginBtn.svg'
import DevicePopUp from '../../../../components/PopUp/PopUp'
import closeBtn from '../../../../assets/images/close2.png'
import showPassword from '../../../../assets/images/showPassword.png'
import hidePassword from '../../../../assets/images/hidePassword.png'
import ReCAPTCHA from "react-google-recaptcha";

class LoginView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileScreen: false,
      showForgotPassword: false,
      sendEmailTo: '',
      emailSent: false,
      userDoesNotExist: false,
      updatedPassword: '',
      userId: '',
      apiUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
      showPassword: false,
      showExistingPassword: false
    }
  }
  checkUsernameExists() {
    var data = JSON.stringify({ email: this.state.sendEmailTo })
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    let that = this
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        if (this.status <= 299) {
          that.setState({
            emailSent: true,
            showForgotPassword: false,
            userDoesNotExist: false
          })
        } else {
          that.setState({
            emailSent: false,
            showForgotPassword: false,
            userDoesNotExist: true
          })
        }
      }
    })
    xhr.open(
      'GET',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/users/verifyUser/' +
      that.state.sendEmailTo
    )
    xhr.setRequestHeader(
      'x-access-token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxNGFiOTU4YTRjYjg4MDAxNWI2MjRkMCIsImlhdCI6MTYzMzQyMjYxMCwiZXhwIjoxNjMzNTA5MDEwfQ.W5usjOTlCT6WHN2dMBYCTy6c1McBOhcYFdCQNAemVzA'
    )
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(data)
  }

  render() {
    return (
      <div className='loginPage'>
        <DevicePopUp />

        <div
          className={
            this.state.showForgotPassword ? 'popupCard ' : 'popupCard dispNone'
          }
        >
          <div className='popup width30 forgotPassword'>
            <div className='flex spaceBetween'>
              <div className=' width70 h5 left-txt buffer-bottom'>
                Forgot Password
              </div>
              <button
                className='icon-btn pointer'
                onClick={() => this.setState({ showForgotPassword: false })}
              >
                <img src={closeBtn} alt='close the window' />
              </button>
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Dont worry! Just enter your registered email id and we will share
              a new password. You can change it from your profile anytime.
            </p>
            <TextInput
              type='text'
              label='Email'
              value={this.state.sendEmailTo}
              onChange={event => {
                this.setState({ sendEmailTo: event.target.value })
              }}
            />

            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns '
                disabled={this.state.sendEmailTo === ''}
                onClick={() => {
                  this.checkUsernameExists()
                }}
              >
                Generate password
              </button>
            </div>
          </div>
        </div>

        <div
          className={this.state.emailSent ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popup width30'>
            <div className='width100 h9 left-txt buffer-bottom'>
              Your new Password has been sent!
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Email sent to <b>{this.state.sendEmailTo}</b> !<br /> If you do
              not see the email in your inbox, please check your “junk mail”
              folder or “spam” folder.
            </p>
            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns'
                onClick={() => {
                  this.setState({ emailSent: false })
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.userDoesNotExist ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popup width30'>
            <div className='width100 h9 left-txt buffer-bottom'>
              Your username does not exist
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Please check if you entered the correct email address{' '}
              <b>{this.state.sendEmailTo}</b>
            </p>
            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns'
                onClick={() => {
                  this.setState({ userDoesNotExist: false })
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>

        <div
          className={this.props.showPopup ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popup width30'>
            <div className='width100 h9 left-txt'>
              {this.props.errorPopHeader}
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              {this.props.popupMsg}
            </p>
            <div className='row justifyContentFlexEnd'>
              {this.props.showForgotPassword && (
                <button className='h6 justifyContentFlexEnd pop-ups-btns'>
                  Forgot
                </button>
              )}
              {/* {this.props.weakPassword && <button className="h6 justifyContentFlexEnd pop-ups-btns" onClick={() => this.props.signupAnyway()
              }>Continue</button>} */}
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns'
                onClick={() => {
                  this.props.closePopup()
                }}
              >
                Re-enter
              </button>
            </div>
          </div>
        </div>
        <div
          className='leftLogin pointer'
          onClick={() => (window.location.href = '/')}
        >
          <img className='loginImg' src={login} alt='login' />
        </div>
        <div className='rightLogin'>
          <div
            className={
              'buffer-bottom ' +
              (this.props.existingUserLogin ? 'dispNone' : '')
            }
          >
            <img
              className='login-welcome'
              src={newUser}
              alt='Welcome'
              onClick={() => {
                this.props.checkExistingUserLogin()
              }}
            />
            <div className='h9 welcome'>Get Started</div>
            <TextInput
              label='Email ID'
              value={this.props.email}
              onChange={event => {
                this.props.setEmail(event)
              }}
            />
            <div className='relativeElement'>
              <TextInput
                label='Password'
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.props.password}
                onChange={event => {
                  this.props.setPassword(event)
                }}
              />
              <button
                className='pointer icon-btn showHidePassword'
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
              >
                <img
                  src={this.state.showPassword ? showPassword : hidePassword}
                  alt='Show Password'
                />
              </button>
            </div>
            {/* <div>Password must be atleast 8 characters long with atleast 1 digit, 1 uppercase aphabet, 1 lowercase alpahbet and 1 special charactaer</div> */}
            <TextInput
              label='Confirm Password'
              type='password'
              value={this.props.repeatPassword}
              onChange={event => {
                this.props.setRepeatPassword(event)
              }}
            />
            <br />
            <ReCAPTCHA
              sitekey="6LfqAsQfAAAAAKA600X3txvGgmaEIFuDh8n-ZVAY"

            />
            <br/>
            <img
              className='pointer width50 userLogin-btn'
              onClick={() => {
                this.props.signup()
              }}
              src={signup}
              alt='Sign up'
            />
            <br />
            <div className='h11 buffer-bottom'>or Sign up with</div>
            <div className='auth-provider flex '>
              <LinkedInProvider
                className='provider hideProvider'
                onSuccess={this.props.onAuthSignInSuccess}
                onError={this.props.onAuthSignInFailure}
              />
              <GoogleLoginProvider
                className='provider'
                onSuccess={this.props.onAuthSignUpSuccess}
                onError={this.props.onAuthSignUpFailure}
              />
              <LinkedInProvider
                className='provider hideProvider'
                onSuccess={this.props.onAuthSignUpSuccess}
                onError={this.props.onAuthSignUpFailure}
              />
            </div>
          </div>
          <div
            className={
              'buffer-bottom ' +
              (this.props.existingUserLogin ? '' : 'dispNone')
            }
          >
            <img
              className='login-welcome'
              alt='Welcome'
              src={existingUser}
              onClick={() => {
                this.props.openExistingUserLogin()
              }}
            />
            <div className='h9 welcome'>Welcome back!</div>
            <br />
            <TextInput
              className='login-floating-label'
              label='Email ID'
              value={this.props.existingEmail}
              onChange={event => {
                this.props.setExisitingEmail(event)
              }}
            />
            <div className="relativeElement"><TextInput
              className='buffer-bottom1'
              label='Password'
              type={this.state.showExistingPassword ? 'text' : 'password'}
              value={this.props.existingPassword}
              onChange={event => {
                this.props.setExisitingPassword(event)
              }}
            />
              <button className="pointer icon-btn showHidePassword" onClick={() => this.setState({ showExistingPassword: !this.state.showExistingPassword })}>
                <img src={this.state.showExistingPassword ? showPassword : hidePassword} alt="Show Password" />
              </button>
            </div>
            <img
              className='pointer width50 '
              onClick={() => {
                this.props.login()
              }}
              alt='Login button'
              src={loginBtn}
            />
            <br />
            <div
              onClick={() => this.setState({ showForgotPassword: true })}
              className='userLogin-btn treePoppy-txt h6 pointer'
            >
              Forgot Password?
            </div>
            <div className='h11 buffer-bottom'>or Login with</div>
            <div className='auth-provider flex '>
              <LinkedInProvider
                className='provider hideProvider'
                onSuccess={this.props.onAuthSignInSuccess}
                onError={this.props.onAuthSignInFailure}
              />
              <GoogleLoginProvider
                className='provider'
                onSuccess={this.props.onAuthSignInSuccess}
                onError={this.props.onAuthSignInFailure}
              />
              <LinkedInProvider
                className='provider hideProvider'
                onSuccess={this.props.onAuthSignInSuccess}
                onError={this.props.onAuthSignInFailure}
              />
            </div>
          </div>
          <div>
            <span className='h13'>
              By continuing you agree to our
              <br />
            </span>
            <span className='h13 '>Terms & conditions</span>
            <span className='h13'> and</span>
            <span
              className='h12 treePoppy-txt pointer'
              onClick={() => this.props.redirect('/privacy')}
            >
              {' '}
              Privacy Policy
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginView
