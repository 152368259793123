//eslint-disable-next-line 
import React, { Component } from 'react';
import Header from '../../../../components/Header/Header';
import Button from '../../../../components/Button/Button';
import '../../../../index.scss';
import './CaseStudiesView.scss';
import CaseStudies1 from '../../../../assets/images/home8.png'
import CaseStudies2 from '../../../../assets/images/CaseStudies2.png'
import CaseStudies3 from '../../../../assets/images/CaseStudies3.png'
import CaseStudies4 from '../../../../assets/images/CaseStudies4.jpeg'
import home7 from '../../../../assets/images/home7.png'
// import home8 from '../../../../assets/images/home8.png'
import leftArrow from '../../../../assets/images/leftArrow.png'
import rightArrow from '../../../../assets/images/rightArrow.png'
import Footer from '../../../../components/Footer/Footer';

class CaseStudiesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            caseStudies1: true,
            caseStudies2: false
        };
        this.caseStudiesBtns = this.caseStudiesBtns.bind(this);
    }
    componentDidMount() {
        const path = window.location.hash;
        if (path && path.includes("#")) {
            this.setState({ caseStudies2: true, caseStudies1: false })
        }
    }

    redirect(path) {
        window.location.href = path;
    }
    caseStudiesBtns(buttonType) {
        let { caseStudies1, caseStudies2 } = this.state;
        if (buttonType === "Next") {
            if (caseStudies1) {
                caseStudies1 = false;
                caseStudies2 = true;
                window.location.href = "/caseStudies#caseStudies2"
            }
        } else {
            if (caseStudies2) {
                caseStudies1 = true;
                caseStudies2 = false;
                window.location.href = "/caseStudies"
            }

        }
        this.setState({ caseStudies1, caseStudies2 })
    }
    render() {
        return (
            <div id="caseStudyTop">
                <Header />

                <div className="cs-section1">
                    <div className="h2">{this.props.CSTitle1}</div>
                </div>

                {this.state.caseStudies1 && <div>
                    <div className="cs-section2">
                        <div className="cs-section2-heading" ><div className="h2 treePoppy-txt">{this.props.CSTitle2}</div></div>
                        <div>
                            <div><figure>
                                <img src={CaseStudies1} alt="HR Assistant Phoebe Tamondong" />
                                <figcaption className="h13 left-txt emperor-bg white-txt">Phoebe Tamondong, 25, a human resources assistant at Daiwa Seiko Philippines Corp. believed that jumping after sex prevented pregnancy until she learned differently at the company’s family planning information session. © UNFPA Philippines/Mario Villamor</figcaption>
                            </figure>
                            </div>
                            <div className="h11 cs-story "><span><b>{this.props.CSSection1Title}</b></span><span>{this.props.CSPara1}</span>
                                <p>{this.props.CSPara2}</p>
                                <p>{this.props.CSPara3}</p>
                                <p>{this.props.CSPara4}</p>
                                <p>{this.props.CSPara5}</p>
                            </div>

                            <div ><figure>
                                <img src={CaseStudies2} alt="HR Assistant Phoebe Tamondong" />
                                <figcaption className="h13 left-txt emperor-bg white-txt">A UNFPA-trained doctor facilitates a family planning information session at Daiwa Seiko Philippines Corp.  © UNFPA Philippines</figcaption>
                            </figure>
                            </div>
                            <div className="h11 cs-story "><b>{this.props.CSSection2Title}</b><br />
                                <p>{this.props.CSPara6}</p>
                                <p>{this.props.CSPara7}</p>
                                <p>{this.props.CSPara8}</p>
                                <p>{this.props.CSPara9}</p>
                                <p>{this.props.CSPara10}</p>
                                <p>{this.props.CSPara11}</p>
                            </div>
                            <div>
                                <figure>
                                    <img src={CaseStudies3} alt="HR Assistant Phoebe Tamondong" />
                                    <figcaption className="h13 left-txt emperor-bg white-txt">A lively discussion of engaged employees at Daiwa Seiko Philippines Corp.  during a family planning information session. © UNFPA Philippines</figcaption>
                                </figure>
                            </div>
                            <div className="h11 cs-story "><b>{this.props.CSSection3Title}</b><br />
                                <p>{this.props.CSPara12}</p>
                                <p>{this.props.CSPara13}</p>
                                <p>{this.props.CSPara14}</p>
                                <p>{this.props.CSPara15}</p>
                                <p>{this.props.CSPara16}</p></div>


                        </div>
                    </div>
                </div>}

                {this.state.caseStudies2 && <div>
                    <div className="cs-section2">
                        <div className="cs-section2-heading" ><div className="h2 treePoppy-txt">{this.props.CSTitle4}</div></div>
                        <div>
                            <div><figure>
                                <img src={home7} alt="Garment factory workers" />
                                <figcaption className="h13 left-txt emperor-bg white-txt">Garment factory workers who attend Sexual and reproductive health training progamme conducted by Vientiane Youth Centre</figcaption>
                            </figure>
                            </div>
                            <div className="h11 cs-story ">
                                <p>{this.props.CSPara17}</p>
                                <p>{this.props.CSPara18}</p>
                                <p>{this.props.CSPara19}</p>
                                <p>{this.props.CSPara20}</p>
                            </div>

                            <div ><figure>
                                <img src={CaseStudies4} alt="A factory male employee" />
                                <figcaption className="h13 left-txt emperor-bg white-txt">A factory male employee wearing a shirt with the phrase "Every problem has its solution / Friends help friends"</figcaption>
                            </figure>
                            </div>
                            <div className="h11 cs-story ">
                                <p>{this.props.CSPara21}</p>
                                <p>{this.props.CSPara22}</p>
                                <p>{this.props.CSPara23}</p>
                                <p>{this.props.CSPara24}</p>
                                <p>{this.props.CSPara25}</p>
                                <p>{this.props.CSPara26}</p>

                                {/* <div>
                                <figure>
                                    <img src={CaseStudies3} alt="HR Assistant Phoebe Tamondong" />
                                    <figcaption className="h13 left-txt emperor-bg white-txt">A lively discussion of engaged employees at Daiwa Seiko Philippines Corp.  during a family planning information session. © UNFPA Philippines</figcaption>
                                </figure>
                            </div> */}

                                <p>{this.props.CSPara27}</p></div>


                        </div>
                    </div>
                </div>}


                <div className="cs-section2">
                    <hr className="cs-hr "></hr>
                    <div className="cs-section3-nav">
                        <button className="cs-section3-btn treePoppy-txt h5" onClick={() => this.caseStudiesBtns("Previous")}><span><img src={leftArrow} alt="Left arrow" /></span><span style={{ paddingLeft: "1.25rem" }} className="treePoppy-txt " >PREVIOUS</span></button>
                        {this.state.caseStudies1 && <button className="cs-section3-btn h5" onClick={() => this.caseStudiesBtns("Next")}><span className="treePoppy-txt " style={{ paddingRight: "1.25rem" }}>NEXT</span><span><img src={rightArrow} alt="Right arrow" /></span></button>}
                    </div>
                </div>
                {/* <div className="cs-section3 gallery-bg">
                    <div className="cs-section3-row ">
                        <div className="cs-section3-col">
                            <div className="buffer-bottom1">
                                <img src={home7} alt="" /></div>
                            <div className="cs3-Col left-txt">
                                <div className="h11 treePoppy-txt buffer-bottom">{this.props.CaseStudy1Title}</div>
                                <div className="h11">
                                    {this.props.CaseStudy1Txt}
                                </div>
                            </div>
                        </div>
                        <div className="cs-section3-col">
                            <div className="buffer-bottom1">
                                <img src={home8} alt="" /></div>
                            <div className="cs3-Col left-txt">
                                <div className="h11 treePoppy-txt buffer-bottom">{this.props.CaseStudy2Title}</div>
                                <div className="h11">
                                    {this.props.CaseStudy2Txt}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="home-section8 gallery-bg spaceBetween">
                    <div className="h3 left-txt col1">{this.props.CSTitle3}</div>
                    <div className="col2"><Button className="home-section8-btn h4" buttonText="Get Started Now" onClick={() => this.props.goLogin()} /></div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default CaseStudiesView;