
import React, { Component } from "react";
import '../Button/Button.scss'
import '../../index.scss'

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <button className={`common-btn ${this.props.className}`} onClick={this.props.onClick}>{this.props.buttonText}</button>
        );

    }
}

export default Header;