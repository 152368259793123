//eslint-disable-next-line
import React, { Component, useState } from 'react'
import './GBLAdminView.scss'
import '../../../indexroi.scss'
import { TextInput } from '../../../components/MaterialInput'
import adminLogin from '../../../assets/images/login/adminLogin.svg'
import closeBtn from '../../../assets/images/close2.png'
class GBLAdminView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showForgotPassword: false,
      sendEmailTo: '',
      emailSent: false,
      userDoesNotExist: false
    }
  }

  checkUsernameExists() {
    var data = JSON.stringify({ email: this.state.sendEmailTo })
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    let that = this
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        if (this.status <= 299) {
          that.setState({
            emailSent: true,
            showForgotPassword: false,
            userDoesNotExist: false
          })
        } else {
          that.setState({
            emailSent: false,
            showForgotPassword: false,
            userDoesNotExist: true
          })
        }
      }
    })
    xhr.open(
      'GET',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/users/verifyUser/' +
      that.state.sendEmailTo
    )
    xhr.setRequestHeader(
      'x-access-token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxNGFiOTU4YTRjYjg4MDAxNWI2MjRkMCIsImlhdCI6MTYzMzQyMjYxMCwiZXhwIjoxNjMzNTA5MDEwfQ.W5usjOTlCT6WHN2dMBYCTy6c1McBOhcYFdCQNAemVzA'
    )
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(data)
  }

  render () {
    return (
      <div>
        <div
          className={
            this.state.showForgotPassword ? 'popupCard ' : 'popupCard dispNone'
          }
        >
          <div className='popup width30 forgotPassword'>
            <div className='flex spaceBetween'>
              <div className=' width70 h5 left-txt buffer-bottom'>
                Forgot Password
              </div>
              <button
                className='icon-btn pointer'
                onClick={() => this.setState({ showForgotPassword: false })}
              >
                <img src={closeBtn} alt='close the window' />
              </button>
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Dont worry! Just enter your registered email id and we will share
              a new password. You can change it from your profile anytime.
            </p>
            <TextInput
              type='text'
              label='Email'
              value={this.state.sendEmailTo}
              onChange={event => {
                this.setState({ sendEmailTo: event.target.value })
              }}
            />

            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns '
                disabled={this.state.sendEmailTo === ''}
                onClick={() => {
                  this.checkUsernameExists()
                }}
              >
                Generate password
              </button>
            </div>
          </div>
        </div>

        <div
          className={this.state.emailSent ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popup width30'>
            <div className='width100 h9 left-txt buffer-bottom'>
              Your new Password has been sent!
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Email sent to <b>{this.state.sendEmailTo}</b> !<br /> If you do
              not see the email in your inbox, please check your “junk mail”
              folder or “spam” folder.
            </p>
            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns'
                onClick={() => {
                  this.setState({ emailSent: false })
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.userDoesNotExist ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popup width30'>
            <div className='width100 h9 left-txt buffer-bottom'>
              Your username does not exist
            </div>
            <p className='h11 width80 left-txt buffer-bottom1'>
              Please check if you entered the correct email address{' '}
              <b>{this.state.sendEmailTo}</b>
            </p>
            <div className='row justifyContentCenter'>
              <button
                className='h6 justifyContentFlexEnd pop-ups-btns'
                onClick={() => {
                  this.setState({ userDoesNotExist: false })
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>

        <div className='loginPage admin-login'>
          <div className='leftLogin'>
            <img alt='Admin Login' className='loginImg' src={adminLogin} />
          </div>
          <div className='rightLogin flex-col'>
            <div className='h9'>Admin Login</div>
            <br />
            <TextInput
              type='email'
              label='Email Id'
              className='admin-input'
              value={this.props.email}
              error={this.props.emailError}
              onChange={this.props.onChangeEmail}
            />
            <TextInput
              label='Password'
              type='password'
              className='admin-input'
              value={this.props.password}
              error={this.props.passwordError}
              onChange={this.props.onChangePassword}
            />
            <button
              className='btn-primary btn-login'
              onClick={this.props.onLoginSubmit}
            >
              <span className='h6'>LOGIN</span>
            </button>
            <br />
            <div
              onClick={() =>this.setState({ showForgotPassword: true })}
              className='userLogin-btn treePoppy-txt h6 pointer'
            >
              Forgot Password?
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default GBLAdminView
