//eslint-disable-next-line
import React, { Component, useState } from 'react'
import Header from '../../../../components/Header/Header'
import './CompanyView.scss'
import '../../../../indexroi.scss'
import companyBar from '../../../../assets/images/roi/companyBar.svg'
import proceedInactive from '../../../../assets/images/roi/proceedInactive.svg'
import proceedActive from '../../../../assets/images/roi/proceedActive.svg'
import proj from '../../../../assets/images/roi/proj.svg'
import checklist from '../../../../assets/files/Mega checklist.xlsx'
import { Select, RadioButton } from '../../../../components/MaterialInput'
import ok from '../../../../assets/images/roi/ok.svg'

class CompanyView extends Component {
  constructor (props) {
    super(props)
    let local = JSON.parse(localStorage.getItem('udgbl'))
    let companyDetails = local.roiData[localStorage.getItem('currentProg')]
      .companyDetails
      ? local.roiData[localStorage.getItem('currentProg')].companyDetails
      : {
          currency: '',
          country: '',
          industry: '',
          annualWorkingDays: 0,
          avgWorkingHours: 4,
          productionTarget: 0,
          productionTargetType: 'daily',
          noOfWorkers: 0,
          womenWorkers: 0,
          wageBill: 0,
          wageBillDisp: 0,
          wageBillType: 'monthly',
          absenteeism: 0,
          absenteeismType: 'monthly',
          lateComingHours: 0,
          lateComingType: 'monthly',
          workerTurnover: 0,
          workerTurnoverType: 'monthly',
          avgRecruitmentCost: 0,
          trainingCostOfNewWorker: 0,
          miscRecruitmentExpenditure: 0,
          costOfLabor: 0,
          costOfLaborTotal: 0,
          countryCode: 'AFG',
          ironValue: '42.6',
          currencyValue: '86.045'
        }
    this.state = {
      isProceedActive: false,
      showLimit: false,
      showLimitText: '',
      userId: local.id,
      local: local,
      projectionName:
        local.roiData[localStorage.getItem('currentProg')].projectionName,
      forWho: local.roiData[localStorage.getItem('currentProg')].forWho,
      productionTargetText:
        companyDetails.industry === 'Textile' || companyDetails.industry === ''
          ? 'The daily/ annual production target set using the Standard Allowed Minutes (SAM).'
          : 'The number of final goods/ products produced in the factory daily/ annually.',
      companyDetails: companyDetails,
      ironValue: {
        Aruba: '0,ABW',
        'Africa Eastern and Southern': '33.87536982,AFE',
        Afghanistan: '42.6,AFG',
        'Africa Western and Central': '50.81442108,AFW',
        Angola: '44.5,AGO',
        Albania: '24.8,ALB',
        Andorra: '12.1,AND',
        'Arab World': '33.21666747,ARB',
        'United Arab Emirates': '24.3,ARE',
        Argentina: '11.9,ARG',
        Armenia: '17.3,ARM',
        'American Samoa': '0,ASM',
        'Antigua and Barbuda': '17.2,ATG',
        Australia: '8.5,AUS',
        Austria: '13,AUT',
        Azerbaijan: '35.1,AZE',
        Burundi: '38.5,BDI',
        Belgium: '13.6,BEL',
        Benin: '55.2,BEN',
        'Burkina Faso': '52.5,BFA',
        Bangladesh: '36.7,BGD',
        Bulgaria: '23.6,BGR',
        Bahrain: '35.4,BHR',
        'Bahamas, The': '14.5,BHS',
        'Bosnia and Herzegovina': '24.4,BIH',
        Belarus: '20.6,BLR',
        Belize: '20.5,BLZ',
        Bermuda: '0,BMU',
        Bolivia: '24.4,BOL',
        Brazil: '16.1,BRA',
        Barbados: '17,BRB',
        'Brunei Darussalam': '16.7,BRN',
        Bhutan: '38.6,BTN',
        Botswana: '32.5,BWA',
        'Central African Republic': '46.8,CAF',
        Canada: '10.4,CAN',
        'Central Europe and the Baltics': '0,CEB',
        Switzerland: '11.3,CHE',
        'Channel Islands': '0,CHI',
        Chile: '8.7,CHL',
        China: '15.5,CHN',
        "Cote d'Ivoire": '50.9,CIV',
        Cameroon: '40.6,CMR',
        'Congo, Dem. Rep.': '42.4,COD',
        'Congo, Rep.': '48.8,COG',
        Colombia: '21.2,COL',
        Comoros: '33.8,COM',
        'Cabo Verde': '24.3,CPV',
        'Costa Rica': '13.7,CRI',
        'Caribbean small states': '20.20679346,CSS',
        Cuba: '19.3,CUB',
        Curacao: '0,CUW',
        'Cayman Islands': '0,CYM',
        Cyprus: '13.6,CYP',
        'Czech Republic': '21.1,CZE',
        Germany: '11.7,DEU',
        Djibouti: '32.3,DJI',
        Dominica: '20.8,DMA',
        Denmark: '12.2,DNK',
        'Dominican Republic': '26.4,DOM',
        Algeria: '33.3,DZA',
        'East Asia & Pacific (excluding high income)': '19.74788605,EAP',
        'Early-demographic dividend': '39.28879004,EAR',
        'East Asia & Pacific': '19.42877438,EAS',
        'Europe & Central Asia (excluding high income)': '23.05219045,ECA',
        'Europe & Central Asia': '17.43742434,ECS',
        Ecuador: '17.2,ECU',
        'Egypt, Arab Rep.': '28.3,EGY',
        'Euro area': '12.79239728,EMU',
        Eritrea: '37,ERI',
        Spain: '13.4,ESP',
        Estonia: '21.7,EST',
        Ethiopia: '23.9,ETH',
        'European Union': '13.96208471,EUU',
        'Fragile and conflict affected situations': '41.80911069,FCS',
        Finland: '10.9,FIN',
        Fiji: '32,FJI',
        France: '10.6,FRA',
        'Faroe Islands': '0,FRO',
        'Micronesia, Fed. Sts.': '25,FSM',
        Gabon: '52.4,GAB',
        'United Kingdom': '11.1,GBR',
        Georgia: '27.5,GEO',
        Ghana: '35.4,GHA',
        Gibraltar: '0,GIB',
        Guinea: '48,GIN',
        'Gambia, The': '49.5,GMB',
        'Guinea-Bissau': '48.1,GNB',
        'Equatorial Guinea': '44.5,GNQ',
        Greece: '15.1,GRC',
        Grenada: '19.2,GRD',
        Greenland: '0,GRL',
        Guatemala: '7.4,GTM',
        Guam: '0,GUM',
        Guyana: '31.7,GUY',
        'High income': '13.71195284,HIC',
        'Hong Kong SAR, China': '0,HKG',
        Honduras: '18,HND',
        'Heavily indebted poor countries (HIPC)': '38.40443842,HPC',
        Croatia: '21,HRV',
        Haiti: '47.7,HTI',
        Hungary: '19.7,HUN',
        'IBRD only': '29.83152798,IBD',
        'IDA & IBRD total': '32.52671161,IBT',
        'IDA total': '40.27047392,IDA',
        'IDA blend': '43.11006012,IDB',
        Indonesia: '31.2,IDN',
        'IDA only': '38.86179124,IDX',
        'Isle of Man': '0,IMN',
        India: '53,IND',
        'Not classified': '0,INX',
        Ireland: '12.1,IRL',
        'Iran, Islamic Rep.': '24.1,IRN',
        Iraq: '28.6,IRQ',
        Iceland: '10.3,ISL',
        Israel: '12.9,ISR',
        Italy: '13.6,ITA',
        Jamaica: '19.9,JAM',
        Jordan: '37.7,JOR',
        Japan: '19,JPN',
        Kazakhstan: '28.7,KAZ',
        Kenya: '28.7,KEN',
        'Kyrgyz Republic': '35.8,KGZ',
        Cambodia: '47.1,KHM',
        Kiribati: '32.6,KIR',
        'St. Kitts and Nevis': '15.4,KNA',
        'Korea, Rep.': '13.5,KOR',
        Kuwait: '23.7,KWT',
        'Latin America & Caribbean (excluding high income)': '17.19537384,LAC',
        'Lao PDR': '39.5,LAO',
        Lebanon: '28.3,LBN',
        Liberia: '42.6,LBR',
        Libya: '29.9,LBY',
        'St. Lucia': '14.3,LCA',
        'Latin America & Caribbean': '17.24386944,LCN',
        'Least developed countries: UN classification': '39.36378023,LDC',
        'Low income': '38.72725199,LIC',
        Liechtenstein: '0,LIE',
        'Sri Lanka': '34.6,LKA',
        'Lower middle income': '42.08293128,LMC',
        'Low & middle income': '32.63962599,LMY',
        Lesotho: '27.9,LSO',
        'Late-demographic dividend': '18.01452769,LTE',
        Lithuania: '19.9,LTU',
        Luxembourg: '10.2,LUX',
        Latvia: '21.6,LVA',
        'Macao SAR, China': '0,MAC',
        'St. Martin (French part)': '0,MAF',
        Morocco: '29.9,MAR',
        Monaco: '12.3,MCO',
        Moldova: '26.1,MDA',
        Madagascar: '37.8,MDG',
        Maldives: '52.2,MDV',
        'Middle East & North Africa': '30.32373619,MEA',
        Mexico: '15.3,MEX',
        'Marshall Islands': '30.6,MHL',
        'Middle income': '31.98848499,MIC',
        'North Macedonia': '19.3,MKD',
        Mali: '59,MLI',
        Malta: '13.7,MLT',
        Myanmar: '42.1,MMR',
        'Middle East & North Africa (excluding high income)': '31.13486452,MNA',
        Montenegro: '17.2,MNE',
        Mongolia: '14.5,MNG',
        'Northern Mariana Islands': '0,MNP',
        Mozambique: '47.9,MOZ',
        Mauritania: '43.3,MRT',
        Mauritius: '23.5,MUS',
        Malawi: '31.4,MWI',
        Malaysia: '32,MYS',
        'North America': '11.65670124,NAC',
        Namibia: '25.2,NAM',
        'New Caledonia': '0,NCL',
        Niger: '49.5,NER',
        Nigeria: '55.1,NGA',
        Nicaragua: '15.7,NIC',
        Netherlands: '12.8,NLD',
        Norway: '12,NOR',
        Nepal: '35.7,NPL',
        Nauru: '29.6,NRU',
        'New Zealand': '10.4,NZL',
        'OECD members': '13.61192684,OED',
        Oman: '29.1,OMN',
        'Other small states': '33.16048388,OSS',
        Pakistan: '41.3,PAK',
        Panama: '21.2,PAN',
        Peru: '20.6,PER',
        Philippines: '12.3,PHL',
        Palau: '28.5,PLW',
        'Papua New Guinea': '34.4,PNG',
        Poland: '0,POL',
        'Pre-demographic dividend': '43.69281712,PRE',
        'Puerto Rico': '0,PRI',
        "Korea, Dem. People's Rep.": '33.9,PRK',
        Portugal: '13.2,PRT',
        Paraguay: '23,PRY',
        'West Bank and Gaza': '0,PSE',
        'Pacific island small states': '32.27259555,PSS',
        'Post-demographic dividend': '13.39657382,PST',
        'French Polynesia': '0,PYF',
        Qatar: '28.1,QAT',
        Romania: '22.7,ROU',
        'Russian Federation': '21.1,RUS',
        Rwanda: '17.2,RWA',
        'South Asia': '49.37369877,SAS',
        'Saudi Arabia': '27.5,SAU',
        Sudan: '36.5,SDN',
        Senegal: '52.7,SEN',
        Singapore: '13,SGP',
        'Solomon Islands': '37.7,SLB',
        'Sierra Leone': '48.4,SLE',
        'El Salvador': '10.6,SLV',
        'San Marino': '12.5,SMR',
        Somalia: '43.1,SOM',
        Serbia: '22.8,SRB',
        'Sub-Saharan Africa (excluding high income)': '40.54959359,SSA',
        'South Sudan': '35.6,SSD',
        'Sub-Saharan Africa': '40.54824012,SSF',
        'Small states': '30.64733634,SST',
        'Sao Tome and Principe': '44.2,STP',
        Suriname: '21,SUR',
        'Slovak Republic': '23.5,SVK',
        Slovenia: '21.8,SVN',
        Sweden: '13.6,SWE',
        Eswatini: '30.7,SWZ',
        'Sint Maarten (Dutch part)': '0,SXM',
        Seychelles: '25.1,SYC',
        'Syrian Arab Republic': '32.8,SYR',
        'Turks and Caicos Islands': '0,TCA',
        Chad: '45.4,TCD',
        'East Asia & Pacific (IDA & IBRD countries)': '19.5681007,TEA',
        'Europe & Central Asia (IDA & IBRD countries)': '23.03016542,TEC',
        Togo: '45.7,TGO',
        Thailand: '24,THA',
        Tajikistan: '35.2,TJK',
        Turkmenistan: '26.6,TKM',
        'Latin America & the Caribbean (IDA & IBRD countries)':
          '17.21425861,TLA',
        'Timor-Leste': '29.9,TLS',
        'Middle East & North Africa (IDA & IBRD countries)': '31.13486452,TMN',
        Tonga: '28.5,TON',
        'South Asia (IDA & IBRD)': '49.37369877,TSA',
        'Sub-Saharan Africa (IDA & IBRD countries)': '40.54824012,TSS',
        'Trinidad and Tobago': '17.7,TTO',
        Tunisia: '32.1,TUN',
        Turkey: '0,TUR',
        Tuvalu: '27.5,TUV',
        Tanzania: '38.9,TZA',
        Uganda: '32.8,UGA',
        Ukraine: '17.7,UKR',
        'Upper middle income': '17.80559307,UMC',
        Uruguay: '15,URY',
        'United States': '11.8,USA',
        Uzbekistan: '24.8,UZB',
        'St. Vincent and the Grenadines': '17,VCT',
        'Venezuela, RB': '24.2,VEN',
        'British Virgin Islands': '0,VGB',
        'Virgin Islands (U.S.)': '0,VIR',
        Vietnam: '20.6,VNM',
        Vanuatu: '28.5,VUT',
        World: '29.9,WLD',
        Samoa: '26.8,WSM',
        Kosovo: '0,XKX',
        'Yemen, Rep.': '61.5,YEM',
        'South Africa': '30.5,ZAF',
        Zambia: '31.5,ZMB',
        Zimbabwe: '28.9,ZWE'
      },
      currencyValue: {
        AFN: '86.045',
        ALL: '101.39',
        DZD: '134.77',
        USD: '1',
        EUR: '0.848',
        AOA: '643.121',
        XCD: '2.7',
        ARS: '97.711',
        AMD: '490.5',
        AWG: '1.79',
        AZN: '1.695',
        BSD: '1',
        BHD: '0.377',
        BDT: '84.845',
        BBD: '2',
        BYN: '2.507',
        BZD: '2',
        BMD: '1',
        BTN: '73.27',
        BOB: '6.846',
        BAM: '1.659',
        BWP: '11.062',
        BRL: '5.21',
        BND: '1.345',
        BGN: '1.659',
        BIF: '1968.932',
        KHR: '4115',
        CAD: '1.26',
        CVE: '93.54',
        KYD: '0.82',
        CLP: '780',
        CNY: '6.468',
        COP: '3809',
        KMF: '417.346',
        CDF: '1968.345',
        CRC: '618.51',
        HRK: '6.352',
        CUP: '24',
        CZK: '21.69',
        DJF: '177',
        DOP: '56.778',
        EGP: '15.652',
        ERN: '15',
        SZL: '14.645',
        ETB: '45.332',
        FJD: '2.1',
        XAF: '556.462',
        GMD: '51.46',
        GEL: '3.119',

        GHS: '5.976',
        GIP: '0.727',

        DKK: '6.308',

        GTQ: '7.725',
        GNF: '9719.95',

        GYD: '208.2',
        HTG: '95.546',
        HNL: '23.712',
        HKD: '7.787',
        HUF: '295.16',
        ISK: '126.99',
        INR: '73.27',
        IDR: '14368',
        IRR: '267982',
        IQD: '1450',

        ILS: '3.218',

        JMD: '150.754',
        JPY: '109.94',
        JOD: '0.708',
        KZT: '426.57',
        KES: '109.72',
        KPW: '110',
        KRW: '1166.39',
        KWD: '0.301',
        KGS: '84.4',
        LAK: '9590',

        LBP: '1507.5',
        LSL: '14.645',
        LRD: '170.981',
        LYD: '4.536',
        CHF: '0.917',

        MOP: '8.005',
        MGA: '3842.05',
        MWK: '815',
        MYR: '4.153',
        MVR: '15',

        MRU: '36.04',
        MUR: '42.17',
        MXN: '20.19',

        MDL: '17.71',

        MNT: '2835.42',

        MAD: '8.884',
        MZN: '63.1',
        MMK: '1652',
        NAD: '14.645',
        AUD: '1.371',
        NPR: '117.2189',

        ANG: '1.767',

        NIO: '34.985',

        NGN: '411.33',

        MKD: '51.941',

        NOK: '8.661',
        OMR: '0.384',
        PKR: '165.27',

        PAB: '1',
        PGK: '3.509',
        PYG: '6912',
        PEN: '4.08',
        PHP: '49.82',
        PLN: '3.874',

        QAR: '3.694',

        RON: '4.186',
        RUB: '73.613',
        RWF: '998.53',
        SHP: '0.727',

        WST: '2.553',

        STN: '20.87',
        SAR: '3.751',

        RSD: '99.646',
        SCR: '13.081',
        SLL: '10330',
        SGD: '1.345',

        SBD: '7.918',
        SOS: '24300',
        ZAR: '14.645',
        SSP: '411.799',

        LKR: '199.35',

        SDG: '445',
        SRD: '21.1',
        SEK: '8.639',

        SYP: '2500',
        TJS: '11.273',
        TZS: '2310.5',
        THB: '32.46',
        XOF: '556.462',
        NZD: '1.429',
        TOP: '2.192',
        TTD: '6.757',
        TND: '2.782',
        TRY: '8.377',
        TMT: '3.5',
        UGX: '3540.06',
        UAH: '26.75',
        AED: '3.673',
        GBP: '0.727',

        UYU: '42.371',
        UZS: '10620',
        VUV: '108.91',
        VES: '4147300.52',
        VND: '22755',

        XPF: '101.232',
        YER: '581.03',
        ZMW: '15.87',
        ZWL: '83.8'
      }
    }
  }

  componentDidMount = () => {
    this.checkProceedActive()
  }

  checkMax = (value, max, item, limitText) => {
    if (parseInt(value) > max) {
      let companyDetails = this.state.companyDetails
      companyDetails[item] = ''
      this.setState({
        companyDetails: companyDetails,
        showLimit: true,
        showLimitText: limitText
      })
    }
    this.checkProceedActive()
  }

  checkMin = (value, min, item, limitText) => {
    if (parseInt(value) < min) {
      let companyDetails = this.state.companyDetails
      companyDetails[item] = ''
      this.setState({
        companyDetails: companyDetails,
        showLimit: true,
        showLimitText: limitText
      })
    }
    this.checkProceedActive()
  }

  calculateCostOfLabor = () => {
    let companyDetails = this.state.companyDetails

    let b7 = 0
    if (companyDetails.productionTargetType === 'annual') {
      b7 =
        parseInt(companyDetails.productionTarget) /
        parseInt(companyDetails.annualWorkingDays)
    } else {
      b7 = parseInt(companyDetails.productionTarget)
    }

    let costOfLabor = 0
    if (companyDetails.wageBillType === 'annual') {
      costOfLabor =
        (parseInt(companyDetails.wageBill) *
          parseInt(companyDetails.womenWorkers)) /
        (b7 *
          parseInt(companyDetails.annualWorkingDays) *
          parseInt(companyDetails.noOfWorkers))
    } else {
      costOfLabor =
        (parseInt(companyDetails.wageBill) *
          12 *
          parseInt(companyDetails.womenWorkers)) /
        (b7 *
          parseInt(companyDetails.annualWorkingDays) *
          parseInt(companyDetails.noOfWorkers))
    }

    let costOfLaborTotal = 0
    if (companyDetails.wageBillType === 'annual') {
      costOfLaborTotal =
        parseInt(companyDetails.wageBill) /
        (b7 * parseInt(companyDetails.annualWorkingDays))
    } else {
      costOfLaborTotal =
        (parseInt(companyDetails.wageBill) * 12) /
        (b7 * parseInt(companyDetails.annualWorkingDays))
    }

    companyDetails.costOfLabor = costOfLabor
    companyDetails.costOfLaborTotal = costOfLaborTotal
    companyDetails.ironValue = this.state.ironValue[companyDetails.country]
      ? this.state.ironValue[companyDetails.country].split(',')[0]
      : ''
    companyDetails.countryCode = this.state.ironValue[companyDetails.country]
      ? this.state.ironValue[companyDetails.country].split(',')[1]
      : ''
    companyDetails.currencyValue =
      this.state.currencyValue[companyDetails.currency] || ''
    this.setState({ companyDetails: companyDetails })
  }

  saveCompanyDetails = () => {
    this.calculateCostOfLabor()
    let newRoiData = this.state.local
    newRoiData.roiData[
      localStorage.getItem('currentProg')
    ].companyDetails = this.state.companyDetails
    var data = JSON.stringify({
      roiData: newRoiData.roiData
    })
    localStorage.setItem('udgbl', JSON.stringify(newRoiData))

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        window.location.href = '/input'
      }
    })

    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/user/updateROI/' +
        this.state.userId
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
    )

    xhr.send(data)
  }

  checkProceedActive = () => {
    let isProceedActive = true
    let companyDetails = this.state.companyDetails
    companyDetails.absenteeismType = document.querySelector(
      'input[name="radAb"]:checked'
    )
      ? document.querySelector('input[name="radAb"]:checked').value
      : ''
    companyDetails.lateComingType = document.querySelector(
      'input[name="radLC"]:checked'
    )
      ? document.querySelector('input[name="radLC"]:checked').value
      : ''
    companyDetails.productionTargetType = document.querySelector(
      'input[name="radTarget"]:checked'
    )
      ? document.querySelector('input[name="radTarget"]:checked').value
      : ''
    companyDetails.wageBillType = document.querySelector(
      'input[name="radWorkforce"]:checked'
    )
      ? document.querySelector('input[name="radWorkforce"]:checked').value
      : ''
    companyDetails.workerTurnoverType = document.querySelector(
      'input[name="radTurnover"]:checked'
    )
      ? document.querySelector('input[name="radTurnover"]:checked').value
      : ''
    this.setState({ companyDetails: companyDetails })
    if (
      companyDetails.currency === '' ||
      companyDetails.country === '' ||
      companyDetails.industry === '' ||
      companyDetails.annualWorkingDays === 0 ||
      companyDetails.annualWorkingDays === '' ||
      companyDetails.avgWorkingHours === 0 ||
      companyDetails.avgWorkingHours === '' ||
      companyDetails.productionTarget === 0 ||
      companyDetails.productionTarget === '' ||
      companyDetails.productionTargetType === '' ||
      companyDetails.noOfWorkers === 0 ||
      companyDetails.noOfWorkers === '' ||
      companyDetails.womenWorkers === 0 ||
      companyDetails.womenWorkers === '' ||
      companyDetails.wageBill === 0 ||
      companyDetails.wageBill === '' ||
      companyDetails.wageBillType === '' ||
      companyDetails.absenteeism === '' ||
      companyDetails.absenteeismType === '' ||
      companyDetails.lateComingHours === '' ||
      companyDetails.lateComingType === '' ||
      companyDetails.workerTurnover === '' ||
      companyDetails.workerTurnoverType === '' ||
      companyDetails.avgRecruitmentCost === '' ||
      companyDetails.trainingCostOfNewWorker === '' ||
      companyDetails.miscRecruitmentExpenditure === ''
    ) {
      isProceedActive = false
    }
    this.setState({ isProceedActive: isProceedActive })
  }

  getNumber = _str => {
    var arr = _str.split('')
    var out = []
    for (var cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) === false) {
        out.push(arr[cnt])
      }
    }
    return Number(out.join(''))
  }

  rangeError = () => {
    this.setState({
      showLimit: true,
      showLimitText: 'Range is not allowed'
    })
  }

  render () {
    let companyDetails = this.state.companyDetails
    return (
      <div className='container noover'>
        <Header />
        <div
          className={this.state.showLimit ? 'popupCard' : 'popupCard dispNone'}
        >
          <div className='popup popupCardTop35 left-txt'>
            <div className='h9 padBot16'>Error</div>
            <div className='h11'>
              {this.state.showLimitText}
              <br />
              <br />
            </div>
            <div className='width100 flex justifyContentFlexEnd'>
              <img
                alt='ok'
                className='pointer height40px padLeft24'
                onClick={() => {
                  this.setState({ showLimit: false })
                }}
                src={ok}
              />
            </div>
          </div>
        </div>
        <div className='blackWhite-bg height100'>
          <div className='bar flex justifyContentSpaceBetween  alignItemsCenter'>
            <div className='width60'>
              <img className='width100' src={companyBar} alt='Company Bar' />
            </div>
            <div className='flex alignItemsCenter'>
              <div
                onClick={() => {
                  this.saveCompanyDetails()
                  window.location.href = '/projections'
                }}
              >
                <img
                  alt='projections'
                  className=' pointer height32 margRight8 margBot4'
                  src={proj}
                />
              </div>
              {this.state.isProceedActive ? (
                <img
                  alt='proceed active'
                  onClick={() => {
                    this.saveCompanyDetails()
                  }}
                  className='pointer height32'
                  src={proceedActive}
                />
              ) : (
                <img
                  alt='proceed inactive'
                  className='height32'
                  src={proceedInactive}
                />
              )}
            </div>
          </div>
          <div className='flex padTop24'>
            <div className='bar width20 flexColumn justifyContentSpaceBetween height70 padTop48'>
              <div>
                <div className='h7 padRight24 left-txt nowrap'>Step 2</div>
                <br />
                <div className='h4 left-txt '>
                  Input Company baseline details
                </div>
                <br />
                <div className='h7 left-txt padBot3rem'>
                  The details that you input in this form are used as the
                  baseline data for all your shortlisted programmes.{' '}
                  <span
                    onClick={() => {
                      window.open('/roi-tGuide/#FAQ', '_blank')
                    }}
                    className='h12 treePoppy-txt pointer'
                  >
                    Learn More
                  </span>
                </div>
                <div className='left-txt'>
                  <div className='h6'>{this.state.projectionName}</div>
                  <div className='h7'>{this.state.forWho}</div>
                </div>
              </div>
              <div className='h7 left-txt'>
                <a
                  className='h12 treePoppy-txt pointer'
                  href={checklist}
                  download='ROI-T invest in her -  Mega checklist'
                >
                  DOWNLOAD THE MEGA CHECKLIST
                </a>
                <br />
                You can use this handy checklist to collect the details offline
                and add them in later.
                <br />
                <br />
                <br />
              </div>
            </div>
            <div className='rightSection'>
              <div className='companyCard'>
                <div className='flex'>
                  <div className='width40'></div>
                  <div className='width55 right-txt h14'>
                    * All fields are mandatory
                  </div>
                </div>
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>COMPANY DETAILS</div>
                    <div className='h7'>
                      Details about the factory set-up where you would be
                      implementing the health programmes
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Currency</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      All financial aspects of this projection will be shown in
                      the selected currency
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter borderSelect margBot32'>
                      <Select
                        className='h11 width100 gallery-bg'
                        value={this.state.companyDetails.currency}
                        onChange={event => {
                          companyDetails.currency = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      >
                        <option value=''>Select Currency</option>
                        <option value='AFN'>Afghanistan Afghani</option>
                        <option value='ALL'>Albania Lek(e)</option>
                        <option value='DZD'>Algerian Dinar</option>
                        <option value='EUR'>Euro</option>
                        <option value='AOA'>Angolan Kwanza</option>
                        <option value='XCD'>E.C. Dollar</option>
                        <option value='ARS'>Argentine Peso</option>
                        <option value='AMD'>Armenian Dram</option>
                        <option value='AWG'>Aruban Guilder</option>
                        <option value='AUD'>Australian Dollar</option>
                        <option value='AZN'>Azerbaijan Manat</option>
                        <option value='BSD'>Bahamian Dollar</option>
                        <option value='BHD'>Bahraini Dinar</option>
                        <option value='BDT'>Bangladesh Taka</option>
                        <option value='BBD'>Barbados Dollar</option>
                        <option value='BYN'>Belarusian Ruble</option>
                        <option value='BZD'>Belize Dollar</option>
                        <option value='BMD'>Bermuda Dollar</option>
                        <option value='BTN'>Bhutan Ngultrum</option>
                        <option value='BOB'>Bolivia Boliviano</option>
                        <option value='BAM'>
                          Bosnia and Herzegovina Convertible Mark
                        </option>
                        <option value='BWP'>Botswana Pula</option>
                        <option value='BRL'>Brazilian Real</option>
                        <option value='BND'>Brunei Dollar</option>
                        <option value='BGN'>Bulgarian Lev</option>
                        <option value='BIF'>Burundi Franc</option>
                        <option value='KHR'>Cambodian Riel</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='CAD'>Canadian Dollar</option>
                        <option value='CVE'>Cape Verde Escudo</option>
                        <option value='KYD'>Cayman Is. Dollar</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='CLP'>Chilean Peso</option>
                        <option value='CNY'>Chinese Renminbi</option>
                        <option value='COP'>Colombian Peso</option>
                        <option value='KMF'>Comoros Franc</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='CDF'>Congo Franc, Dem. Rep.of</option>
                        <option value='NZD'>New Zealand Dollar</option>
                        <option value='CRC'>Costa Rica Colon</option>
                        <option value='HRK'>Croatia Kuna</option>
                        <option value='CUP'>Cuban Peso</option>
                        <option value='CZK'>Czech Koruna</option>
                        <option value='DKK'>Danish Krone</option>
                        <option value='DJF'>Djibouti Francs</option>
                        <option value='DOP'>Dominican Peso</option>
                        <option value='EGP'>Egyptian Pound</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='ERN'>Eritrea Nakfa</option>
                        <option value='SZL'>Lilangeni</option>
                        <option value='ETB'>Ethiopian Birr</option>
                        <option value='FJD'>Fiji Dollar</option>
                        <option value='XAF'>CFA Franc</option>
                        <option value='GMD'>Gambian Dalasi</option>
                        <option value='GEL'>Georgian Lari</option>
                        <option value='GHS'>Ghana Cedi</option>
                        <option value='GIP'>Gibraltar Pound</option>
                        <option value='GTQ'>Guatemala Quetzal(es)</option>
                        <option value='GNF'>Guinean Franc</option>
                        <option value='GYD'>Guyana Dollar</option>
                        <option value='HTG'>Haiti Gourde</option>
                        <option value='HNL'>Honduras Lempira</option>
                        <option value='HKD'>HongKong Dollar</option>
                        <option value='HUF'>Hungary Forint</option>
                        <option value='ISK'>Iceland Krona</option>
                        <option value='INR'>Indian Rupee</option>
                        <option value='IDR'>Indonesia Rupiah</option>
                        <option value='IRR'>Iranian Rial</option>
                        <option value='IQD'>Iraqi Dinar</option>
                        <option value='ILS'>Israel Shekel</option>
                        <option value='JMD'>Jamaican Dollar</option>
                        <option value='JPY'>Japanese Yen</option>
                        <option value='JOD'>Jordanian Dinar</option>
                        <option value='KZT'>Kazakhstan Tenge</option>
                        <option value='KES'>Kenyan Shilling</option>
                        <option value='KPW'>Korean Won, North Korea</option>
                        <option value='KRW'>Korean Won, South Korea</option>
                        <option value='KWD'>Kuwaiti Dinar</option>
                        <option value='KGS'>Kyrgyzstan Som</option>
                        <option value='LAK'>Laos Kip</option>
                        <option value='LBP'>Lebanese Pound</option>
                        <option value='LSL'>Lesotho Loti</option>
                        <option value='LRD'>Liberian Dollar</option>
                        <option value='LYD'>Libyan Dinar</option>
                        <option value='MOP'>Macao Pataca</option>
                        <option value='MGA'>Malagasy Ariary, Madagascar</option>
                        <option value='MWK'>Malawi Kwacha</option>
                        <option value='MYR'>Malaysia Ringgit</option>
                        <option value='MVR'>Maldives Rufiyaa</option>
                        <option value='MRU'>Mauritania Ouguiya</option>
                        <option value='MUR'>Mauritius Rupee</option>
                        <option value='MXN'>Mexican Peso</option>
                        <option value='MDL'>Moldovan Leu</option>
                        <option value='MNT'>Mongolia Tugrik</option>
                        <option value='MAD'>Morocco Dirham</option>
                        <option value='MZN'>Mozambique Metical</option>
                        <option value='MMK'>Myanmar Kyat</option>
                        <option value='NAD'>Namibia Dollar</option>
                        <option value='NPR'>Nepalese Rupee</option>
                        <option value='ANG'>
                          Netherlands Antilles Guilder
                        </option>
                        <option value='XPF'>CFP Franc</option>
                        <option value='NIO'>Nicaragua Cordoba Oro</option>
                        <option value='NGN'>Nigeria Naira</option>
                        <option value='MKD'>Denar</option>
                        <option value='NOK'>Norwegian Krone</option>
                        <option value='OMR'>Oman Rial</option>
                        <option value='PKR'>Pakistani Rupee</option>
                        <option value='PAB'>Panama Balboa</option>
                        <option value='PGK'>Kina</option>
                        <option value='PYG'>Paraguay Guarani</option>
                        <option value='PEN'>Sol</option>
                        <option value='PHP'>Philippine Peso</option>
                        <option value='PLN'>Poland Zloty</option>
                        <option value='QAR'>Qatari Rial</option>
                        <option value='RON'>Romanian Leu</option>
                        <option value='RUB'>Russian Rouble</option>
                        <option value='RWF'>Rwanda Franc</option>
                        <option value='SHP'>St.Helena Pound</option>
                        <option value='WST'>Samoa Tala</option>
                        <option value='STN'>Sao Tome Principe Dobra</option>
                        <option value='SAR'>Saudi Riyal</option>
                        <option value='XOF'>CFA Franc</option>
                        <option value='RSD'>Serbian Dinar</option>
                        <option value='SCR'>Seychelles Rupee</option>
                        <option value='SLL'>Sierra Leone Leone</option>
                        <option value='SGD'>Singapore Dollar</option>
                        <option value='SBD'>Solomon Is. Dollar</option>
                        <option value='SOS'>Somali Shilling</option>
                        <option value='ZAR'>South Africa Rand</option>
                        <option value='SSP'>South Sudanese Pound</option>
                        <option value='LKR'>Sri Lanka Rupee</option>
                        <option value='SDG'>Sudanese Pound</option>
                        <option value='SRD'>Surinamese Dollar</option>
                        <option value='SEK'>Swedish Krona</option>
                        <option value='CHF'>Swiss Franc</option>
                        <option value='SYP'>Syrian Pound</option>
                        <option value='TJS'>Tajikistan Somoni</option>
                        <option value='TZS'>Tanzania Shilling</option>
                        <option value='THB'>Thai Baht</option>
                        <option value='TOP'>Tonga Pa'anga</option>
                        <option value='TTD'>Trinidad and Tobago Dollar</option>
                        <option value='TND'>Tunisian Dinar</option>
                        <option value='TRY'>Turkish Lira</option>
                        <option value='TMT'>Turkmenistan Manat</option>

                        <option value='UGX'>Uganda Shilling</option>
                        <option value='UAH'>Ukraine Hryvnia</option>
                        <option value='AED'>United Arab Emirates Dirham</option>
                        <option value='GBP'>U.K. Pound</option>
                        <option value='UYU'>Uruguay Peso</option>
                        <option value='UZS'>Uzbekistan Sum</option>
                        <option value='VUV'>Vanuatu Vatu</option>
                        <option value='VES'>Bolivar Soberano</option>
                        <option value='VND'>Viet Nam Dong</option>
                        <option value='USD'>US Dollar</option>
                        <option value='YER'>Yemeni Rial</option>
                        <option value='ZMW'>Zambia Kwacha</option>
                        <option value='ZWL'>Zimbabwe RTGS dollar</option>
                      </Select>
                    </div>

                    <div className='h5'>Country</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      State the Country/region where your factory is located
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter borderSelect margBot32'>
                      <Select
                        className='h11 width100 gallery-bg'
                        value={this.state.companyDetails.country}
                        onChange={event => {
                          companyDetails.country = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      >
                        <option value=''>Select Country</option>
                        <option value='Afghanistan'>Afghanistan</option>
                        <option value='Albania'>Albania</option>
                        <option value='Algeria'>Algeria</option>
                        <option value='American Samoa'>American Samoa</option>
                        <option value='Andorra'>Andorra</option>
                        <option value='Angola'>Angola</option>
                        <option value='Anguilla'>Anguilla</option>
                        <option value='Antigua and Barbuda'>
                          Antigua and Barbuda
                        </option>
                        <option value='Argentina'>Argentina</option>
                        <option value='Armenia'>Armenia</option>
                        <option value='Aruba'>Aruba</option>
                        <option value='Australia'>Australia</option>
                        <option value='Austria'>Austria</option>
                        <option value='Azerbaijan'>Azerbaijan</option>
                        <option value='Bahamas'>Bahamas</option>
                        <option value='Bahrain'>Bahrain</option>
                        <option value='Bangladesh'>Bangladesh</option>
                        <option value='Barbados'>Barbados</option>
                        <option value='Belarus'>Belarus</option>
                        <option value='Belgium'>Belgium</option>
                        <option value='Belize'>Belize</option>
                        <option value='Benin'>Benin</option>
                        <option value='Bermuda'>Bermuda</option>
                        <option value='Bhutan'>Bhutan</option>
                        <option value='Bolivia'>Bolivia</option>
                        <option value='Bosnia and Herzegovina'>
                          Bosnia and Herzegovina
                        </option>
                        <option value='Botswana'>Botswana</option>
                        <option value='Brazil'>Brazil</option>
                        <option value='Brunei'>Brunei</option>
                        <option value='Bulgaria'>Bulgaria</option>
                        <option value='Burkina Faso'>Burkina Faso</option>
                        <option value='Burundi'>Burundi</option>
                        <option value='Cambodia'>Cambodia</option>
                        <option value='Cameroon'>Cameroon</option>
                        <option value='Canada'>Canada</option>
                        <option value='Canary Islands'>Canary Islands</option>
                        <option value='Cape Verde'>Cape Verde</option>
                        <option value='Cayman Islands'>Cayman Islands</option>
                        <option value='Central African Republic'>
                          Central African Republic
                        </option>
                        <option value='Chad'>Chad</option>
                        <option value='Chile'>Chile</option>
                        <option value='China'>China</option>
                        <option value='Colombia'>Colombia</option>
                        <option value='Comoros'>Comoros</option>
                        <option value='Congo'>Congo</option>
                        <option value='Congo, Dem. Rep.'>
                          Congo, Dem. Rep.
                        </option>
                        <option value='Cook Islands'>Cook Islands</option>
                        <option value='Costa Rica'>Costa Rica</option>
                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                        <option value='Croatia'>Croatia</option>
                        <option value='Cuba'>Cuba</option>
                        <option value='Cyprus'>Cyprus</option>
                        <option value='Czech Republic'>Czech Republic</option>
                        <option value='Denmark'>Denmark</option>
                        <option value='Djibouti'>Djibouti</option>
                        <option value='Dominica'>Dominica</option>
                        <option value='Dominican Republic'>
                          Dominican Republic
                        </option>
                        <option value='East Timor'>East Timor</option>
                        <option value='Ecuador'>Ecuador</option>
                        <option value='Egypt'>Egypt</option>
                        <option value='El Salvador'>El Salvador</option>
                        <option value='Equatorial Guinea'>
                          Equatorial Guinea
                        </option>
                        <option value='Eritrea'>Eritrea</option>
                        <option value='Estonia'>Estonia</option>
                        <option value='Eswatini, Kingdom of'>
                          Eswatini, Kingdom of
                        </option>
                        <option value='Ethiopia'>Ethiopia</option>
                        <option value='Fiji'>Fiji</option>
                        <option value='Finland'>Finland</option>
                        <option value='France'>France</option>
                        <option value='French Guiana'>French Guiana</option>
                        <option value='French Polynesia'>
                          French Polynesia
                        </option>
                        <option value='Gabon'>Gabon</option>
                        <option value='Gambia'>Gambia</option>
                        <option value='Georgia'>Georgia</option>
                        <option value='Germany'>Germany</option>
                        <option value='Ghana'>Ghana</option>
                        <option value='Gibraltar'>Gibraltar</option>
                        <option value='Greece'>Greece</option>
                        <option value='Greenland'>Greenland</option>
                        <option value='Grenada'>Grenada</option>
                        <option value='Guadeloupe'>Guadeloupe</option>
                        <option value='Guam'>Guam</option>
                        <option value='Guatemala'>Guatemala</option>
                        <option value='Guinea'>Guinea</option>
                        <option value='Guinea-Bissau'>Guinea-Bissau</option>
                        <option value='Guyana'>Guyana</option>
                        <option value='Haiti'>Haiti</option>
                        <option value='Honduras'>Honduras</option>
                        <option value='Hong Kong'>Hong Kong</option>
                        <option value='Hungary'>Hungary</option>
                        <option value='Iceland'>Iceland</option>
                        <option value='India'>India</option>
                        <option value='Indonesia'>Indonesia</option>
                        <option value='Iran'>Iran</option>
                        <option value='Iraq'>Iraq</option>
                        <option value='Ireland'>Ireland</option>
                        <option value='Israel'>Israel</option>
                        <option value='Italy'>Italy</option>
                        <option value='Jamaica'>Jamaica</option>
                        <option value='Japan'>Japan</option>
                        <option value='Jordan'>Jordan</option>
                        <option value='Kazakhstan'>Kazakhstan</option>
                        <option value='Kenya'>Kenya</option>
                        <option value='Kiribati'>Kiribati</option>
                        <option value='Korea, D.P.R. of'>
                          Korea, D.P.R. of
                        </option>
                        <option value='Korea, Republic of'>
                          Korea, Republic of
                        </option>
                        <option value='Kuwait'>Kuwait</option>
                        <option value='Kyrgyzstan'>Kyrgyzstan</option>
                        <option value="Lao, People's Dem. Rep.">
                          Lao, People's Dem. Rep.
                        </option>
                        <option value='Latvia'>Latvia</option>
                        <option value='Lebanon'>Lebanon</option>
                        <option value='Lesotho'>Lesotho</option>
                        <option value='Liberia'>Liberia</option>
                        <option value='Libyan Arab Jamahiriya'>
                          Libyan Arab Jamahiriya
                        </option>
                        <option value='Liechtenstein'>Liechtenstein</option>
                        <option value='Lithuania'>Lithuania</option>
                        <option value='Luxembourg'>Luxembourg</option>
                        <option value='Macao'>Macao</option>
                        <option value='Madagascar'>Madagascar</option>
                        <option value='Malawi'>Malawi</option>
                        <option value='Malaysia'>Malaysia</option>
                        <option value='Maldives'>Maldives</option>
                        <option value='Mali'>Mali</option>
                        <option value='Malta'>Malta</option>
                        <option value='Marshall Islands'>
                          Marshall Islands
                        </option>
                        <option value='Martinique'>Martinique</option>
                        <option value='Mauritania'>Mauritania</option>
                        <option value='Mauritius'>Mauritius</option>
                        <option value='Mexico'>Mexico</option>
                        <option value='Micronesia'>Micronesia</option>
                        <option value='Moldova, Republic of'>
                          Moldova, Republic of
                        </option>
                        <option value='Monaco'>Monaco</option>
                        <option value='Mongolia'>Mongolia</option>
                        <option value='Montenegro'>Montenegro</option>
                        <option value='Montserrat'>Montserrat</option>
                        <option value='Morocco'>Morocco</option>
                        <option value='Mozambique'>Mozambique</option>
                        <option value='Myanmar'>Myanmar</option>
                        <option value='Namibia'>Namibia</option>
                        <option value='Nauru'>Nauru</option>
                        <option value='Nepal'>Nepal</option>
                        <option value='Netherlands'>Netherlands</option>
                        <option value='Netherlands Antilles'>
                          Netherlands Antilles
                        </option>
                        <option value='New Caledonia'>New Caledonia</option>
                        <option value='New Zealand'>New Zealand</option>
                        <option value='Nicaragua'>Nicaragua</option>
                        <option value='Niger'>Niger</option>
                        <option value='Nigeria'>Nigeria</option>
                        <option value='Niue'>Niue</option>
                        <option value='North Macedonia, Rep. of'>
                          North Macedonia, Rep. of
                        </option>
                        <option value='Northern Mariana Islands'>
                          Northern Mariana Islands
                        </option>
                        <option value='Norway'>Norway</option>
                        <option value='Oman'>Oman</option>
                        <option value='Pakistan'>Pakistan</option>
                        <option value='Palau, Republic of'>
                          Palau, Republic of
                        </option>
                        <option value='Panama'>Panama</option>
                        <option value='Papua New Guinea'>
                          Papua New Guinea
                        </option>
                        <option value='Paraguay'>Paraguay</option>
                        <option value='Peru'>Peru</option>
                        <option value='Philippines'>Philippines</option>
                        <option value='Poland'>Poland</option>
                        <option value='Portugal'>Portugal</option>
                        <option value='Puerto Rico'>Puerto Rico</option>
                        <option value='Qatar'>Qatar</option>
                        <option value='Reunion'>Reunion</option>
                        <option value='Romania'>Romania</option>
                        <option value='Russian Federation'>
                          Russian Federation
                        </option>
                        <option value='Rwanda'>Rwanda</option>
                        <option value='Saint Helena'>Saint Helena</option>
                        <option value='Saint Kitts and Nevis'>
                          Saint Kitts and Nevis
                        </option>
                        <option value='Saint Lucia'>Saint Lucia</option>
                        <option value='Samoa'>Samoa</option>
                        <option value='San Marino'>San Marino</option>
                        <option value='Sao Tome and Principe'>
                          Sao Tome and Principe
                        </option>
                        <option value='Saudi Arabia'>Saudi Arabia</option>
                        <option value='Senegal'>Senegal</option>
                        <option value='Serbia'>Serbia</option>
                        <option value='Seychelles'>Seychelles</option>
                        <option value='Sierra Leone'>Sierra Leone</option>
                        <option value='Singapore'>Singapore</option>
                        <option value='Slovakia'>Slovakia</option>
                        <option value='Slovenia'>Slovenia</option>
                        <option value='Solomon Islands'>Solomon Islands</option>
                        <option value='Somalia'>Somalia</option>
                        <option value='South Africa'>South Africa</option>
                        <option value='South Sudan'>South Sudan</option>
                        <option value='Spain'>Spain</option>
                        <option value='Sri Lanka'>Sri Lanka</option>
                        <option value='St. Vincent and the Grena'>
                          St. Vincent and the Grena
                        </option>
                        <option value='Sudan'>Sudan</option>
                        <option value='Suriname'>Suriname</option>
                        <option value='Sweden'>Sweden</option>
                        <option value='Switzerland'>Switzerland</option>
                        <option value='Syrian Arab Republic'>
                          Syrian Arab Republic
                        </option>
                        <option value='Tajikistan'>Tajikistan</option>
                        <option value='Tanzania, United Rep. of'>
                          Tanzania, United Rep. of
                        </option>
                        <option value='Thailand'>Thailand</option>
                        <option value='Togo'>Togo</option>
                        <option value='Tokelau Islands'>Tokelau Islands</option>
                        <option value='Tonga'>Tonga</option>
                        <option value='Trinidad and Tobago'>
                          Trinidad and Tobago
                        </option>
                        <option value='Tunisia'>Tunisia</option>
                        <option value='Turkey'>Turkey</option>
                        <option value='Turkmenistan'>Turkmenistan</option>
                        <option value='Turks and Caicos Island'>
                          Turks and Caicos Island
                        </option>
                        <option value='Tuvalu'>Tuvalu</option>
                        <option value='Uganda'>Uganda</option>
                        <option value='Ukraine'>Ukraine</option>
                        <option value='United Arab Emirates'>
                          United Arab Emirates
                        </option>
                        <option value='United Kingdom'>United Kingdom</option>
                        <option value='United States of America'>
                          United States of America
                        </option>
                        <option value='Uruguay'>Uruguay</option>
                        <option value='Uzbekistan'>Uzbekistan</option>
                        <option value='Vanuatu'>Vanuatu</option>
                        <option value='Venezuela'>Venezuela</option>
                        <option value='Viet Nam'>Viet Nam</option>
                        <option value='Virgin Islands (UK)'>
                          Virgin Islands (UK)
                        </option>
                        <option value='Virgin Islands (USA)'>
                          Virgin Islands (USA)
                        </option>
                        <option value='Wallis and Futuna Islands'>
                          Wallis and Futuna Islands
                        </option>
                        <option value='Yemen, Republic of'>
                          Yemen, Republic of
                        </option>
                        <option value='Zambia'>Zambia</option>
                        <option value='Zimbabwe'>Zimbabwe</option>
                      </Select>
                    </div>

                    <div className='h5'>Industry</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The Industry sector in which the factory operates
                    </div>
                    <div className='h11 width90 select margTop16 gallery-bg flex alignItemsCenter borderSelect margBot32'>
                      <Select
                        inputClass='h11'
                        className='width100 gallery-bg'
                        value={this.state.companyDetails.industry}
                        onChange={event => {
                          companyDetails.industry = event.target.value
                          if (event.target.value === 'Textile') {
                            this.setState({
                              productionTargetText:
                                'The daily/ annual production target set using the Standard Allowed Minutes (SAM).'
                            })
                          } else {
                            this.setState({
                              productionTargetText:
                                'The number of final goods/ products produced in the factory daily/ annually.'
                            })
                          }
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      >
                        <option value=''>Select Industry</option>
                        <option value='Textile'>Textile</option>
                        <option value='Manufacturing'>Manufacturing</option>
                        <option value='Assembly'>Assembly</option>
                        <option value='Food & Consumer goods'>
                          Food & Consumer goods
                        </option>
                        <option value='Print & Packaging'>
                          Print & Packaging
                        </option>
                        <option value='Others'>Others</option>
                      </Select>
                    </div>
                  </div>
                </div>

                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>PRODUCTION DETAILS</div>
                    <div className='h7'>
                      Some health programmes have an impact on productivity.
                      Production Details are required to project the increase in
                      productivity post-programme.
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Annual working days</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The number of working days in the factory per year,
                      excluding the festive, government and weekly holidays
                      (Minimum 90 days)
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          this.checkMax(
                            event.target.value,
                            364,
                            'annualWorkingDays',
                            'The number of working days in the factory per year, excluding the festive, government and weekly holidays should be less than 365 days'
                          )
                        }}
                        onBlur={event => {
                          this.checkMin(
                            event.target.value,
                            90,
                            'annualWorkingDays',
                            'The number of working days in the factory per year, excluding the festive, government and weekly holidays should be a minimum of 90 days'
                          )
                        }}
                        placeholder=''
                        value={this.state.companyDetails.annualWorkingDays}
                        onChange={event => {
                          companyDetails.annualWorkingDays = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>

                    <div className='h5'>
                      Average Number of working hours per day
                    </div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The number of hours that the factory operates everyday
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter borderSelect margBot32'>
                      <Select
                        className='h11 width100 gallery-bg'
                        value={this.state.companyDetails.avgWorkingHours}
                        onChange={event => {
                          companyDetails.avgWorkingHours = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      >
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                        <option value='13'>13</option>
                        <option value='14'>14</option>
                        <option value='15'>15</option>
                        <option value='16'>16</option>
                      </Select>
                    </div>

                    <div className='h5'>Production Target</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      {this.state.productionTargetText}
                    </div>
                    <div className='h11 width90 margTop16'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) < 0) {
                            companyDetails.productionTarget = ''
                            this.setState({ companyDetails: companyDetails })
                            this.checkProceedActive()
                          }
                        }}
                        onBlur={event => {
                          this.checkMin(
                            event.target.value,
                            1,
                            'productionTarget',
                            'Production target should be greater than 0'
                          )
                        }}
                        placeholder=''
                        value={this.state.companyDetails.productionTarget}
                        onChange={event => {
                          companyDetails.productionTarget = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                    <div className='h11 width90 margTop16 flex md-radio-container'>
                      <RadioButton
                        id='production-target-daily'
                        className='h11 gallery-bg'
                        name='radTarget'
                        value='daily'
                        checked={
                          companyDetails.productionTargetType === '' ||
                          companyDetails.productionTargetType === 'daily'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='production-target-daily'>
                        Daily Production Target
                      </label>
                    </div>
                    <div className='h11 md-radio-container width90 margTop16 flex margBot32'>
                      <RadioButton
                        id='production-target-annual'
                        className='h11 gallery-bg'
                        name='radTarget'
                        value='annual'
                        checked={
                          companyDetails.productionTargetType === 'annual'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='production-target-annual'>
                        Annual Production Target
                      </label>
                    </div>
                  </div>
                </div>

                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>WORKFORCE DETAILS</div>
                    <div className='h7'>
                      Input the details of your beneficiary workforce. This tool
                      is working on the assumption that all of your eligible
                      workforce will be receiving the intervention.
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Total number of workers</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The number of workers in the factory. (We assume that all
                      eligible workers in the factory/company will receive the
                      intervention)
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) < 0) {
                            companyDetails.noOfWorkers = ''
                            this.setState({ companyDetails: companyDetails })
                            this.checkProceedActive()
                          }
                        }}
                        onBlur={event => {
                          this.checkMin(
                            event.target.value,
                            1,
                            'noOfWorkers',
                            'No of workers should be greater than 0'
                          )
                        }}
                        placeholder=''
                        value={this.state.companyDetails.noOfWorkers}
                        onChange={event => {
                          companyDetails.noOfWorkers = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Total number of women workers</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The number of women workers in the factory who will
                      receive the intervention in the factory. (We assume that
                      all eligible women workers aged 18-50 in the
                      factory/company will receive the intervention)
                    </div>
                    <div className='h11 width90 margTop16 margBot32'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (
                            parseInt(event.target.value) < 0 ||
                            parseInt(event.target.value) >
                              parseInt(this.state.companyDetails.noOfWorkers)
                          ) {
                            companyDetails.womenWorkers = ''
                            this.setState({
                              companyDetails: companyDetails,
                              showLimit: true,
                              showLimitText:
                                'The number of women workers should be less than the total number of workers.'
                            })
                            this.checkProceedActive()
                          }
                        }}
                        onBlur={event => {
                          this.checkMin(
                            event.target.value,
                            1,
                            'womenWorkers',
                            'No of women workers should be greater than 0'
                          )
                        }}
                        placeholder=''
                        value={this.state.companyDetails.womenWorkers}
                        onChange={event => {
                          companyDetails.womenWorkers = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Wage Expenditure</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The total monthly/ annual expenditure incurred by the
                      company on wages for the factory workers (Please input the
                      wage expenditure only for the total number of workers you
                      mentioned in the company baseline form)
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder'>
                      <div className='h11'>
                        {this.state.companyDetails.currency}
                      </div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='text'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          var num = this.getNumber(event.target.value)
                          if (num === 0) {
                            companyDetails.wageBillDisp = ''
                          } else {
                            companyDetails.wageBillDisp = num.toLocaleString()
                          }

                          if (parseInt(event.target.value) < 0) {
                            companyDetails.wageBillDisp = ''
                          }

                          companyDetails.wageBill = companyDetails.wageBillDisp.replaceAll(
                            ',',
                            ''
                          )

                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                        onBlur={event => {
                          this.checkMin(
                            event.target.value,
                            1,
                            'wageBill',
                            'Wage Expenditure should be greater than 0'
                          )
                          if (
                            parseInt(this.state.companyDetails.wageBill) <
                            parseInt(this.state.companyDetails.womenWorkers)
                          ) {
                            companyDetails.wageBillDisp = ''
                            this.setState({
                              companyDetails: companyDetails,
                              showLimit: true,
                              showLimitText:
                                'The monthly/ annual wage expenditure should be more than the number of women workers.'
                            })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={this.state.companyDetails.wageBillDisp}
                        onChange={event => {
                          companyDetails.wageBillDisp = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                    <div className='h11 md-radio-container width90 margTop16 flex margBot32'>
                      <RadioButton
                        id='way-bill-month'
                        className='h11 gallery-bg'
                        name='radWorkforce'
                        checked={
                          companyDetails.wageBillType === '' ||
                          companyDetails.wageBillType === 'monthly'
                            ? true
                            : false
                        }
                        value='monthly'
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label
                        htmlFor='way-bill-month'
                        style={{ marginRight: '16px' }}
                      >
                        Monthly
                      </label>
                      <RadioButton
                        id='way-bill-annual'
                        className='h11 gallery-bg margLeft16'
                        name='radWorkforce'
                        value='annual'
                        checked={
                          companyDetails.wageBillType === 'annual'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='way-bill-annual'>Annual</label>
                    </div>
                  </div>
                </div>

                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>ABSENTEEISM</div>
                    <div className='h7'>
                      The average number of leave days taken with/ without
                      permission by one worker monthly/ annually (Do not include
                      maternity leave and other legal leave days in this
                      calculation)
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Number of Absent days</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The average number of leave days taken per worker
                    </div>
                    <div className='h11 width90 margTop16'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (
                            parseInt(event.target.value) < 0 ||
                            parseInt(event.target.value) >
                              parseInt(
                                this.state.companyDetails.annualWorkingDays
                              )
                          ) {
                            companyDetails.absenteeism = ''
                            this.setState({
                              companyDetails: companyDetails,
                              showLimit: true,
                              showLimitText:
                                'The number of absenteeism days per worker should be less than total number of working days in a year.'
                            })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={this.state.companyDetails.absenteeism}
                        onChange={event => {
                          companyDetails.absenteeism = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                    <div className='h11 md-radio-container width90 margTop16 flex margBot32'>
                      <RadioButton
                        id='absentism-monthly'
                        className='h11 gallery-bg'
                        name='radAb'
                        type='radio'
                        checked={
                          companyDetails.absenteeismType === '' ||
                          companyDetails.absenteeismType === 'monthly'
                            ? true
                            : false
                        }
                        value='monthly'
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label
                        htmlFor='absentism-monthly'
                        style={{ marginRight: '16px' }}
                      >
                        Monthly
                      </label>
                      <RadioButton
                        id='absentism-annualy'
                        className='h11 gallery-bg margLeft16'
                        name='radAb'
                        type='radio'
                        value='annual'
                        checked={
                          companyDetails.absenteeismType === 'annual'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='absentism-annualy'>Annual</label>
                    </div>
                  </div>
                </div>
                <br />
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>LATE COMING</div>
                    <div className='h7'>
                      The total sum of hours monthly/ annually that your workers
                      are late to work without prior permission
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Hours lost due to late coming</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The total number of hours lost to late coming in your
                      factory/ unit
                    </div>
                    <div className='h11 width90 margTop16'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          let lateVal =
                            parseInt(companyDetails.noOfWorkers) *
                            parseInt(companyDetails.avgWorkingHours) *
                            parseInt(companyDetails.annualWorkingDays)
                          let showLT =
                            'The average total number of late hours anually should be less than Total number of workers * Annual number of working days per month * Number of daily working hours'
                          if (
                            this.state.companyDetails.lateComingType ===
                            'monthly'
                          ) {
                            lateVal = lateVal / 12
                            showLT =
                              'The average total number of late hours per month should be less than Total number of workers * Number of working days per month * Number of daily working hours'
                          }
                          if (
                            parseInt(event.target.value) < 0 ||
                            parseInt(event.target.value) > lateVal
                          ) {
                            companyDetails.lateComingHours = ''
                            this.setState({
                              companyDetails: companyDetails,
                              showLimit: true,
                              showLimitText: showLT
                            })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={this.state.companyDetails.lateComingHours}
                        onChange={event => {
                          companyDetails.lateComingHours = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                    <div className='h11 md-radio-container width90 margTop16 flex margBot32'>
                      <RadioButton
                        id='radio-latecomming-monthly'
                        className='h11 gallery-bg'
                        name='radLC'
                        checked={
                          companyDetails.lateComingType === '' ||
                          companyDetails.lateComingType === 'monthly'
                            ? true
                            : false
                        }
                        value='monthly'
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label
                        htmlFor='radio-latecomming-monthly'
                        style={{ marginRight: '16px' }}
                      >
                        Monthly
                      </label>
                      <RadioButton
                        id='radio-latecomming-annualy'
                        className='h11 gallery-bg margLeft16'
                        name='radLC'
                        value='annual'
                        checked={
                          companyDetails.lateComingType === 'annual'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='radio-latecomming-annualy'>Annual</label>
                    </div>
                  </div>
                </div>
                <br />
                <div className='flex left-txt'>
                  <div className='width40 margRight24'>
                    <div className='h4 pacificBlue-txt'>WORKER TURNOVER</div>
                    <div className='h7 margRight24'>
                      Details about workers who leave the job and the cost heads
                      for new recruitment
                    </div>
                  </div>
                  <div className='width60'>
                    <div className='h5'>Worker Turnover</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The number of workers who leave their job every month/
                      year.
                    </div>
                    <div className='h11 md-radio-container width90 margTop16'>
                      <input
                        className='h11 width96 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (
                            parseInt(event.target.value) < 0 ||
                            parseInt(event.target.value) >
                              parseInt(this.state.companyDetails.noOfWorkers)
                          ) {
                            companyDetails.workerTurnover = ''
                            this.setState({
                              companyDetails: companyDetails,
                              showLimit: true,
                              showLimitText:
                                'The number of worker turnover anually/monthly should be less than number of workers.'
                            })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={this.state.companyDetails.workerTurnover}
                        onChange={event => {
                          companyDetails.workerTurnover = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                    <div className='h11 md-radio-container width90 margTop16 flex margBot32'>
                      <RadioButton
                        className='h11 gallery-bg'
                        id='work-turnover-monthly'
                        name='radTurnover'
                        checked={
                          companyDetails.workerTurnoverType === '' ||
                          companyDetails.workerTurnoverType === 'monthly'
                            ? true
                            : false
                        }
                        value='monthly'
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label
                        htmlFor='work-turnover-monthly'
                        style={{ marginRight: '16px' }}
                      >
                        Monthly
                      </label>
                      <RadioButton
                        id='work-turnover-annualy'
                        className='h11 gallery-bg margLeft16'
                        name='radTurnover'
                        value='annual'
                        checked={
                          companyDetails.workerTurnoverType === 'annual'
                            ? true
                            : false
                        }
                        onChange={() => {
                          this.checkProceedActive()
                        }}
                      />
                      <label htmlFor='work-turnover-annualy'>Annual</label>
                    </div>

                    <div className='h5'>Training Cost of New Worker</div>
                    <div className='h13 margTop8 matterhorn-txt'>
                      The training costs incurred post recruitment of a new
                      worker
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>
                        {this.state.companyDetails.currency}
                      </div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) < 0) {
                            companyDetails.trainingCostOfNewWorker = ''
                            this.setState({ companyDetails: companyDetails })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={
                          this.state.companyDetails.trainingCostOfNewWorker
                        }
                        onChange={event => {
                          companyDetails.trainingCostOfNewWorker =
                            event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Average Recruitment Cost</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      The average cost to the factory/company of recruiting a
                      worker (include cost of manpower agency, worker
                      mobilisation etc.)
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>
                        {this.state.companyDetails.currency}
                      </div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                          if (parseInt(event.target.value) < 0) {
                            companyDetails.avgRecruitmentCost = ''
                            this.setState({ companyDetails: companyDetails })
                            this.checkProceedActive()
                          }
                        }}
                        placeholder=''
                        value={this.state.companyDetails.avgRecruitmentCost}
                        onChange={event => {
                          companyDetails.avgRecruitmentCost = event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>

                    <div className='h5'>Misc. Recruitment Expenditure</div>
                    <div className='h13 margTop8 matterhorn-txt width96'>
                      Any additional cost involved in replacing/hiring worker,
                      apart from the training and recruitment cost (including
                      administrative, stationery and membership costs incurred
                      during recruitment)
                    </div>
                    <div className='h11 width90 margTop16 gallery-bg flex alignItemsCenter currencyBorder margBot32'>
                      <div className='h11'>
                        {this.state.companyDetails.currency}
                      </div>
                      <input
                        className='h11 width90 gallery-bg'
                        type='number'
                        onClick={event => {
                          if (event.target.value === '0') {
                            event.target.value = ''
                          }
                        }}
                        onKeyUp={event => {
                          if (event.key === '-') {
                            event.target.value = event.target.value.replace('-','')
                            this.rangeError()
                          }
                        }}
                        placeholder=''
                        value={
                          this.state.companyDetails.miscRecruitmentExpenditure
                        }
                        onChange={event => {
                          companyDetails.miscRecruitmentExpenditure =
                            event.target.value
                          this.setState({ companyDetails: companyDetails })
                          this.checkProceedActive()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default CompanyView
