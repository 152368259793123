//eslint-disable-next-line
import React, { Component, useState } from 'react'
import Header from '../../../../components/Header/Header'
import './RoiView.scss'
import '../../../../indexroi.scss'
import roiBar from '../../../../assets/images/roi/roiBar.svg'
import infoBtn from '../../../../assets/images/roi/infoBtn.svg'
import infoBtnWhite from '../../../../assets/images/roi/infoBtnWhite.svg'
import addNow from '../../../../assets/images/roi/addNow.svg'
import close from '../../../../assets/images/roi/close.svg'
import exportBtn from '../../../../assets/images/roi/exportBtn.svg'
import exportb from '../../../../assets/images/roi/exportb.svg'
import cancel from '../../../../assets/images/roi/cancel.svg'
import ok from '../../../../assets/images/roi/ok.svg'
import addProgBtn from '../../../../assets/images/roi/addProgBtn.svg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import closeBtn from '../../../../assets/images/close2.png'
import { saveAs } from 'file-saver'
import spin from '../../../../assets/images/roi/spin.svg'
import { Checkbox } from '../../../../components/MaterialInput'
import back from '../../../../assets/images/back.svg'

class RoiView extends Component {
  constructor (props) {
    super(props)
    let local = JSON.parse(localStorage.getItem('udgbl'))
    let currProg = localStorage.getItem('currentProg')
    let coDet = local.roiData[currProg].companyDetails

    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    )

    this.state = {
      apiUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
      showAddPopup: false,
      showBundlePopup: false,
      bundlePopupName: '',
      showChartPopup: false,
      showDeletePopup: false,
      showGenerateReportPopup: false,
      showExportReportPopup: false,
      reportPopupText: 'Generating Report...',
      deleteBundle: -1,
      chartHeader: '',
      chartSavings: '',
      chartTotalCost: '',
      chartProjRoi: '',
      local: local,
      reportData: {
        projection: {
          reportFor: local.roiData[currProg].forWho,
          factoryName: local.roiData[currProg].projectionName,
          department: ''
        },
        programs: []
      },
      forWho: local.roiData[currProg].forWho,
      currency: local.roiData[currProg].companyDetails.currency,
      currencyValue: local.roiData[currProg].companyDetails.currencyValue,
      progNo: 0,
      programmes: JSON.parse(localStorage.getItem('programmes')),
      companyDetails: coDet,
      absenteeismActual:
        coDet.absenteeismType === 'annual'
          ? parseInt(coDet.absenteeism)
          : parseInt(coDet.absenteeism) * 12,
      lateComingActual:
        coDet.lateComingType === 'annual'
          ? parseInt(coDet.lateComingHours)
          : parseInt(coDet.lateComingHours) * 12,
      workerTurnoverActual:
        coDet.workerTurnoverType === 'annual'
          ? parseInt(coDet.workerTurnover)
          : parseInt(coDet.workerTurnover) * 12,
      productionTargetActual:
        coDet.productionTargetType === 'annual'
          ? parseInt(coDet.productionTarget)
          : parseInt(coDet.productionTarget) *
            parseInt(coDet.annualWorkingDays),
      currProjectionId: currProg,
      chartType: 'single',
      chartData1: {},
      chartData2: {},
      chartData3: {},
      chartData4: {},
      chartData5: {},
      chartItem: {},
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  }

  deletebundle = () => {
    let local = this.state.local
    local.roiData[this.state.currProjectionId].bundleDetails.splice(
      this.state.deleteBundle,
      1
    )
    var data = JSON.stringify({
      roiData: local.roiData
    })
    localStorage.setItem('udgbl', JSON.stringify(local))
    this.setState({ showDeletePopup: false, local: local })
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.addEventListener('readystatechange', function () {})
    xhr.open(
      'POST',
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL +
        '/api/user/updateROI/' +
        local.id
    )
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.setRequestHeader('cache-control', 'no-cache')
    xhr.setRequestHeader(
      'postman-token',
      '2c67f2e9-cf05-f0a3-4c2d-f25904dc6e2c'
    )
    xhr.send(data)
  }

  prepReportData = (type, item, itemId) => {
    let coDet = this.state.companyDetails
    let absenteeismActual = this.state.absenteeismActual
    let workerTurnoverActual = this.state.workerTurnoverActual
    let lateComingActual = this.state.lateComingActual
    let productionTargetActual = this.state.productionTargetActual
    let program = this.state.programmes.filter(function (itm) {
      return itm.programmeDetails.programNo === item.programNo
    })

    let savings = item.savingsFromIntervention
      ? parseInt(item.savingsFromIntervention)
      : this.average(
          item.savingsFromInterventionUB,
          item.savingsFromInterventionLB
        )
    let anRoi =
      item.annualRoi >= 0
        ? parseFloat(item.annualRoi)
        : this.average(item.annualRoiUB, item.annualRoiLB)

    let itemsSavings = []
    let costSavings = []
    let impactItems = []

    if (item.percentAbsent || item.percentAbsentLB) {
      itemsSavings.push({
        title: 'Savings from Absentism',
        label: 'Absenteeism',
        value: {
          upperbound: item.costSavingsAbsenteeismUB
            ? item.costSavingsAbsenteeismUB.toFixed(2)
            : 'NA',
          lowerbound: item.costSavingsAbsenteeismLB
            ? item.costSavingsAbsenteeismLB.toFixed(2)
            : 'NA',
          average: item.costSavingsAbsenteeismLB
            ? this.average(
                item.costSavingsAbsenteeismLB,
                item.costSavingsAbsenteeismUB
              ).toFixed(2)
            : item.costSavingsAbsenteeism.toFixed(2)
        }
      })
      let savingsInlocal =
        (item.costSavingsAbsenteeism && item.costSavingsAbsenteeism) === 0
          ? item.costSavingsAbsenteeism
          : item.costSavingsAbsenteeism
          ? item.costSavingsAbsenteeism
          : this.average(
              item.costSavingsAbsenteeismUB,
              item.costSavingsAbsenteeismLB
            )
      impactItems.push({
        showGraph: true,
        title: 'Absenteeism',
        subTitle: 'Annual absenteeism days/worker',
        present: absenteeismActual,
        postProgram: {
          upperbound: item.absentNoUB
            ? Math.round(absenteeismActual) - Math.round(item.absentNoUB)
            : 'NA',
          lowerbound: item.absentNoLB
            ? Math.round(absenteeismActual) - Math.round(item.absentNoLB)
            : 'NA',
          average: item.absentNoLB
            ? (
                Math.round(absenteeismActual) -
                this.average(item.absentNoLB, item.absentNoUB)
              ).toFixed(2)
            : Math.round(absenteeismActual) - Math.round(item.absentNo)
        },
        impactItem: {
          name: 'Reduction in absenteeism',
          value:
            (item.percentAbsentUB
              ? item.percentAbsentLB + '-' + item.percentAbsentUB
              : item.percentAbsent) + '%'
        },
        totalSavingsInLocalCurrency: savingsInlocal.toFixed(2),
        totalSavingsInDollars: (
          parseFloat(savingsInlocal) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      })
    }

    if (item.percentLateComing) {
      itemsSavings.push({
        title: 'Savings from Late Coming',
        label: 'Late Coming',
        value: {
          upperbound: 'NA',
          lowerbound: 'NA',
          average: item.costSavingsLateComing.toFixed(2)
        }
      })
      let savingsInlocal = item.costSavingsLateComing
      impactItems.push({
        showGraph: true,
        title: 'Late Coming',
        subTitle: ' Annual late coming',
        present: lateComingActual,
        postProgram: {
          upperbound: 'NA',
          lowerbound: 'NA',
          average: Math.round(
            Math.round(lateComingActual) - 0.62 * Math.round(lateComingActual)
          ).toString()
        },
        impactItem: {
          name: 'Reduction in Late Coming',
          value: item.percentLateComing + '%'
        },
        totalSavingsInLocalCurrency: savingsInlocal.toFixed(2),
        totalSavingsInDollars: (
          parseFloat(savingsInlocal) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      })
    }

    if (item.percentAttendance) {
      itemsSavings.push({
        title: 'Savings from Attendance',
        label: 'Attendance',
        value: {
          upperbound: 'NA',
          lowerbound: 'NA',
          average: item.costSavingsAttendance.toFixed(2)
        }
      })
      let savingsInlocal = item.costSavingsAttendance
      impactItems.push({
        showGraph: false,
        title: 'Attendance',
        subTitle: ' Annual attendance',
        impactItem: [
          {
            name: 'Monthly increase in working hours',
            value: item.attendanceNo.toFixed(2)
          },
          {
            name: 'Annual increase in working hours',
            value: item.percentAttendance + ' Hours'
          }
        ],
        totalSavingsInLocalCurrency: savingsInlocal.toFixed(2),
        totalSavingsInDollars: (
          parseFloat(savingsInlocal) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      })
    }

    if (item.percentWorkerTurnover || item.percentWorkerTurnoverLB) {
      itemsSavings.push({
        title: 'Savings from Worker Turnover',
        label: 'Worker Turnover',
        value: {
          upperbound: item.costSavingsWorkerTurnoverUB
            ? item.costSavingsWorkerTurnoverUB.toFixed(2)
            : 'NA',
          lowerbound: item.costSavingsWorkerTurnoverLB
            ? item.costSavingsWorkerTurnoverLB.toFixed(2)
            : 'NA',
          average: item.costSavingsWorkerTurnoverLB
            ? this.average(
                item.costSavingsWorkerTurnoverLB,
                item.costSavingsWorkerTurnoverUB
              ).toFixed(2)
            : item.costSavingsWorkerTurnover.toFixed(2)
        }
      })
      let savingsInlocal =
        (item.costSavingsWorkerTurnover && item.costSavingsWorkerTurnover) === 0
          ? item.costSavingsWorkerTurnover
          : item.costSavingsWorkerTurnoverUB
          ? this.average(
              item.costSavingsWorkerTurnoverUB,
              item.costSavingsWorkerTurnoverLB
            )
          : item.costSavingsWorkerTurnover
      impactItems.push({
        showGraph: true,
        title: 'Worker Turnover',
        subTitle: 'Annual turnover',
        present: workerTurnoverActual,
        postProgram: {
          upperbound: item.workerTurnoverUB
            ? Math.round(workerTurnoverActual) -
              Math.round(item.workerTurnoverUB)
            : 'NA',
          lowerbound: item.workerTurnoverLB
            ? Math.round(workerTurnoverActual) -
              Math.round(item.workerTurnoverLB)
            : 'NA',
          average: item.workerTurnoverLB
            ? (
                Math.round(workerTurnoverActual) -
                this.average(item.workerTurnoverLB, item.workerTurnoverUB)
              ).toFixed(2)
            : Math.round(workerTurnoverActual) - Math.round(item.workerTurnover)
        },
        impactItem: {
          name: 'Reduction in worker turnover',
          value: item.percentWorkerTurnover
            ? item.percentWorkerTurnover + '%'
            : item.percentWorkerTurnoverLB +
              '% - ' +
              item.percentWorkerTurnoverUB +
              '%'
        },
        totalSavingsInLocalCurrency: savingsInlocal.toFixed(2),
        totalSavingsInDollars: (
          parseFloat(savingsInlocal) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      })
    }

    if (item.percentProductivity) {
      itemsSavings.push({
        title: 'Savings from Productivity',
        label: 'Productivity',
        value: {
          upperbound: 'NA',
          lowerbound: 'NA',
          average: item.costSavingsProductivity.toFixed(2)
        }
      })
      let savingsInlocal = item.costSavingsProductivity
      impactItems.push({
        showGraph: true,
        title: 'Productivity',
        subTitle: ' Annual productivity',
        present: productionTargetActual,
        postProgram: {
          upperbound: 'NA',
          lowerbound: 'NA',
          average:
            parseInt(productionTargetActual) +
            parseInt(item.annualChangeInProductivity)
        },
        impactItem: {
          name: 'Increase in productivity',
          value: item.percentProductivity + '%'
        },
        totalSavingsInLocalCurrency: savingsInlocal.toFixed(2),
        totalSavingsInDollars: (
          parseFloat(savingsInlocal) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      })
    }

    if (type === 'single') {
      if (item.programNo === 0) {
        costSavings.push(
          { title: 'Cost of 1 Pad', value: item.costPerPad },
          {
            title: 'Annual pad cost per employee',
            value: (300 * Math.round(item.costPerPad)).toFixed(2)
          },
          {
            title: '% of cost per employee',
            value: item.percentageCoveredByCompany
          },
          { title: 'Misc Admin Cost', value: item.miscAdminCost }
        )
      } else if (
        item.programNo === 1 ||
        item.programNo === 6 ||
        item.programNo === 2
      ) {
        costSavings.push(
          { title: 'Total hours of training', value: item.hoursOfTraining },
          { title: 'Training cost per hr', value: item.hourlyTrainingCost },
          {
            title: 'Total stationery cost',
            value: item.totalStationeryCost
          },
          { title: 'Cost of refreshment', value: item.costOfRefreshment },
          {
            title: 'Cost of loss of productivity',
            value: parseFloat(item.lossOfProductivityFromTraining).toFixed(2)
          },
          { title: 'Misc Admin Cost', value: item.miscAdminCost }
        )
      } else if (item.programNo === 3) {
        costSavings.push(
          {
            title: 'Health personal salary',
            value: item.healthPersonnelSalary
          },
          { title: 'Transport cost', value: item.transportCost },
          { title: 'Medical consumables', value: item.medicalConsumables },
          { title: 'Misc expenses', value: item.miscExpenses }
        )
      } else if (item.programNo === 4) {
        costSavings.push(
          {
            title: 'One time construction cost of clinic',
            value: item.oneTimeConstructionCost
          },
          {
            title: 'Annual medicine inventory cost',
            value: item.annualMedicineInventoryCost
          },
          {
            title: 'Medical instrument cost',
            value: item.medicalInstrumentCost
          }
        )
      } else if (item.programNo === 5) {
        costSavings.push(
          { title: 'Total hours of training', value: item.hoursOfTraining },
          { title: 'Training cost per hr', value: item.hourlyTrainingCost },
          {
            title: 'Total stationery cost',
            value: item.totalStationeryCost
          },
          {
            title: 'Testing cost per employee',
            value: item.testingCostPerWorker
          },
          {
            title: 'Average no. of anemic women',
            value: (
              (parseFloat(coDet.ironValue) * parseFloat(coDet.womenWorkers)) /
              100
            ).toFixed(2)
          },
          {
            title: 'Medication cost per employee',
            value: item.medicationCostPerWorker
          },
          { title: 'Misc admin cost', value: item.miscAdminCost }
        )
      }
    } else {
      if (item.costSavingsAbsenteeism || item.costSavingsAbsenteeismLB) {
        item.costSavingsAbsenteeism
          ? costSavings.push({
              title: 'Cost savings absenteeism',
              value: item.costSavingsAbsenteeism.toFixed(2)
            })
          : costSavings.push({
              title: 'Cost savings absenteeism',
              value: this.average(
                item.costSavingsAbsenteeismUB,
                item.costSavingsAbsenteeismLB
              ).toFixed(2)
            })
      }
      if (item.costSavingsAttendance) {
        costSavings.push({
          title: 'Cost savings attendance',
          value: item.costSavingsAttendance.toFixed(2)
        })
      }
      if (item.costSavingsLateComing) {
        costSavings.push({
          title: 'Cost savings late coming',
          value: item.costSavingsLateComing.toFixed(2)
        })
      }
      if (item.costSavingsWorkerTurnover || item.costSavingsWorkerTurnoverLB) {
        item.costSavingsWorkerTurnover
          ? costSavings.push({
              title: 'Cost savings worker turnover',
              value: item.costSavingsWorkerTurnover.toFixed(2)
            })
          : costSavings.push({
              title: 'Cost savings worker turnover',
              value: this.average(
                item.costSavingsWorkerTurnoverUB,
                item.costSavingsWorkerTurnoverLB
              ).toFixed(2)
            })
      }
      if (item.costSavingsProductivity) {
        costSavings.push({
          title: 'Cost savings productivity',
          value: item.costSavingsProductivity.toFixed(2)
        })
      }
    }

    let chartHeader = ''
    this.state.programmes.forEach((itm, i) => {
      if (
        item.programIds &&
        item.programIds.includes(itm.programmeDetails.programNo)
      ) {
        chartHeader += itm.programmeDetails.name + ' , '
      }
    })
    chartHeader = chartHeader.slice(0, -2)

    let programDetail = {
      isBundle: type === 'single' ? false : true,
      programId: 'program' + item.programNo,
      title:
        type === 'single'
          ? program[0].programmeDetails.name
          : 'Bundle ' + itemId,
      description:
        type === 'single'
          ? program[0].programmeDetails.description
          : chartHeader,
      reduceInAbsentism: item.percentAbsent
        ? item.percentAbsent
        : item.percentAbsentUB
        ? this.average(item.percentAbsentLB, item.percentAbsentUB).toFixed(2)
        : '-',
      reductionInWorkerTurnOver: item.percentWorkerTurnover
        ? item.percentWorkerTurnover
        : item.percentWorkerTurnoverUB
        ? this.average(
            item.percentWorkerTurnoverUB,
            item.percentWorkerTurnoverLB
          ).toFixed(2)
        : '-',
      increaseInProductivity: item.percentProductivity
        ? item.percentProductivity
        : '-',
      hourlyIncreaseInAttendance: item.percentAttendance
        ? item.percentAttendance
        : '-',
      reduceInLateComming: item.percentLateComing
        ? item.percentLateComing
        : '-',
      rio:
        this.state.currency +
        anRoi.toFixed(2) +
        '  |  ' +
        (anRoi * 100).toFixed(2) +
        '%',
      overall: {
        total: item.totalCost.toFixed(2),
        currency: this.state.currency,
        inLocalCurrency: anRoi.toFixed(2),
        inDollars: (
          parseFloat(anRoi) / parseFloat(this.state.currencyValue)
        ).toFixed(2),
        percentage: (anRoi * 100).toFixed(2)
      },
      savings: {
        items: itemsSavings,
        inLocalCurrency: savings.toFixed(2),
        inDollars: (
          parseFloat(savings) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      },
      cost: {
        items: costSavings,
        totalCostInLocalCurrency: item.totalCost.toFixed(2),
        totalCostInDollars: (
          parseFloat(item.totalCost) / parseFloat(this.state.currencyValue)
        ).toFixed(2)
      },
      impacts: impactItems
    }
    let reportData = this.state.reportData
    reportData.programs.push(programDetail)
    reportData.currency = this.state.currency
    this.setState({
      reportData: reportData
    })
  }

  exportReport = () => {
    this.state.local.roiData[
      this.state.currProjectionId
    ].programmeDetails.forEach((item, i) => {
      if (item && document.getElementById(i + 'programmeExport').checked) {
        this.prepReportData('single', item, i + 1)
      }
    })
    this.state.local.roiData[this.state.currProjectionId].bundleDetails &&
      this.state.local.roiData[
        this.state.currProjectionId
      ].bundleDetails.forEach((item, i) => {
        if (item && document.getElementById(i + 'bundleExport').checked) {
          this.prepReportData('bundle', item, i + 1)
        }
      })
    var data = JSON.stringify(this.state.reportData)

    // let that = this
    // var xhr = new XMLHttpRequest()
    // xhr.withCredentials = true

    // xhr.addEventListener('readystatechange', function () {
    //   if (this.readyState === 4) {
    //     console.log(this.responseText)
    //     var blob = new Blob([this.response], { type: 'application/pdf' })
    //     saveAs(blob, 'projection.pdf')
    //     that.setState({
    //       reportPopupText: 'Report Generated Now'
    //     })
    //   }
    // })

    // xhr.open(
    //   'POST',
    //   window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/roitool/export-report'
    // )
    // xhr.setRequestHeader('content-type', 'application/json')
    // xhr.setRequestHeader('cache-control', 'no-cache')
    // xhr.setRequestHeader(
    //   'postman-token',
    //   '7d3e4721-542d-a1bc-6133-46cba1ca65e6'
    // )

    // xhr.send(data)

    fetch(this.state.apiUrl + '/api/roitool/export-report', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: data
    })
      .then(res => {
        return res.blob()
      })
      .then(res => {
        var blob = new Blob([res], { type: 'application/pdf' })
        saveAs(blob, 'projection.pdf')
        this.setState({
          reportPopupText: 'Report Generated'
        })
      })
  }

  commaNum = x => {
    x = parseInt(x)
    return x && x !== undefined
      ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : x
  }

  convertToInternationalCurrencySystem = labelValue => {
    labelValue = Math.round(labelValue)
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
      : Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
      : Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
      : Math.abs(Number(labelValue))
  }

  average = (num1, num2) => {
    return (parseFloat(num1) + parseFloat(num2)) / 2
  }

  openBundlePopup = (bundleList, programNo, programName) => {
    localStorage.setItem('bundleFor', programNo)
    localStorage.setItem('bundleList', bundleList.toString())
    this.setState({ showBundlePopup: true, bundlePopupName: programName })
  }

  openChartOpenPopup = (programName, item, type) => {
    let absenteeismActual = this.state.absenteeismActual
    let workerTurnoverActual = this.state.workerTurnoverActual
    let lateComingActual = this.state.lateComingActual
    let productionTargetActual = this.state.productionTargetActual

    let savings = item.savingsFromIntervention
      ? parseInt(item.savingsFromIntervention)
      : this.average(
          item.savingsFromInterventionUB,
          item.savingsFromInterventionLB
        )

    let anRoi =
      item.annualRoi && item.annualRoi >= 0
        ? parseFloat(item.annualRoi)
        : this.average(item.annualRoiUB, item.annualRoiLB)

    let chartData1 =
      item.absentNo || item.absentNoLB
        ? {
            labels: ['At Present', 'Post Program'],
            options: {
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                scales: {
                  xAxes: [{ barPercentage: 0.5 }]
                }
              }
            },
            datasets: [
              {
                type: 'line',
                order: 1,
                backgroundColor: [],
                data: []
              },
              {
                type: 'scatter',
                order: 2,
                backgroundColor: ['#6EC4D7', '#A3A3A3'],
                data: item.absentNoLB
                  ? [
                      {},
                      {
                        x: 0,
                        y: absenteeismActual - Math.round(item.absentNoLB)
                      }
                    ]
                  : []
              },
              {
                type: 'scatter',
                order: 3,
                backgroundColor: ['#A3A3A3', '#535353'],
                data: item.absentNoUB
                  ? [
                      {},
                      {
                        x: 0,
                        y: absenteeismActual - Math.round(item.absentNoUB)
                      }
                    ]
                  : []
              },
              {
                type: 'bar',
                order: 4,
                barThickness: 40,
                backgroundColor: ['#A3A3A3', '#6EC4D7'],
                fill: false,
                data: item.absentNoUB
                  ? [
                      absenteeismActual,
                      absenteeismActual -
                        this.average(item.absentNoUB, item.absentNoLB)
                    ]
                  : [
                      absenteeismActual,
                      absenteeismActual - Math.round(item.absentNo)
                    ]
              }
            ]
          }
        : false

    let chartData2 = item.percentLateComing
      ? {
          labels: ['At Present', 'Post Program'],
          datasets: [
            {
              type: 'bar',
              barThickness: 40,
              backgroundColor: ['#A3A3A3', '#6EC4D7'],
              fill: false,
              data: [
                lateComingActual,
                lateComingActual -
                  parseInt((item.percentLateComing * lateComingActual) / 100)
              ]
            }
          ]
        }
      : false

    let chartData3 = item.percentAttendance ? true : false

    let chartData4 =
      item.percentWorkerTurnover || item.percentWorkerTurnoverLB
        ? {
            labels: ['At Present', 'Post Program'],
            datasets: [
              {
                type: 'line',
                order: 1,
                backgroundColor: [],
                data: []
              },
              {
                type: 'scatter',
                order: 2,
                backgroundColor: ['#A3A3A3', '#A3A3A3'],
                data: item.workerTurnoverLB
                  ? [
                      {},
                      {
                        x: 0,
                        y:
                          workerTurnoverActual -
                          Math.round(item.workerTurnoverLB)
                      }
                    ]
                  : []
              },
              {
                type: 'scatter',
                order: 3,
                backgroundColor: ['#A3A3A3', '#535353'],
                data: item.workerTurnoverUB
                  ? [
                      {},
                      {
                        x: 0,
                        y:
                          workerTurnoverActual -
                          Math.round(item.workerTurnoverUB)
                      }
                    ]
                  : []
              },
              {
                type: 'bar',
                order: 4,
                barThickness: 40,
                backgroundColor: ['#A3A3A3', '#6EC4D7'],
                fill: false,
                data: item.workerTurnoverUB
                  ? [
                      workerTurnoverActual,
                      workerTurnoverActual -
                        this.average(
                          item.workerTurnoverUB,
                          item.workerTurnoverLB
                        )
                    ]
                  : [
                      workerTurnoverActual,
                      workerTurnoverActual - Math.round(item.workerTurnover)
                    ]
              }
            ]
          }
        : false

    let chartData5 = item.percentProductivity
      ? {
          labels: ['At Present', 'Post Program'],
          datasets: [
            {
              type: 'bar',
              barThickness: 40,
              backgroundColor: ['#A3A3A3', '#6EC4D7'],
              fill: false,
              data: [
                productionTargetActual,
                productionTargetActual +
                  parseInt(item.annualChangeInProductivity)
              ]
            }
          ]
        }
      : false

    this.setState({
      showChartPopup: true,
      chartHeader: programName,
      chartSavings: savings,
      chartTotalCost: item.totalCost,
      chartProjRoi: anRoi,
      chartData1: chartData1,
      chartData2: chartData2,
      chartData3: chartData3,
      chartData4: chartData4,
      chartData5: chartData5,
      chartType: type,
      progNo: item.programNo,
      chartItem: item
    })
  }

  render () {
    let coDet = this.state.companyDetails
    let absenteeismActual = this.state.absenteeismActual
    let workerTurnoverActual = this.state.workerTurnoverActual
    let lateComingActual = this.state.lateComingActual
    let productionTargetActual = this.state.productionTargetActual
    return (
      <div className='noover'>
        <Header />
        <div
          className={
            this.state.showChartPopup ? 'popupCard' : 'popupCard dispNone'
          }
        >
          <div className='popup popupChart left-txt'>
            <div className='flex justifyContentSpaceBetween'>
              <div className='h5 pacificBlue-txt padBot16'>
                {this.state.chartHeader}
              </div>
              <div
                className='pointer'
                onClick={() => {
                  this.setState({
                    showChartPopup: false,
                    chartData1: {},
                    chartData2: {},
                    chartData3: {},
                    chartData4: {},
                    chartData5: {}
                  })
                }}
              >
                <img alt='close' src={close} />
              </div>
            </div>
            <div className='flex width100 scrollX'>
              <div>
                <div className='h5'>Impact of Programmes</div>
                <div className='flex justifyContentSpaceBetween'>
                  {this.state.chartData1 && (
                    <div className='chartCard gallery-bg widthChart'>
                      <div className='h6 padBot16'>Absenteeism</div>
                      <Bar
                        data={this.state.chartData1}
                        options={this.state.options}
                      />
                      <div className='h13 width100 center-txt'>
                        Annual Absenteeism days/employee
                      </div>
                      <br />
                      <div className='chartCard gallery-bg pad0 flex border1px'>
                        <div className='width60'>
                          <div className='h13 borderBottom padLeft16'>
                            At Present
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            Post program
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot lowerbound'></span>
                            Lowerbound
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot upperbound'></span>Upperbound
                          </div>
                          <div className='h13 borderBottom padLeft32'>
                            <span className='dot gallery-bg'></span>Average
                          </div>
                          <div className='h12 borderBottom padLeft16 nowrap'>
                            Percentage Reduction
                          </div>
                          <div className='h12 padLeft16'>
                            Total Savings ({coDet.currency})
                          </div>
                        </div>
                        <div className='width40 borderLeft'>
                          <div className='h13 borderBottom padLeft16'>
                            {absenteeismActual}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            &nbsp;
                          </div>
                          <div
                            className={
                              this.state.chartItem.absentNoLB
                                ? 'h13  borderBottom padLeft16'
                                : 'h13  borderBottom padLeft16 silver-chalice-txt'
                            }
                          >
                            {this.state.chartItem.absentNoLB
                              ? Math.round(absenteeismActual) -
                                Math.round(this.state.chartItem.absentNoLB)
                              : 'NA'}
                          </div>
                          <div
                            className={
                              this.state.chartItem.absentNoUB
                                ? 'h13  borderBottom padLeft16'
                                : 'h13  borderBottom padLeft16 silver-chalice-txt'
                            }
                          >
                            {this.state.chartItem.absentNoUB
                              ? Math.round(absenteeismActual) -
                                Math.round(this.state.chartItem.absentNoUB)
                              : 'NA'}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            {(this.state.chartItem.absentNoUB
                              ? Math.round(
                                  parseFloat(absenteeismActual) -
                                    this.average(
                                      this.state.chartItem.absentNoUB,
                                      this.state.chartItem.absentNoLB
                                    )
                                )
                              : Math.round(absenteeismActual) -
                                Math.round(this.state.chartItem.absentNo)
                            ).toString()}
                          </div>
                          <div className='h12 borderBottom padLeft16'>
                            {this.state.chartItem.percentAbsentUB
                              ? this.state.chartItem.percentAbsentLB +
                                '-' +
                                this.state.chartItem.percentAbsentUB
                              : this.state.chartItem.percentAbsent}
                            %
                          </div>
                          <div className='h12 padLeft16 flex alignItemsCenter'>
                            {(this.state.chartItem.costSavingsAbsenteeism &&
                              this.state.chartItem.costSavingsAbsenteeism) === 0
                              ? this.state.chartItem.costSavingsAbsenteeism
                              : this.convertToInternationalCurrencySystem(
                                  this.state.chartItem.costSavingsAbsenteeism
                                    ? this.state.chartItem
                                        .costSavingsAbsenteeism
                                    : this.average(
                                        this.state.chartItem
                                          .costSavingsAbsenteeismUB,
                                        this.state.chartItem
                                          .costSavingsAbsenteeismLB
                                      )
                                ).toString()}
                            <div className='tooltip'>
                              <img
                                alt='info'
                                src={infoBtnWhite}
                                className='info'
                              />
                              <span className='tooltiptextchart h11'>
                                {coDet.currency}&nbsp;
                                {this.commaNum(
                                  this.state.chartItem.costSavingsAbsenteeism &&
                                    this.state.chartItem.costSavingsAbsenteeism
                                ) === 0
                                  ? this.commaNum(this.state.chartItem.costSavingsAbsenteeism)
                                  : this.state.chartItem.costSavingsAbsenteeism
                                  ? this.commaNum(this.state.chartItem.costSavingsAbsenteeism)
                                  : this.commaNum(this.average(
                                      this.state.chartItem
                                        .costSavingsAbsenteeismUB,
                                      this.state.chartItem
                                        .costSavingsAbsenteeismLB
                                    ))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.chartData2 && (
                    <div className='chartCard gallery-bg widthChart'>
                      <div className='h6 padBot16'>Late Coming</div>
                      <Bar
                        data={this.state.chartData2}
                        options={this.state.options}
                      />
                      <div className='h13 width100 center-txt'>
                        Annual Late Coming days/employee
                      </div>
                      <br />
                      <div className='chartCard gallery-bg pad0 flex border1px'>
                        <div className='width60'>
                          <div className='h13 borderBottom padLeft16'>
                            At Present
                          </div>
                          <div className='h13 borderBottom  padLeft16'>
                            Post program
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot lowerbound'></span>
                            Lowerbound
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot upperbound'></span>Upperbound
                          </div>
                          <div className='h13 borderBottom padLeft32'>
                            <span className='dot gallery-bg'></span>Average
                          </div>
                          <div className='h12 borderBottom padLeft16 nowrap'>
                            Percentage Reduction
                          </div>
                          <div className='h12 padLeft16'>
                            Total Savings ({coDet.currency})
                          </div>
                        </div>
                        <div className='width40 borderLeft'>
                          <div className='h13 borderBottom padLeft16'>
                            {(lateComingActual
                              ? lateComingActual
                              : 0
                            ).toString()}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            &nbsp;
                          </div>
                          <div className='h13 borderBottom padLeft16 silver-chalice-txt'>
                            NA
                          </div>
                          <div className='h13 borderBottom padLeft16 silver-chalice-txt'>
                            NA
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            {Math.round(lateComingActual) -
                              Math.round(0.62 * parseFloat(lateComingActual))}
                          </div>
                          <div className='h12 borderBottom padLeft16'>
                            {this.state.chartItem.percentLateComing}%
                          </div>
                          <div className='h12 padLeft16 flex alignItemsCenter'>
                            {this.convertToInternationalCurrencySystem(
                              this.state.chartItem.costSavingsLateComing
                            ).toString()}
                            <div className='tooltip'>
                              <img
                                alt='info'
                                src={infoBtnWhite}
                                className='info'
                              />
                              <span className='tooltiptextchart h11'>
                                {coDet.currency}&nbsp;
                                {this.commaNum(
                                  this.state.chartItem.costSavingsLateComing
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.chartData3 && (
                    <div className='chartCard  gallery-bg widthChart'>
                      <div className='h6 width100 center-txt'>
                        <div className='h6 padBot16 left-txt'>Attendance</div>
                        <div className='silver-chalice-txt notApplicableMargin'>
                          Graph not applicable
                        </div>
                      </div>
                      <div className='chartCard gallery-bg pad0 flex border1px'>
                        <div className='width60 flexColumn justifyContentSpaceBetween'>
                          <div className='h13 borderBottom padLeft16 height40Per lineHeight24'>
                            Monthly increase in working hours
                          </div>
                          <div className='h13 borderBottom padLeft16 height40Per lineHeight24'>
                            Annual increase in working hours
                          </div>
                          <div className='h12 padLeft16 height20Per'>
                            Total Savings ({coDet.currency}){' '}
                          </div>
                        </div>
                        <div className='width40 borderLeft flexColumn justifyContentSpaceBetween'>
                          <div className='h13 borderBottom padLeft16 height40Per'>
                            {parseFloat(
                              this.state.chartItem.attendanceNo
                            ).toFixed(2)}
                          </div>
                          <div className='h13 borderBottom padLeft16 height40Per'>
                            {this.state.chartItem.percentAttendance}
                          </div>
                          <div className='h12 padLeft16 height20Per flex alignItemsCenter'>
                            {this.convertToInternationalCurrencySystem(
                              this.state.chartItem.costSavingsAttendance
                            ).toString()}
                            <div className='tooltip'>
                              <img
                                alt='info'
                                src={infoBtnWhite}
                                className='info'
                              />
                              <span className='tooltiptextchart h11'>
                                {coDet.currency}&nbsp;
                                {this.commaNum(
                                  this.state.chartItem.costSavingsAttendance
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.chartData4 && (
                    <div className='chartCard gallery-bg widthChart'>
                      <div className='h6 padBot16'>Worker Turnover</div>
                      <Bar
                        data={this.state.chartData4}
                        options={this.state.options}
                      />
                      <div className='h13 width100 center-txt'>
                        Annual Worker turnover
                      </div>
                      <br />
                      <div className='chartCard gallery-bg pad0 flex border1px'>
                        <div className='width60'>
                          <div className='h13 borderBottom padLeft16'>
                            At Present
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            Post program
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot lowerbound'></span>
                            Lowerbound
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot upperbound'></span>Upperbound
                          </div>
                          <div className='h13 borderBottom padLeft32'>
                            <span className='dot gallery-bg'></span>Average
                          </div>
                          <div className='h12 borderBottom padLeft16 nowrap'>
                            Percentage Reduction
                          </div>
                          <div className='h12 padLeft16'>
                            Total Savings ({coDet.currency})
                          </div>
                        </div>
                        <div className='width40 borderLeft'>
                          <div className='h13 borderBottom padLeft16'>
                            {workerTurnoverActual}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            &nbsp;
                          </div>
                          <div
                            className={
                              this.state.chartItem.workerTurnoverLB
                                ? 'h13 borderBottom padLeft16'
                                : 'h13 borderBottom padLeft16 silver-chalice-txt'
                            }
                          >
                            {this.state.chartItem.workerTurnoverLB
                              ? parseInt(workerTurnoverActual) -
                                Math.round(
                                  this.state.chartItem.workerTurnoverLB
                                )
                              : 'NA'}
                          </div>
                          <div
                            className={
                              this.state.chartItem.workerTurnoverUB
                                ? 'h13 borderBottom padLeft16'
                                : 'h13 borderBottom padLeft16 silver-chalice-txt'
                            }
                          >
                            {this.state.chartItem.workerTurnoverUB
                              ? Math.round(workerTurnoverActual) -
                                Math.round(
                                  this.state.chartItem.workerTurnoverUB
                                )
                              : 'NA'}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            {(this.state.chartItem.workerTurnoverUB
                              ? Math.round(workerTurnoverActual) -
                                Math.round(
                                  this.average(
                                    this.state.chartItem.workerTurnoverUB,
                                    this.state.chartItem.workerTurnoverLB
                                  )
                                )
                              : Math.round(workerTurnoverActual) -
                                Math.round(this.state.chartItem.workerTurnover)
                            ).toString()}
                          </div>
                          <div className='h12 nowrap borderBottom padLeft16'>
                            {this.state.chartItem.percentWorkerTurnover
                              ? this.state.chartItem.percentWorkerTurnover + '%'
                              : this.state.chartItem.percentWorkerTurnoverLB +
                                '% - ' +
                                this.state.chartItem.percentWorkerTurnoverUB +
                                '%'}
                          </div>
                          <div className='h12 padLeft16 flex alignItemsCenter'>
                            {this.convertToInternationalCurrencySystem(
                              this.state.chartItem.costSavingsWorkerTurnoverUB
                                ? this.average(
                                    this.state.chartItem
                                      .costSavingsWorkerTurnoverUB,
                                    this.state.chartItem
                                      .costSavingsWorkerTurnoverLB
                                  )
                                : this.state.chartItem.costSavingsWorkerTurnover
                            ).toString()}
                            <div className='tooltip'>
                              <img
                                alt='info'
                                src={infoBtnWhite}
                                className='info'
                              />
                              <span className='tooltiptextchart h11'>
                                {coDet.currency}&nbsp;
                                {this.commaNum(
                                  this.state.chartItem
                                    .costSavingsWorkerTurnoverUB
                                    ? this.average(
                                        this.state.chartItem
                                          .costSavingsWorkerTurnoverUB,
                                        this.state.chartItem
                                          .costSavingsWorkerTurnoverLB
                                      )
                                    : this.state.chartItem
                                        .costSavingsWorkerTurnover
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.chartData5 && (
                    <div className='chartCard gallery-bg widthChart'>
                      <div className='h6 padBot16'>Productivity</div>
                      <Bar
                        data={this.state.chartData5}
                        options={this.state.options}
                      />
                      <div className='h13 width100 center-txt'>
                        Annual Productivity{' '}
                        {coDet.industry === 'Textile' && <span>(SAM)</span>}
                      </div>
                      <br />
                      <div className='chartCard gallery-bg pad0 flex border1px'>
                        <div className='width60'>
                          <div className='h13 borderBottom padLeft16'>
                            At Present
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            Post program
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot lowerbound'></span>
                            Lowerbound
                          </div>
                          <div className='h13  borderBottom padLeft32'>
                            <span className='dot upperbound'></span>Upperbound
                          </div>
                          <div className='h13 borderBottom padLeft32'>
                            <span className='dot gallery-bg'></span>Average
                          </div>
                          <div className='h12  borderBottom padLeft16'>
                            Percentage Increase
                          </div>
                          <div className='h12 padLeft16'>
                            Total Savings ({coDet.currency})
                          </div>
                        </div>
                        <div className='width40 borderLeft'>
                          <div className='h13 borderBottom padLeft16'>
                            {productionTargetActual}
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            &nbsp;
                          </div>
                          <div className='h13 borderBottom padLeft16 silver-chalice-txt'>
                            NA
                          </div>
                          <div className='h13 borderBottom padLeft16 silver-chalice-txt'>
                            NA
                          </div>
                          <div className='h13 borderBottom padLeft16'>
                            {(
                              parseInt(productionTargetActual) +
                              parseInt(
                                this.state.chartItem.annualChangeInProductivity
                              )
                            ).toString()}
                          </div>
                          <div className='h12 borderBottom padLeft16'>
                            {this.state.chartItem.percentProductivity}%
                          </div>
                          <div className='h12 padLeft16 flex alignItemsCenter'>
                            {this.convertToInternationalCurrencySystem(
                              this.state.chartItem.costSavingsProductivity
                            ).toString()}
                            <div className='tooltip'>
                              <img
                                alt='info'
                                src={infoBtnWhite}
                                className='info'
                              />
                              <span className='tooltiptextchart h11'>
                                {coDet.currency}&nbsp;
                                {this.commaNum(
                                  this.state.chartItem.costSavingsProductivity
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='margLeft16'>
                <div className='h5'>Output</div>
                <div className='chartCard gallery-bg pad0 flex'>
                  <div className='width70'>
                    <div className='h7 pad4 nowrap  borderBottom padRight24'>
                      Annual Cost of Programme ({coDet.currency})
                    </div>
                    <div className='h7 pad4 nowrap borderBottom padRight24'>
                      Annual Savings from Programme ({coDet.currency})
                    </div>

                    <div className='h6 pad4 borderBottom'>Projected ROI</div>
                    <div className='h7 pad4 borderBottom'>
                      For Every {coDet.currency} 1, get {coDet.currency}
                    </div>
                    <div className='h7 pad4 nowrap'>
                      Projected ROI percentage
                    </div>
                  </div>
                  <div className='width30 borderLeft'>
                    <div className='h5 pad4 borderBottom flex  alignItemsCenter'>
                      {this.convertToInternationalCurrencySystem(
                        parseFloat(this.state.chartTotalCost)
                      ).toString()}
                      <div className='tooltip'>
                        <img alt='info' src={infoBtnWhite} className='info' />
                        <span className='tooltiptextchart right0 h11'>
                          {coDet.currency}&nbsp;
                          {this.commaNum(this.state.chartTotalCost)}
                        </span>
                      </div>
                    </div>
                    <div className='h5 pad4 borderBottom flex  alignItemsCenter'>
                      {this.convertToInternationalCurrencySystem(
                        parseFloat(this.state.chartSavings)
                      ).toString()}
                      <div className='tooltip'>
                        <img alt='info' src={infoBtnWhite} className='info' />
                        <span className='tooltiptextchart right0 h11'>
                          {coDet.currency}&nbsp;
                          {this.commaNum(this.state.chartSavings)}
                        </span>
                      </div>
                    </div>

                    <div className='h4 pad4 borderBottom'>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className='h4 pad4 borderBottom'>
                      {parseFloat(this.state.chartProjRoi).toFixed(2)}
                    </div>
                    <div className='h4 pad4'>
                      {(parseFloat(this.state.chartProjRoi) * 100).toFixed(2)}%
                    </div>
                  </div>
                </div>
                {this.state.chartType === 'single' && (
                  <div>
                    <div className='h5'>Bundles</div>
                    <div className='chartCard gallery-bg'>
                      <div className='h12'>
                        Programmes this can be bundled with
                      </div>
                      <div className='h13 bundleDesc'>
                        {parseInt(this.state.progNo) === 0 && (
                          <ul>
                            <li>
                              Any 1 of these programme <br />
                              (SRH Training/ Lifeskill training/ <br />
                              Peer Outreach/Free Iron tablets)
                            </li>
                            <li>Subsidized SRH services & products</li>
                            <li>Setup Factory Clinic</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 1 && (
                          <ul>
                            <li>Sanitary Pad Usage</li>
                            <li>Setup Factory Clinic</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 2 && (
                          <ul>
                            <li>Sanitary Pad Usage</li>
                            <li>Setup Factory Clinic</li>
                            <li>Free Iron Tablets</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 3 && (
                          <ul>
                            <li>Sanitary Pad Usage</li>
                            <li>Setup Factory Clinic</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 4 && (
                          <ul>
                            <li>
                              Any 1 of these programme <br />
                              (SRH Training/ Lifeskill training/ <br />
                              Peer Outreach)
                            </li>
                            <li>Subsidized SRH services & products</li>
                            <li>Setup Factory Clinic</li>
                            <li>Free Iron Tablets</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 5 && (
                          <ul>
                            <li>Sanitary Pad Usage</li>
                            <li>Subsidized SRH services & products</li>
                            <li>Setup Factory Clinic</li>
                          </ul>
                        )}
                        {parseInt(this.state.progNo) === 6 && (
                          <ul>
                            <li>Sanitary Pad Usage</li>
                            <li>Setup Factory Clinic</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showAddPopup ||
            this.state.showBundlePopup ||
            this.state.showDeletePopup ||
            this.state.showGenerateReportPopup ||
            this.state.showExportReportPopup
              ? 'popupCard'
              : 'popupCard dispNone'
          }
        >
          <div
            className={
              this.state.showExportReportPopup
                ? 'popup left-txt'
                : 'popupSmall left-txt'
            }
          >
            <div className='h9 padBot16'>
              {this.state.showAddPopup
                ? 'Add programme'
                : this.state.showBundlePopup
                ? 'Bundle Programmes'
                : this.state.showDeletePopup
                ? 'Delete Bundle'
                : this.state.showExportReportPopup
                ? 'Export Report'
                : this.state.reportPopupText}
              <span className='h12  padLeft48'>
                {this.state.showExportReportPopup ? 'FILE TYPE (.PDF)' : ''}
              </span>
            </div>
            {this.state.showAddPopup && (
              <div className='h11'>
                You will be redirected to the programme selection page.
              </div>
            )}
            {this.state.showBundlePopup && (
              <div className='h11'>
                Bundling programmes that can be implemented simultaneously might
                save time and give a more wholesome ROI. View and add programmes
                that can be bundled with {this.state.bundlePopupName}
              </div>
            )}
            {this.state.showDeletePopup && (
              <div className='h11'>
                Are you sure you want to delete this bundle?
              </div>
            )}
            {this.state.showGenerateReportPopup && (
              <div className='width100 flex justifyContentFlexEnd'>
                {this.state.reportPopupText !== 'Report Generated' ? (
                  <img alt='spin' className='height48' src={spin} />
                ) : (
                  <img
                    alt='ok'
                    className='pointer height40px padLeft24'
                    onClick={() => {
                      this.setState({ showGenerateReportPopup: false })
                    }}
                    src={ok}
                  />
                )}
              </div>
            )}
            {this.state.showExportReportPopup && (
              <div className='width100'>
                <div className='h6'>
                  Please select programmes from your projection.
                </div>
                <br />
                <div>
                  {this.state.local.roiData[this.state.currProjectionId]
                    .programmeDetails &&
                    this.state.local.roiData[
                      this.state.currProjectionId
                    ].programmeDetails.map((item, i) => {
                      if (item) {
                        let program = this.state.programmes.filter(function (
                          itm
                        ) {
                          return (
                            itm.programmeDetails.programNo === item.programNo
                          )
                        })
                        return (
                          <div className='h11' style={{ marginBottom: '4px' }}>
                            <Checkbox id={i + 'programmeExport'} />
                            &nbsp;
                            <label htmlFor={i + 'programmeExport'}>
                              {program[0].programmeDetails.name}
                            </label>
                          </div>
                        )
                      } else {
                        return <div></div>
                      }
                    })}
                  {this.state.local.roiData[this.state.currProjectionId]
                    .bundleDetails &&
                    this.state.local.roiData[
                      this.state.currProjectionId
                    ].bundleDetails.map((item, i) => {
                      if (item) {
                        let chartHeader = ''
                        this.state.programmes.forEach((itm, i) => {
                          if (
                            item.programIds.includes(
                              itm.programmeDetails.programNo
                            )
                          ) {
                            chartHeader += itm.programmeDetails.name + ' + '
                          }
                        })
                        chartHeader = chartHeader.slice(0, -2)
                        return (
                          <div className='h11' style={{ marginBottom: '4px' }}>
                            <Checkbox id={i + 'bundleExport'} />
                            <label htmlFor={i + 'bundleExport'}>
                              &nbsp;{chartHeader}
                            </label>
                          </div>
                        )
                      } else {
                        return <div></div>
                      }
                    })}
                </div>
                <br />
                <br />
                <div className='flex width100 justifyContentFlexEnd'>
                  <img
                    alt='cancel'
                    className='pointer height40px'
                    onClick={() => {
                      this.setState({ showExportReportPopup: false })
                    }}
                    src={cancel}
                  />
                  <img
                    alt='export'
                    className='pointer height40px padLeft16'
                    onClick={() => {
                      this.setState({
                        showExportReportPopup: false,
                        showGenerateReportPopup: true
                      })
                      this.exportReport()
                    }}
                    src={exportb}
                  />
                </div>
              </div>
            )}
            <br />
            <br />
            {(this.state.showAddPopup ||
              this.state.showBundlePopup ||
              this.state.showDeletePopup) && (
              <div className='width100 flex justifyContentFlexEnd'>
                <img
                  alt='cancel'
                  className='pointer height40px'
                  onClick={() => {
                    this.state.showAddPopup
                      ? this.setState({ showAddPopup: false })
                      : this.state.showBundlePopup
                      ? this.setState({ showBundlePopup: false })
                      : this.setState({ showDeletePopup: false })
                  }}
                  src={cancel}
                />
                <img
                  alt='ok'
                  className='pointer height40px padLeft24'
                  onClick={() => {
                    if (this.state.showAddPopup) {
                      localStorage.setItem('from', 'add')
                      window.location.href = '/select-programmes'
                    } else if (this.state.showBundlePopup) {
                      localStorage.setItem('from', 'bundle')
                      window.location.href = '/select-programmes'
                    } else {
                      this.deletebundle()
                    }
                  }}
                  src={ok}
                />
              </div>
            )}
          </div>
        </div>
        <div className='blackWhite-bg height100'>
          <div className='bar flex justifyContentSpaceBetween  alignItemsCenter'>
            <div className='width60'>
              <img alt='Roi bar' className='width100' src={roiBar} />
            </div>
            <div className='flex alignItemsCenter'>
              <div
                onClick={() => {
                  localStorage.setItem('from', 'back')
                  window.location.href = '/input'
                }}
              >
                <img
                  alt='back'
                  className='pointer height32 margRight8 margBot4'
                  src={back}
                />
              </div>
              <img
                alt='export Btn'
                className='pointer height32'
                onClick={() => {
                  this.setState({ showExportReportPopup: true })
                }}
                src={exportBtn}
              />
            </div>
          </div>
          <div className='flex padTop24'>
            <div className='bar width20 padTop48'>
              <div className='h7 padRight24 left-txt nowrap'>Step 4</div>
              <br />
              <div className='h4 left-txt'>
                View ROI for
                <br />‘
                {
                  this.state.local.roiData[this.state.currProjectionId]
                    .projectionName
                }
                ’
              </div>
              <br />
              <div className='h7 left-txt'>{this.state.forWho}</div>
              <br />
              <div className='h7 left-txt'>
                Your workspace where you can review the impact and projected ROI
                for selected programmes. You can add more programmes or bundled
                programmes to this workspace.
              </div>
              <br />
              <br />
              <div className='left-txt margBotAdd'>
                <img
                  alt='add prog btn'
                  className='pointer height32 left-txt'
                  onClick={() => {
                    localStorage.removeItem('currentSelectedProgFromAdd')
                    localStorage.removeItem('currentSelectedProgAdd')
                    localStorage.setItem('calculateRoi', 'false')
                    this.setState({ showAddPopup: true })
                  }}
                  src={addProgBtn}
                />
              </div>
              <div className='h7 left-txt'>
                <strong>Legend</strong>
                <br />
                1K = 1 Thousand = 1,000
                <br />
                1M= 1 Million =10,00,000
                <br />
                1B = 1 Billion = 1,000,000,000
              </div>
            </div>
            <div className='rightSection rightSectionRoi flex'>
              {this.state.local.roiData[this.state.currProjectionId]
                .programmeDetails &&
                this.state.local.roiData[
                  this.state.currProjectionId
                ].programmeDetails.map((item, i) => {
                  if (item) {
                    let program = this.state.programmes.filter(function (itm) {
                      return itm.programmeDetails.programNo === item.programNo
                    })
                    let programBundle = program[0].programmeDetails.bundleOptions.filter(
                      function (itm) {
                        return itm.isBundled
                      }
                    )
                    let bundleList = []
                    programBundle.forEach(item => {
                      bundleList.push(item.programNo)
                    })
                    let savings = item.savingsFromIntervention
                      ? parseInt(item.savingsFromIntervention)
                      : this.average(
                          item.savingsFromInterventionUB,
                          item.savingsFromInterventionLB
                        )
                    let anRoi =
                      item.annualRoi >= 0
                        ? parseFloat(item.annualRoi)
                        : this.average(item.annualRoiUB, item.annualRoiLB)
                    return (
                      <div
                        key={'roi' + item.programNo}
                        className='roiCard width41'
                      >
                        <div
                          onClick={() => {
                            this.openChartOpenPopup(
                              program[0].programmeDetails.name,
                              item,
                              'single'
                            )
                          }}
                          className='pointer'
                        >
                          <div className='h5 pacificBlue-txt'>
                            {program[0].programmeDetails.name}
                          </div>
                          <br />
                          <div className='h5 flex justifyContentSpaceBetween'>
                            <div>Impact of programmes</div>
                            <div className='h6 treePoppy-txt pointer'>
                              &nbsp;View More &#62;
                            </div>
                          </div>
                          <div className='impactCard gallery-bg flex justifyContentSpaceBetween'>
                            <div className='width32'>
                              {(item.percentAbsent || item.percentAbsentUB) && (
                                <div className='width100'>
                                  <div className='h7'>Absenteeism</div>
                                  <div className='margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                    <div className='h4'>
                                      {item.percentAbsent
                                        ? item.percentAbsent
                                        : this.average(
                                            item.percentAbsentUB,
                                            item.percentAbsentLB
                                          )}
                                      %
                                    </div>
                                    <div className='h12'>Reduction</div>
                                  </div>
                                </div>
                              )}
                              {item.percentAttendance && (
                                <div className='width100'>
                                  <div className='h7'>Attendance</div>
                                  <div className='margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                    <div className='h4'>
                                      {item.percentAttendance}
                                    </div>
                                    <div className='h12'>Increase</div>
                                  </div>
                                </div>
                              )}
                              {item.percentLateComing && (
                                <div className='width100'>
                                  <div className='h7'>Late Coming</div>
                                  <div className='margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                    <div className='h4'>
                                      {item.percentLateComing}%
                                    </div>
                                    <div className='h12'>Reduction</div>
                                  </div>
                                </div>
                              )}
                              {!item.percentLateComing &&
                                !item.percentAttendance &&
                                !(
                                  item.percentAbsent || item.percentAbsentUB
                                ) && (
                                  <div className='width100'>
                                    <div className='h7 silver-chalice-txt'>
                                      Absenteeism
                                    </div>
                                    <div className='margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                      <div className='h4 silver-chalice-txt'>
                                        NA
                                      </div>
                                      <div className='h11'></div>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className='width36'>
                              <div
                                className={
                                  item.percentWorkerTurnover ||
                                  item.percentWorkerTurnoverUB
                                    ? 'h7'
                                    : 'h7 silver-chalice-txt'
                                }
                              >
                                Worker Turnover
                              </div>
                              <div className='grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                <div
                                  className={
                                    item.percentWorkerTurnover ||
                                    item.percentWorkerTurnoverUB
                                      ? 'h4'
                                      : 'h4 silver-chalice-txt'
                                  }
                                >
                                  {item.percentWorkerTurnover
                                    ? item.percentWorkerTurnover + '%'
                                    : item.percentWorkerTurnoverUB
                                    ? this.average(
                                        item.percentWorkerTurnoverUB,
                                        item.percentWorkerTurnoverLB
                                      ).toFixed(2) + '%'
                                    : 'NA'}
                                </div>
                                <div className='h12'>
                                  {item.percentWorkerTurnover ||
                                  item.percentWorkerTurnoverUB
                                    ? 'Reduction'
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <div className='width32'>
                              <div
                                className={
                                  item.percentProductivity
                                    ? 'h7'
                                    : 'h7 silver-chalice-txt'
                                }
                              >
                                Productivity
                              </div>
                              <div className='grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentFlexStart'>
                                <div
                                  className={
                                    item.percentProductivity
                                      ? 'h4'
                                      : 'h4 silver-chalice-txt'
                                  }
                                >
                                  {item.percentProductivity
                                    ? item.percentProductivity + '%'
                                    : 'NA'}
                                </div>
                                <div className='h12'>
                                  {item.percentProductivity ? 'Increase' : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='h5 padBot16'>Outcomes</div>
                          <div className='flex'>
                            <div className='h7 width65'>
                              Annual Cost of Programme
                            </div>
                            <div className='h5 flex  alignItemsCenter'>
                              {coDet.currency}&nbsp;
                              {this.convertToInternationalCurrencySystem(
                                parseInt(item.totalCost)
                              )}
                              <div className='tooltip'>
                                <img
                                  alt='info'
                                  src={infoBtn}
                                  className='info'
                                />
                                <span className='tooltiptext h11'>
                                  {coDet.currency}&nbsp;
                                  {this.commaNum(item.totalCost)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='flex'>
                            <div className='h7 width65 nowrap'>
                              Annual Savings from Programme
                            </div>
                            <div className='h5 flex  alignItemsCenter'>
                              {coDet.currency}&nbsp;
                              {this.convertToInternationalCurrencySystem(
                                savings
                              )}
                              <div className='tooltip'>
                                <img
                                  alt='info'
                                  src={infoBtn}
                                  className='info'
                                />
                                <span className='tooltiptext h11'>
                                  {coDet.currency}&nbsp;
                                  {this.commaNum(savings)}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className='h5 padBot8 padTop16'>
                            Projected ROI
                          </div>
                          <div className='flex'>
                            <div className='width65'>
                              <div className='h4'>
                                {coDet.currency} {anRoi.toFixed(2)}
                              </div>
                              <div className='h7 padBot16'>
                                for every {coDet.currency} invested
                              </div>
                            </div>
                            <div>
                              {/* <div className='h4'>
                              {(anRoi * 100).toFixed(2)}%
                            </div>
                            <div className='h7'>Projected ROI %</div> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                        {programBundle.length > 0 && (
                          <div className='h7 padTop16'>
                            <div className='flex alignItemsCenter'>
                              <div className='width80'>
                                Bundle with {programBundle.length}{' '}
                                programmes&nbsp;&nbsp;
                              </div>
                              <div
                                onClick={() => {
                                  localStorage.removeItem(
                                    'currentSelectedProgBundle'
                                  )
                                  localStorage.setItem('calculateRoi', 'false')
                                  this.openBundlePopup(
                                    bundleList,
                                    item.programNo,
                                    program[0].programmeDetails.name
                                  )
                                }}
                                className='pointer'
                              >
                                <img
                                  alt='Add Now'
                                  className='height32'
                                  src={addNow}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  } else {
                    return <div key={'nodisp' + i}></div>
                  }
                })}
              {this.state.local.roiData[this.state.currProjectionId]
                .bundleDetails &&
                this.state.local.roiData[
                  this.state.currProjectionId
                ].bundleDetails.map((item, i) => {
                  let anRoi =
                    item.annualRoi >= 0
                      ? parseFloat(item.annualRoi)
                      : this.average(item.annualRoiUB, item.annualRoiLB)
                  return (
                    <div
                      key={i + 'roiCard'}
                      className='roiCard bundle width90 flex relativePos'
                    >
                      <div className='width20'>
                        <div className='h5 pacificBlue-txt'>
                          BUNDLE of {item.programIds.length}
                        </div>
                        <div className='h12'>
                          <ul>
                            {this.state.programmes &&
                              this.state.programmes.map((itm, j) => {
                                if (
                                  item.programIds.includes(
                                    itm.programmeDetails.programNo
                                  )
                                ) {
                                  return (
                                    <li key={j + 'program'}>
                                      {itm.programmeDetails.name}
                                    </li>
                                  )
                                } else {
                                  return ''
                                }
                              })}
                          </ul>
                        </div>
                      </div>
                      <div
                        className='width80 pointer'
                        onClick={() => {
                          let chartHeader = ''
                          this.state.programmes.forEach((itm, i) => {
                            if (
                              item.programIds.includes(
                                itm.programmeDetails.programNo
                              )
                            ) {
                              chartHeader += itm.programmeDetails.name + ' + '
                            }
                          })
                          chartHeader = chartHeader.slice(0, -2)
                          this.openChartOpenPopup(chartHeader, item, 'bundle')
                        }}
                      >
                        <div className='h5 flex justifyContentSpaceBetween'>
                          <div>Impact of programmes</div>
                          <div className='flex'>
                            <div className='h6 treePoppy-txt pointer'>
                              &nbsp;View More &#62;
                            </div>
                            <img
                              alt='close btn'
                              onClick={() => {
                                this.setState({
                                  deleteBundle: i,
                                  showDeletePopup: true
                                })
                              }}
                              className='deletbtn pointer'
                              src={closeBtn}
                            />
                          </div>
                        </div>
                        <div className='impactCard gallery-bg flex justifyContentSpaceBetween'>
                          <div className='width32'>
                            <div className='h7 padBot16'>Absenteeism</div>
                            <div className='margTop16 h4 flexColumn alignItemsCenter justifyContentCenter'>
                              <div className='h4'>
                                {item.percentAbsent
                                  ? parseFloat(item.percentAbsent)
                                  : this.average(
                                      item.percentAbsentUB,
                                      item.percentAbsentLB
                                    )}
                                %
                              </div>
                              <div className='h12'>Reduction</div>
                            </div>
                          </div>
                          <div className='width36'>
                            <div
                              className={
                                item.percentAttendance
                                  ? 'h7'
                                  : 'h7 silver-chalice-txt'
                              }
                            >
                              Attendance
                            </div>
                            <div
                              className={
                                item.percentAttendance
                                  ? 'grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter'
                                  : 'grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter silver-chalice-txt'
                              }
                            >
                              {item.percentAttendance
                                ? item.percentAttendance + ' hrs'
                                : 'NA'}
                              {item.percentAttendance && (
                                <div className='h12'>Increase</div>
                              )}
                            </div>
                          </div>
                          <div className='width32'>
                            <div
                              className={
                                item.percentLateComing
                                  ? 'h7'
                                  : 'h7 silver-chalice-txt'
                              }
                            >
                              Late Coming
                            </div>
                            <div
                              className={
                                item.percentLateComing
                                  ? 'grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter'
                                  : 'grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter silver-chalice-txt'
                              }
                            >
                              {item.percentLateComing
                                ? item.percentLateComing + '%'
                                : 'NA'}

                              {item.percentLateComing && (
                                <div className='h12'>Reduction</div>
                              )}
                            </div>
                          </div>
                          <div className='width32'>
                            <div
                              className={
                                item.percentWorkerTurnover ||
                                item.percentWorkerTurnoverLB
                                  ? 'h7'
                                  : 'h7 silver-chalice-txt'
                              }
                            >
                              Worker Turnover
                            </div>
                            <div className='grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter'>
                              <div
                                className={
                                  item.percentWorkerTurnover ||
                                  item.percentWorkerTurnoverLB
                                    ? 'h4'
                                    : 'h4 silver-chalice-txt'
                                }
                              >
                                {item.percentWorkerTurnover
                                  ? item.percentWorkerTurnover + '%'
                                  : item.percentWorkerTurnoverUB
                                  ? (
                                      (parseFloat(
                                        item.percentWorkerTurnoverUB
                                      ) +
                                        parseFloat(
                                          item.percentWorkerTurnoverLB
                                        )) /
                                      2
                                    ).toFixed(2) + '%'
                                  : 'NA'}
                              </div>
                              <div className='h12'>
                                {item.percentWorkerTurnover ||
                                item.percentWorkerTurnoverUB
                                  ? 'Reduction'
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className='width32'>
                            <div
                              className={
                                item.percentProductivity
                                  ? 'h7'
                                  : 'h7 silver-chalice-txt'
                              }
                            >
                              Productivity
                            </div>
                            <div className='grayBorderLeft margTop16 h4 flexColumn alignItemsCenter justifyContentCenter'>
                              <div
                                className={
                                  item.percentProductivity
                                    ? 'h4'
                                    : 'h4 silver-chalice-txt'
                                }
                              >
                                {item.percentProductivity
                                  ? item.percentProductivity + '%'
                                  : 'NA'}
                              </div>
                              <div className='h12'>
                                {item.percentProductivity ? 'Increase' : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex'>
                          <div className='width60'>
                            <div className='h5 padBot16'>Outcomes</div>
                            <div className='flex'>
                              <div className='h7 width65'>
                                Annual Cost of Programme
                              </div>
                              <div className='h5 width25 flex alignItemsCenter'>
                                {coDet.currency}&nbsp;
                                {this.convertToInternationalCurrencySystem(
                                  item.totalCost
                                )}
                                <div className='tooltip'>
                                  <img
                                    alt='info'
                                    src={infoBtn}
                                    className='info'
                                  />
                                  <span className='tooltiptext h11'>
                                    {coDet.currency}&nbsp;
                                    {this.commaNum(item.totalCost)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='flex'>
                              <div className='h7 width65 nowrap'>
                                Annual Savings from Programme
                              </div>
                              <div className='h5 width25 flex alignItemsCenter'>
                                {coDet.currency}&nbsp;
                                {this.convertToInternationalCurrencySystem(
                                  item.savingsFromIntervention
                                    ? parseInt(item.savingsFromIntervention)
                                    : this.average(
                                        item.savingsFromInterventionUB,
                                        item.savingsFromInterventionLB
                                      )
                                )}
                                <div className='tooltip'>
                                  <img
                                    alt='info'
                                    src={infoBtn}
                                    className='info'
                                  />
                                  <span className='tooltiptext h11'>
                                    {coDet.currency}&nbsp;
                                    {this.commaNum(
                                      item.savingsFromIntervention
                                        ? parseInt(item.savingsFromIntervention)
                                        : this.average(
                                            item.savingsFromInterventionUB,
                                            item.savingsFromInterventionLB
                                          )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='width40 grayBorderLeft padLeft16'>
                            <div className='h5 padBot8'>Projected ROI</div>
                            <div className='flex'>
                              <div className='width65'>
                                <div className='h4'>
                                  {coDet.currency} {anRoi.toFixed(2)}
                                </div>
                                <div className='h7'>
                                  for every {coDet.currency} invested
                                </div>
                              </div>
                              <div>
                                {/* <div className='h4'>
                                  {(anRoi * 100).toFixed(2)}%
                                </div>
                                <div className='h7 nowrap'>Projected ROI %</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RoiView
