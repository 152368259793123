//eslint-disable-next-line 
import React, { Component } from 'react';
import ProgrammesView from '../../../../views/Users/ROITool/Programmes/ProgrammesView'

class ProgrammesController extends Component {
    constructor(props) {
        super(props);
        this.state = {            
        };
        this.redirect = this.redirect.bind(this);
    }
    redirect(path) {
        return this.props.history.push(path);
    }

    render() {
        return (
            <ProgrammesView
                {...this.state}
                redirect={this.redirect}

            />
        )
    }
}
export default ProgrammesController;