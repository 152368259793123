//eslint-disable-next-line 
import React, { Component, useEffect } from 'react';
import AdminDashboardView from '../../../../views/Admin/AdminDashboard/AdminDashboardView';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


class AdminDashboardController extends Component {
    constructor(props) {
        super(props);
        this._programmeData = null;
        this._userContact = null;

        this.state = {
            "publishProgramme": {
                "FREE IRON TABLET": false,

                "SANITARY PAD": false,

                "LIFE SKILLS TRAINING": false,

                "SETUP FACTORY CLINIC": false,

                "SUBSIDIZED SRH SERVICES & PRODUCTS": false,

                "PEER OUTREACH": false,

                "SRH TRAINING": false,
            },
            userContactData: [],
            programmeData: [],
            selectedProgramme: null,
            saving: false,
            programmeDisablePopup: false,
            programmePublishPopup: false,
            downloadPopup: false,
            menuOpened: "",
            isNewChangesExist: false,
            newMenu: '',
            showDiscardChanges: false
        }

        

        this.redirect = this.redirect.bind(this);
        this.handleBundle = this.handleBundle.bind(this);
        this.updateProgramTxt = this.updateProgramTxt.bind(this);
        this.publishProgramDB = this.publishProgramDB.bind(this);
        this.checkPublishStatus = this.checkPublishStatus.bind(this);
        this.flagProgram = this.flagProgram.bind(this);
        this.openMenu = this.openMenu.bind(this);

    };

    componentDidMount() {

        // var xhr = new XMLHttpRequest();
        // xhr.withCredentials = true;

        // xhr.onreadystatechange = () => {
        //     if (xhr.readyState === XMLHttpRequest.DONE) {
        //         var status = xhr.status;
        //         if (status === 0 || (status >= 200 && status < 400)) {
        //             this._userContact = JSON.parse(xhr.responseText);
        //             if (this._programmeData) {
        //                 this.setState({ userContactData: this._userContact });

        //             }
        //         } else {
        //             // Oh no! There has been an error with the request!
        //         }
        //     }
        // };

        // xhr.open("GET", window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/api/programmes/allProgrammes");

        // xhr.send();
        //this.getUserContact()

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/api/programmes/allProgrammes", requestOptions)
            .then(response => response.text())
            .then(result => {
                this._programmeData = JSON.parse(result);
                if (this._programmeData) {
                    this.setState({ programmeData: this._programmeData });

                }
            })
            .catch(error => console.log('error', error));

        fetch(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/api/users/allUsers", requestOptions)
            .then(response => response.text())
            .then(result => {
                this._userContact = JSON.parse(result);
                if (this._userContact) {
                    this.setState({ userContactData: this._userContact });

                }
            })
            .catch(error => console.log('error', error));

    }
    redirect(path) {
        return this.props.history.push(path);
    }
    checkPublishStatus() {
        let { publishProgramme, selectedProgramme } = this.state;
        if (selectedProgramme) {
            return !publishProgramme[selectedProgramme["programmeDetails"].name];
        }
        else {
            return true
        }

    }

    publishProgramDB(programmeName) {
        let { selectedProgramme, publishProgramme } = this.state;
        var data = JSON.stringify(selectedProgramme);

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", () => {
            if (this.readyState === 4) {
            } else if (this.readyState === "loading") {
            }
        });
        publishProgramme[programmeName] = false;
        this.setState({ publishProgramme, programmePublishPopup: false });

        xhr.open("POST", window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/api/programmes/updateProgramme/" + selectedProgramme["_id"]);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(data);

    }
    handleBundle(bundle) {
        let { selectedProgramme, publishProgramme } = this.state;

        if (selectedProgramme) {
            let bundleIndex = selectedProgramme["programmeDetails"].bundleOptions.indexOf(bundle)
            selectedProgramme["programmeDetails"].bundleOptions[bundleIndex].isBundled = !selectedProgramme["programmeDetails"].bundleOptions[bundleIndex].isBundled;
        }
        publishProgramme[selectedProgramme["programmeDetails"].name] = true;
        this.setState({ selectedProgramme, publishProgramme })
    }
    updateProgramTxt(targetValue, inputType) {
        let { selectedProgramme, publishProgramme } = this.state;
        if (selectedProgramme) {
            selectedProgramme["programmeDetails"][inputType] = targetValue;
        }
        publishProgramme[selectedProgramme["programmeDetails"].name] = true;
        this.setState({ selectedProgramme, publishProgramme });

    }
    selectProgramme(programmeObj) {
        this.setState({ selectedProgramme: programmeObj })

    }
    flagProgram() {
        let { selectedProgramme, publishProgramme } = this.state;
        selectedProgramme["programmeDetails"].disable = !selectedProgramme["programmeDetails"].disable;
        publishProgramme[selectedProgramme["programmeDetails"].name] = true;
        this.setState({ selectedProgramme, programmeDisablePopup: false });
    }
    openMenu(menu) {
        if (this.state.isNewChangesExist) {
            this.setState({ showDiscardChanges: true, newMenu: menu })
            return;
        }
        this.setState({ menuOpened: menu })
    }
    exportUserContactToExcel() {
        let { userContactData } = this.state;
        let userArray = [];
        for (var i = 0; i < userContactData.length; i++) {
            userArray.push({
                'Name': userContactData[i].name,
                "Email Address": userContactData[i].email,
                "Organization": userContactData[i].organization,
                "Role in Organization": userContactData[i].roleInOrganization,
                "Signed up on": userContactData[i].created? new Date(userContactData[i].created).toLocaleDateString(): ""
            })
        };
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let fileName = "userContact";
        const ws = XLSX.utils.json_to_sheet(userArray);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    handleDiscardYes = () => {
        const { newMenu } = this.state;
        this.setState({
            menuOpened: newMenu,
            newMenu: '',
            isNewChangesExist: false,
            showDiscardChanges: false
        })
    }
    render() {
        return (
            <AdminDashboardView
                {...this.state}
                redirect={this.redirect}
                openMenu={this.openMenu}
                selectProgramme={programmeObj => this.setState({ selectedProgramme: programmeObj })}
                publishProgram={this.publishProgramDB}
                handleBundle={this.handleBundle}
                changeProgramTxt={this.updateProgramTxt}
                checkPublishStatus={this.checkPublishStatus}
                flagProgram={this.flagProgram}
                OpenDisablePopup={() => this.setState({ programmeDisablePopup: true })}
                CloseDisablePopup={() => this.setState({ programmeDisablePopup: false })}
                ClosePublishProgramPopup={() => this.setState({ programmePublishPopup: false })}
                OpenPublishProgramPopup={() => this.setState({ programmePublishPopup: true })}
                openDownloadPopup={() => this.setState({ downloadPopup: true })}
                CloseDownloadPopup={() => this.setState({ downloadPopup: false })}
                downloadUserContact={() => this.exportUserContactToExcel()}
                onNewChangesMade={(value) => this.setState({ isNewChangesExist: value })}
                onDiscardYes={this.handleDiscardYes}
                onDiscardNo={() => this.setState({ showDiscardChanges: false })}
            />
        )
    }
}
export default AdminDashboardController;