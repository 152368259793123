import React from 'react'
import './dialog.scss'

export const Dialog = props => {
  return (
    <div className='gbl-dialog flex-col' role='dialog'>
      <div className='dialog-container'>
        {props.children}
        {Boolean(props.buttons) && (
          <div className='row btn-row'>
            {props.buttons.map(btn => {
              return (
                <button className='btn h6' onClick={btn.onClick}>
                  <span>{btn.text}</span>
                </button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export const DiscardChangeDialog = (props) => {
  return (
    <Dialog
      buttons={[{ text: 'No', onClick: props.onNo }, { text: 'Yes', onClick: props.onYes }]} >
      <div className='h9 heading'>Discard Changes</div>
      <div className='h7'>Once you discard these changes, it wouldn't be recovered again. Do you wish to proceed?</div>
    </Dialog>
  )
}
export const PublishChangesDialog = (props) => {
  return (
    <Dialog
      buttons={[{ text: 'No', onClick: props.onNo }, { text: 'Yes', onClick: props.onYes }]} >
      <div className='h9 heading'>Publish Changes</div>
      <div className='h7'>Once you publish these changes, it would reflect on <br /> the public domain. Do you wish to proceed?</div>
    </Dialog>
  )
}
export const AlertDialog = (props) => {
  return (
    <Dialog
      buttons={props.buttons || [{ text: 'Ok', onClick: props.onOk }]} >
      <div className='h9 heading'>{props.title}</div>
      <div className='h7'>{props.subtitle}</div>
    </Dialog>
  )
}
