import originAxios from "axios";

const HOST_SERVER = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/';
const ACCESS_TOKEN = "access-token";

const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json"
}

function errorhandler(errorRes) {
  const { response } = errorRes;
  if (response) {
    // eslint-disable-next-line no-throw-literal
    throw { status: errorRes.response.status, data: errorRes.response.data };
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { data: { message: "error", success: false, error: errorRes } };
  }
}

const axiosInst = originAxios.create({
  baseURL: HOST_SERVER,
});
class MyAxios {

  getHeaders(additional = {}) {
    let newheaders = {},
      authToken = localStorage.getItem(ACCESS_TOKEN);
    if (authToken) {
      newheaders['x-access-token'] = authToken
    }
    return { headers: { ...HEADERS, ...newheaders, ...additional } }

  }
  async responseHandler(response) {
    const accessToken = (response.headers && response.headers[ACCESS_TOKEN]) || (response.data && response.data.accessToken);
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN, accessToken)
    }
    return response.data
  }
  get(path, newheaders) {
    return axiosInst.get(HOST_SERVER + path, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler);
  }
  post(path, body, newheaders) {
    return axiosInst.post((HOST_SERVER + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  delete(path, newheaders) {
    return axiosInst.delete((HOST_SERVER + path), this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  put(path, body, newheaders) {
    return axiosInst.put((HOST_SERVER + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  patch(path, body, newheaders) {
    return new Promise((resolve, reject) => {
      this.getHeaders(newheaders)
        .then(headers => {
          if (body instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data'
          }
          return axiosInst.patch(HOST_SERVER + path, body, headers)
        })
        .then(res => {
          return this.responseHandler(res)
        }).then(resolve).catch(reject)
    }).catch(errorhandler)
  }
}


export const axios = new MyAxios();