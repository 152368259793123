//eslint-disable-next-line 
import React, { Component } from 'react';
import ContactView from '../../../../views/Users/Public/Contact/ContactView';

class ContactController extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <ContactView />
        )
    }
}
export default ContactController;