//eslint-disable-next-line
import React, { Component } from 'react'
import Header from '../../../components/Header/Header'
import Switch from 'react-switch'
import '../../../index.scss'
import './PageContentView.scss'
class HomeView extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div>
        <Header admin={true} />
        <hr style={{ border: '#f7941e 1px solid', margin: 'unset' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.15)'
          }}
        >
          <div
            className='h11 center-txt'
            style={{
              width: '12%',
              height: '100vh',
              border: '#F0F0F0 4px solid'
            }}
          >
            <button
              className='h11'
              style={{
                padding: '38px 28px',
                background: 'none',
                border: 'none'
              }}
              onClick={{}}
            >
              Programmes
            </button>
            <hr />
            <button
              className='h11'
              style={{
                padding: '38px 28px',
                background: 'none',
                border: 'none'
              }}
              onClick={{}}
            >
              User Contact
            </button>
            <hr />
            <button
              className='h11'
              style={{
                padding: '38px 28px',
                background: 'none',
                border: 'none'
              }}
              onClick={{}}
            >
              Change Password
            </button>
            <hr />
            <button
              className='h11'
              style={{
                padding: '38px 28px',
                background: 'none',
                border: 'none'
              }}
              onClick={{}}
            >
              Page Content
            </button>
            <hr />
            <button
              className='h11'
              style={{
                padding: '38px 28px',
                background: 'none',
                border: 'none'
              }}
              onClick={{}}
            >
              User Metrics
            </button>
            <hr />
          </div>
          <div className='gallery-bg left-txt' style={{ width: '428px' }}>
            <div className='h10 left-txt' style={{ padding: '50px 50px' }}>
              {' '}
              List of Programmes
            </div>
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Sanitary Pad Usage
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Free Iron tablets
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Peer Outreach
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              SRH Training
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Subsidized SRH services & products
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Likeskill Training
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
            <button
              className='h12 left-txt'
              style={{
                padding: '20px 0px 20px 50px',
                background: 'none',
                border: 'none'
              }}
            >
              Setup Factory Clinic
            </button>
            <hr style={{ border: '#FFF 1px solid', margin: 'unset' }} />
          </div>

          <div
            style={{
              padding: '127px 0 50px 55px',
              textAlign: 'left',
              position: 'relative',
              width: 'calc(100% - 600px)'
            }}
          >
            <button
              disabled
              className='h6 white-txt'
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                borderRadius: '26px',
                width: '200px',
                height: '48px',
                position: 'absolute',
                top: 80,
                right: 90,
                border: 'none',
                background: '#A3A3A3'
              }}
            >
              Publish changes
            </button>
            <div className='row buffer-bottom1'>
              <div className='h9'>PROGRAMME DETAILS</div>
              <button
                className='h6 treePoppy-txt'
                style={{
                  background: 'none',
                  border: 'none',
                  paddingLeft: '16px '
                }}
              >
                DISABLE PROGRAM
              </button>
            </div>

            <div className='h14 buffer-bottom1'>
              These details is displayed on the programme card shown to user
              while creating a projection.
            </div>
            <div className='buffer-bottom2'>
              <div className='h10' style={{ paddingBottom: '16px' }}>
                PROGRAM NAME
              </div>
              <textarea
                className='gallery-bg'
                style={{ width: '822px', marginBottom: '26px', border: 'none' }}
              ></textarea>
              <div className='h10' style={{ paddingBottom: '16px' }}>
                CATEGORY
              </div>
              <textarea
                className='gallery-bg'
                style={{ width: '822px', marginBottom: '26px', border: 'none' }}
              ></textarea>
              <div className='h10' style={{ paddingBottom: '16px' }}>
                TARGET
              </div>
              <textarea
                className='gallery-bg'
                style={{ width: '822px', marginBottom: '26px', border: 'none' }}
              ></textarea>
              <div className='h10' style={{ paddingBottom: '16px' }}>
                DESCRIPTION
              </div>
              <textarea
                className='gallery-bg'
                style={{
                  width: '822px',
                  height: '120px',
                  marginBottom: '26px',
                  border: 'none'
                }}
              ></textarea>
            </div>

            <div>
              <div className='h6 buffer-bottom1'>BUNDLE OPTIONS</div>
              <div className='h11 buffer-bottom2'>
                Programs that can be bundled with
              </div>
              <div className='h11' style={{ width: '561px' }}>
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Sanitary Pad Usage</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Peer outreach</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Lifeskill Training</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>SRH Training</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Subsidized SRH services & products </div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Setup Factory Clinics</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
                <div className='row spaceBetween' style={{ padding: '16px 0' }}>
                  <div>Free Iron Tablets</div>
                  <Switch
                    offHandleColor='#F7941E'
                    offColor='#E6E5D6'
                    onColor='#F7941E'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={this.props.OutreachChecked}
                  />
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
    )
  }
}
export default HomeView
