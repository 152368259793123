import React, { Component } from 'react';
import { Dialog, AlertDialog, PublishChangesDialog, DiscardChangeDialog } from "../../../components/Dialog";
import { axios } from "../../../utilities/axios";
import spin from "../../../assets/images/roi/spin.svg";
import close from "../../../assets/images/roi/close.svg";

const PAGES = [
  { title: 'Home Page', path: 'home' },
  { title: 'About', path: 'about' },
  { title: 'Programs', path: 'programs' },
  { title: 'ROIT Guide', path: 'roitGuide' },
  { title: 'Case Studies', path: 'caseStudies' },
  { title: 'FAQ', path: 'faq' },
]


export default class PageContents extends Component {
  state = {
    selected: '',
    pageData: {},
    enablePublish: false,
    showDiscardChanges: false,
    newPage: '',
    loading: false,
    success: false
  }
  componentDidMount() {

  }
  getPageData = () => {
    const { selected, pageData } = this.state;
    axios.get(`cms/postByPageName/${selected.path}`)
      .then((res) => {
        if (!res.cmsJSON) {
          res.cmsJSON = selected.path === 'faq' ? [{ question: '', answer: '' }] : {};
        }
        pageData[selected.path] = res
        this.setState({ pageData: { ...pageData } })
      })
      .catch(error => console.log('error', error));
  }
  onPageChange = (page) => {
    // const { selected, pageData } = this.state;
    if (this.state.enablePublish) {
      this.setState({ showDiscardChanges: true, newPage: page })
      return;
    }
    this.setState({
      selected: page,
      pageData: {}
    }, this.getPageData)
  }
  onDiscardConfirm = () => {
    const { newPage } = this.state;
    this.setState({
      showDiscardChanges: false,
      selected: newPage,
      enablePublish: false
    }, this.getPageData)
    if (this.props.onNewChangesMade) {
      this.props.onNewChangesMade(false)
    }
  }
  onPublishConfirm = () => {
    this.setState({ showPublishDialog: false, loading: true });
    const { selected, pageData } = this.state;
    let cmsJSON = pageData[selected.path].cmsJSON
    if (selected.path === 'faq') {
      cmsJSON = cmsJSON.map((faq) => {
        return { question: faq.question, answer: faq.answer, }
      })
    }
    const data = {
      pageName: pageData[selected.path].pageName,
      cmsJSON: cmsJSON
    }
    axios.post(`cms/updatePost/${pageData[selected.path]._id}`, data)
      .then((res) => {
        this.setState({
          enablePublish: false,
          loading: false,
          success: true,
          title: 'Success!',
          subtitle: 'Changes have been successfully published.'
        });
        if (this.props.onNewChangesMade) {
          this.props.onNewChangesMade(false)
        }
      })
      .catch(er => {
        console.error(er);
        this.setState({
          loading: false,
          success: true,
          title: 'Error!',
          subtitle: 'Error While publishing the changes.'
        });
      });
  }
  onContentChange = (e) => {
    const { selected, pageData } = this.state
    const { name, value } = e.target;
    pageData[selected.path].cmsJSON[name] = value;
    this.setState({
      pageData: { ...pageData },
      enablePublish: true
    });
    if (this.props.onNewChangesMade) {
      this.props.onNewChangesMade(true)
    }
  }
  onFAQChange = (e, i) => {
    const { selected, pageData } = this.state;
    const { name, value } = e.target;
    pageData[selected.path].cmsJSON[i][name] = value;
    this.setState({ pageData: { ...pageData }, enablePublish: true });
    if (this.props.onNewChangesMade) {
      this.props.onNewChangesMade(true)
    }
  }
  onAddNewFAQ = () => {
    const { selected, pageData } = this.state;
    pageData[selected.path].cmsJSON.push({ question: "", answer: '', newlyAdded: true })
    this.setState({ pageData: { ...pageData } });
  }
  onRemoveFAQ = () => {
    const { selected, pageData, selectedFAQ } = this.state;
    if (selectedFAQ >= 0) {
      const newlyAdded = pageData[selected.path].cmsJSON[selectedFAQ].newlyAdded
      pageData[selected.path].cmsJSON.splice(selectedFAQ, 1);
      this.setState({ pageData: { ...pageData }, enablePublish: !newlyAdded, deleteFAQ: false });
    }
  }
  onPublish = () => {
    const { selected, pageData } = this.state;
    if (selected.path === 'faq') {
      const isEmpty = pageData[selected.path].cmsJSON.filter((faq) => {
        return String(faq.question).trim() === '' || String(faq.answer).trim() === '';
      }).length > 0
      if (isEmpty) {
        this.setState({
          title: 'Add content',
          subtitle: 'FAQ section is empty. Add content or delete section to proceed.',
          success: true
        })
        return;
      }
    }
    this.setState({ showPublishDialog: true })
  }
  render() {
    const state = this.state;
    return (
      <div className='flex-col page-content'>
        <div className='row page-content-container'>
          <div className="gallery-bg left-txt program-menu"  >
            <div className="h10 left-txt program-menu-header">PAGES</div>
            {PAGES.map((page) =>
              <div key={page.path}>
                <button className={`h12 left-txt programs-titles ${state.selected && state.selected.path === page.path ? 'selected' : ''}`} onClick={() => this.onPageChange(page)}>{page.title}</button>
                <hr className="program-menu-hr" />
              </div>
            )}
          </div>
          {
            Boolean(state.selected) &&
            <div className='flex-col flex-full' style={{ overflow: 'hidden', height: '100%' }}>
              {
                Boolean(state.pageData[state.selected.path]) ?
                  <div className='page-input-container program-display program-display-active'>
                    <button disabled={!state.enablePublish} className="h6 white-txt publish-btn" onClick={this.onPublish}>Publish changes</button>

                    {
                      state.selected.path === 'faq' ?
                        <>
                          <div className="row buffer-bottom1">
                            <div className="h9">CONTENT - FAQs : Frequently Asked Questions</div>
                            <button className='btn-add-faq' onClick={this.onAddNewFAQ}>
                              <span className='h10'>ADD FAQ</span>
                            </button>
                          </div>
                          <div className='faq-list inputs'>
                            {
                              state.pageData[state.selected.path].cmsJSON.map((faq, i) => {
                                return (
                                  <div key={i} className='faq-card'>
                                    <button className='btn-close' onClick={() => this.setState({ deleteFAQ: true, selectedFAQ: i })}>
                                      <img src={close} alt='close' />
                                    </button>
                                    <div className='h10 faq-head'>Question</div>
                                    <input className='h11 faq-input' placeholder='Start typing..' value={faq.question} name='question' onChange={(e) => this.onFAQChange(e, i)} />
                                    <div className='h10 faq-head'>Answer</div>
                                    <textarea
                                      rows={5}
                                      placeholder='Start typing..'
                                      className='h11 faq-input'
                                      value={faq.answer} name='answer' onChange={(e) => this.onFAQChange(e, i)} ></textarea>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </>
                        :
                        <>
                          <div className="row buffer-bottom1">
                            <div className="h9">CONTENT - {state.selected.title}</div>
                          </div>
                          <div className="inputs">
                            {
                              Object.keys(state.pageData[state.selected.path].cmsJSON).map((key) => {
                                return (
                                  <div key={key}>
                                    <textarea
                                      rows={3}
                                      className={"gallery-bg program-input h11 page-input"}
                                      type="text"
                                      name={key}
                                      value={state.pageData[state.selected.path].cmsJSON[key]}
                                      onChange={this.onContentChange} ></textarea>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </>
                    }
                  </div>
                  :
                  <div className='flex-col flex-full' style={{ justifyContent: 'center' }}>Please wait</div>
              }
            </div>
          }
        </div>
        {
          state.showPublishDialog &&
          <PublishChangesDialog onNo={() => this.setState({ showPublishDialog: false })} onYes={this.onPublishConfirm} />
        }
        {
          state.showDiscardChanges &&
          <DiscardChangeDialog onNo={() => this.setState({ showDiscardChanges: false })} onYes={this.onDiscardConfirm} />
        }
        {
          state.loading &&
          <Dialog>
            <img alt='spin' src={spin} className='height48' />
          </Dialog>
        }
        {
          state.deleteFAQ &&
          <AlertDialog
            title='Delete FAQ?'
            subtitle='Once you delete this FAQ, you will lose the content in this section.'
            buttons={[
              { text: 'No', onClick: () => this.setState({ deleteFAQ: false }) },
              { text: 'Yes', onClick: this.onRemoveFAQ }
            ]}
            onOk={() => this.setState({ success: false })} />
        }
        {
          state.success &&
          <AlertDialog title={state.title} subtitle={state.subtitle} onOk={() => this.setState({ success: false })} />
        }
      </div>

    )
  }
}