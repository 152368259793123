//eslint-disable-next-line 
import React, { Component } from 'react';
import GBLAdminView from '../../../views/Admin/Login/GBLAdminView'
import { axios } from "../../../utilities/axios";

class GBLAdminController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailError: '',
            passwordError: ''
        };
        this.redirect = this.redirect.bind(this);
    }
    redirect(path) {
        return this.props.history.push(path);
    }
    handleLoginSubmit = async (e, name) => {
        const { email, password } = this.state;
        if (!email) {
            return this.setState({ emailError: 'required' })
        }
        if (!password) {
            return this.setState({ passwordError: 'required' })
        }
        try {
            const loginres = await axios.post('auth/signin', { username: email, password: password })
            if (loginres.accessToken) {
                await axios.get('test/admin');
                localStorage.setItem("at",loginres.accessToken)
                this.redirect('gbla/dashboard')
            } else {
                alert('Access Token Empty')
            }
        } catch (e) {
            console.error('error', e);
            if (e.data) {
                if (e.data.message === "Invalid Password!") {
                    return this.setState({ passwordError: e.data.message })
                } else {
                    return this.setState({ emailError: e.data.message })
                }
            } else {
                alert('Admin LogIn Failed');
            }
        }
    }
    render() {
        return (
            <GBLAdminView
                {...this.state}
                redirect={this.redirect}
                onLoginSubmit={this.handleLoginSubmit}
                onChangeEmail={(e) => this.setState({ email: e.target.value, emailError: '' })}
                onChangePassword={(e) => this.setState({ password: e.target.value, passwordError: '' })}
            />
        )
    }
}
export default GBLAdminController;