//eslint-disable-next-line
import React, { Component } from 'react'
import { DiscardChangeDialog } from '../../../components/Dialog'
import Header from '../../../components/Header/Header'
import Switch from 'react-switch'
import '../../../index.scss'
import './AdminDashboardView.scss'
import userImg from '../../../assets/images/UserImg.png'
import PageContentsView from './PageContentsView'

class HomeView extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div className='flex-col gbl-admin'>
        <Header admin={true} />
        <hr className='header-hr' />
        <div className='dashboard flex-full'>
          <div className='row container'>
            <div className='h11 center-txt db-menu'>
              <button
                className={
                  this.props.menuOpened === 'programmes'
                    ? 'h10 treePoppy-txt'
                    : 'h11 gallery-txt'
                }
                onClick={() => this.props.openMenu('programmes')}
              >
                Programmes
              </button>
              <hr
                className={
                  this.props.menuOpened === 'programmes'
                    ? 'program-menu-hr-selected'
                    : 'program-menu-hr'
                }
              />
              <button
                className={
                  this.props.menuOpened === 'userContact'
                    ? 'h10 treePoppy-txt'
                    : 'h11 gallery-txt'
                }
                onClick={() => this.props.openMenu('userContact')}
              >
                User Contact
              </button>
              <hr
                className={
                  this.props.menuOpened === 'userContact'
                    ? 'program-menu-hr-selected'
                    : 'program-menu-hr'
                }
              />
              {/* <button
                className={
                  this.props.menuOpened === 'pageContent'
                    ? 'h10 treePoppy-txt'
                    : 'h11 gallery-txt'
                }
                onClick={() => this.props.openMenu('pageContent')}
              >
                Page Content
              </button>
              <hr
                style={{
                  border:
                    this.props.menuOpened === 'pageContent'
                      ? '3px solid #F7941E'
                      : '1px solid #A3A3A3'
                }}
              />
              <button
                className={
                  this.props.menuOpened === 'userMetrics'
                    ? 'h10 treePoppy-txt'
                    : 'h11 gallery-txt'
                }
                onClick={() => this.props.openMenu('userMetrics')}
              >
                User Metrics
              </button>
              <hr
                style={{
                  border:
                    this.props.menuOpened === 'userMetrics'
                      ? '3px solid #F7941E'
                      : '1px solid #A3A3A3'
                }}
              /> */}
            </div>

            <div
              className={
                'gallery-bg ' +
                (this.props.menuOpened === 'programmes'
                  ? 'program-menu-active'
                  : 'program-menu-hide')
              }
            >
              <div className='left-txt program-menu'>
                <div className='h10 left-txt program-menu-header'>
                  {' '}
                  List of Programmes
                </div>

                {this.props.programmeData.map((value, index) => (
                  <div key={index}>
                    <button
                      className={'h12 left-txt programs-titles '}
                      style={{
                        width: '100%',
                        background:
                          this.props.selectedProgramme === value
                            ? '#f7941e'
                            : 'transparent',
                        color:
                          this.props.selectedProgramme === value
                            ? '#fff'
                            : '#222222'
                      }}
                      onClick={() => this.props.selectProgramme(value)}
                    >
                      {value['programmeDetails'].name}
                    </button>
                    <hr className='program-menu-hr' />
                  </div>
                ))}
              </div>
            </div>
            {this.props.menuOpened === 'programmes' &&
            this.props.selectedProgramme ? (
              <div
                className={
                  'program-display ' +
                  (this.props.selectedProgramme
                    ? 'program-display-active'
                    : 'program-display-hide')
                }
              >
                <button
                  disabled={this.props.checkPublishStatus()}
                  className='h6 white-txt publish-btn-program publish-btn'
                  onClick={() => this.props.OpenPublishProgramPopup()}
                >
                  Publish changes
                </button>
                <div className='row buffer-bottom1'>
                  <div className='h9'>PROGRAMME DETAILS</div>
                  <button
                    className='h6 treePoppy-txt disable-prgm-btn'
                    onClick={() => this.props.flagProgram()}
                  >
                    {this.props.selectedProgramme['programmeDetails'].disable
                      ? 'ENABLE PROGRAM'
                      : 'DISABLE PROGRAM'}
                  </button>
                </div>

                <div className='h14 buffer-bottom1'>
                  These details will be displayed on the programme card shown to
                  the user while creating a projection
                </div>
                <div className='buffer-bottom2'>
                  <div
                    className={
                      'h10 buffer-bottom1 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    PROGRAMME NAME
                  </div>
                  <input
                    className={
                      'gallery-bg program-input h11 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                    disabled={
                      this.props.selectedProgramme['programmeDetails'].disable
                    }
                    type='text'
                    value={
                      this.props.selectedProgramme['programmeDetails'].name ||
                      ''
                    }
                    onChange={event =>
                      this.props.changeProgramTxt(event.target.value, 'name')
                    }
                  />

                  <div
                    className={
                      'h10 buffer-bottom1 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    CATEGORY
                  </div>
                  <input
                    className={
                      'gallery-bg program-input h11 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                    disabled={
                      this.props.selectedProgramme['programmeDetails'].disable
                    }
                    type='text'
                    value={
                      this.props.selectedProgramme['programmeDetails']
                        .category || ''
                    }
                    onChange={event =>
                      this.props.changeProgramTxt(
                        event.target.value,
                        'category'
                      )
                    }
                  />
                  <div
                    className={
                      'h10 buffer-bottom1 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    TARGET
                  </div>
                  <input
                    className={
                      'gallery-bg program-input h11 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                    disabled={
                      this.props.selectedProgramme['programmeDetails'].disable
                    }
                    type='text'
                    value={
                      this.props.selectedProgramme['programmeDetails'].target ||
                      ''
                    }
                    onChange={event =>
                      this.props.changeProgramTxt(event.target.value, 'target')
                    }
                  />
                  <div
                    className={
                      'h10 buffer-bottom1 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    DESCRIPTION
                  </div>
                  <textarea
                    maxlength='150'
                    className={
                      'gallery-bg program-input h11 program-desc ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                    disabled={
                      this.props.selectedProgramme['programmeDetails'].disable
                    }
                    type='text'
                    value={
                      this.props.selectedProgramme['programmeDetails']
                        .description || ''
                    }
                    onChange={event =>
                      this.props.changeProgramTxt(
                        event.target.value,
                        'description'
                      )
                    }
                  />
                </div>

                <div>
                  <div
                    className={
                      'h6 buffer-bottom1 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    BUNDLE OPTIONS
                  </div>
                  <div
                    className={
                      'h11 buffer-bottom2 ' +
                      (this.props.selectedProgramme['programmeDetails'].disable
                        ? 'program-input-disabled'
                        : 'program-input-enabled')
                    }
                  >
                    Programmes that can be bundled with{' '}
                    {this.props.selectedProgramme['programmeDetails'].name ||
                      ''}
                  </div>
                  <div className='h11 program-bundle'>
                    {this.props.selectedProgramme[
                      'programmeDetails'
                    ].bundleOptions.map((bundle, index) => (
                      <div key={index}>
                        <div className='row spaceBetween bundle-pad'>
                          <div
                            className={
                              'h11 ' +
                              (this.props.selectedProgramme['programmeDetails']
                                .disable
                                ? 'program-input-disabled'
                                : 'program-input-enabled')
                            }
                          >
                            {bundle.name}
                          </div>
                          <Switch
                            offHandleColor='#F7941E'
                            offColor='#E6E5D6'
                            onColor='#F7941E'
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={() => this.props.handleBundle(bundle)}
                            checked={bundle.isBundled}
                            disabled={
                              this.props.selectedProgramme['programmeDetails']
                                .disable
                            }
                          />
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className='pop-up-container'
                  style={{
                    display: this.props.programmeDisablePopup ? 'block' : 'none'
                  }}
                >
                  <div className='pop-ups'>
                    <div className='h9 buffer-bottom1 mineShaft-txt'>
                      {this.props.selectedProgramme['programmeDetails'].disable
                        ? 'Enable Programme'
                        : 'Disable Programme'}
                    </div>

                    <div className='h11 buffer-bottom2 mineShaft-txt'>
                      {this.props.selectedProgramme['programmeDetails'].disable
                        ? 'Once you enable this programme, it will be visible on the public domain. Do you wish to proceed?'
                        : 'Once you disable this programme, it will not be visible on the public domain. Are you sure?'}
                    </div>
                    <div style={{ float: 'right' }}>
                      <button
                        className='h6 pop-ups-btns'
                        onClick={() => this.props.CloseDisablePopup()}
                      >
                        No
                      </button>
                      <button
                        className='h6 pop-ups-btns'
                        onClick={() => this.props.flagProgram()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className='pop-up-container'
                  style={{
                    display: this.props.programmePublishPopup ? 'block' : 'none'
                  }}
                >
                  <div
                    className='pop-ups'
                    style={{
                      display: this.props.programmePublishPopup
                        ? 'block'
                        : 'none'
                    }}
                  >
                    <div className='h9 buffer-bottom1 mineShaft-txt'>
                      Publish Changes
                    </div>
                    <div className='h11 buffer-bottom2 mineShaft-txt'>
                      Once you publish these changes, it would reflect on the
                      public domain. Do you wish to proceed?
                    </div>
                    <div style={{ float: 'right' }}>
                      <button
                        className='h6 pop-ups-btns'
                        onClick={() => this.props.ClosePublishProgramPopup()}
                      >
                        No
                      </button>
                      <button
                        className='h6 pop-ups-btns'
                        onClick={() =>
                          this.props.publishProgram(
                            this.props.selectedProgramme['programmeDetails']
                              .name
                          )
                        }
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div
              style={{
                display:
                  this.props.menuOpened === 'userMetrics' ? 'block' : 'none',
                padding: '7.938rem 23.75rem 3.125rem 3.125rem'
              }}
            >
              <div className='h9 row'>
                Google Analytics
                <a
                  href='https://accounts.google.com/signin/v2/identifier?service=analytics&passive=1209600&continue=https%3A%2F%2Fanalytics.google.com%2Fanalytics%2Fweb%2F%23&followup=https%3A%2F%2Fanalytics.google.com%2Fanalytics%2Fweb%2F&flowName=GlifWebSignIn&flowEntry=ServiceLogin'
                  rel='noreferrer'
                  target='_blank'
                >
                  <button className='analytics-btn h6'>View Now</button>
                </a>
              </div>
            </div>
            <div
              className={
                'userContact-display ' +
                (this.props.menuOpened === 'userContact'
                  ? 'userContact-active'
                  : 'userContact-hide')
              }
            >
              <button
                className='h6 white-txt publish-btn'
                onClick={() => this.props.openDownloadPopup()}
              >
                Download List
              </button>
              <div className='h9 left-txt buffer-bottom2'>List of Users</div>
              <table className='userContact-table'>
                <thead>
                  <tr>
                    <th className='h10 userContact-table-th center-txt'>
                      Name
                    </th>
                    <th className='h10 userContact-table-th left-txt'>
                      Email Address
                    </th>
                    <th className='h10 userContact-table-th left-txt'>
                      Organization
                    </th>
                    <th className='h10 userContact-table-th left-txt'>
                      Role in Organization
                    </th>
                    <th className='h10 userContact-table-th left-txt'>
                      Signed up on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.userContactData.slice(0).reverse().map((value, index) => (
                    <tr key={index}>
                      <td className='h11 userContact-td '>
                        <div className='row table-row-padding'>
                          <img src={userImg} alt='User profile' />
                          <div className='userName'>
                            {' '}
                            {value.name && value.name}
                          </div>
                        </div>
                      </td>
                      <td className='h13 userContact-td '>
                        {value.email && value.email}
                      </td>
                      <td className='h13 userContact-td '>
                        {value.organization && value.organization}
                      </td>
                      <td className='h13 userContact-td '>
                        {value.roleInOrganization && value.roleInOrganization}
                      </td>
                      <td className='h13 userContact-td '>
                        {value.created
                          ? new Date(value.created).toLocaleDateString()
                          : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className='pop-up-container'
                style={{
                  display: this.props.downloadPopup ? 'block' : 'none'
                }}
              >
                <div
                  className='pop-ups'
                  style={{
                    display: this.props.downloadPopup ? 'block' : 'none'
                  }}
                >
                  <div className='h9 buffer-bottom1 mineShaft-txt left-txt'>
                    Download list
                  </div>
                  <div className='h11 buffer-bottom2 mineShaft-txt left-txt'>
                    User details would be downloaded as a excel sheet. Do you
                    wish to proceed?
                  </div>
                  <div style={{ float: 'right' }}>
                    <button
                      className='h6 pop-ups-btns'
                      onClick={() => this.props.CloseDownloadPopup()}
                    >
                      No
                    </button>
                    <button
                      className='h6 pop-ups-btns'
                      onClick={() => this.props.downloadUserContact()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>            
            {this.props.menuOpened === 'pageContent' && (
              <PageContentsView
                onNewChangesMade={this.props.onNewChangesMade}
              />
            )}
            {this.props.showDiscardChanges && (
              <DiscardChangeDialog
                onNo={this.props.onDiscardNo}
                onYes={this.props.onDiscardYes}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default HomeView
