import RIOTGuideView from '../../../../views/Users/Public/RIOTGuide/RIOTGuideView';
import { useSelector, connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

function RIOTGuideController(props) {
    let history = useHistory();
    let local = JSON.parse(localStorage.getItem('udgbl'));
    let showLogin = true;
    let accessToken = '';
    if (local) {
        accessToken = local.accessToken;
        showLogin = !(accessToken && accessToken !== "");

    }

    const riotContent = useSelector(state => {
        if (state.loadCMS.cms[2]) {
            return state.loadCMS.cms[2]["cmsJSON"];
        } else {
            return state.loadCMS;
        }
    });
    useEffect(() => {
        const view = props.location.hash;
        const FAQBox = document.getElementById('FAQ');

        if (view && view.includes("#FAQ")) {
            FAQBox.scrollIntoView();
        } else if (view && view.includes("#projectionName")) {
            document.getElementById("projectionName").scrollIntoView();
        } else if (view && view.includes("#guide")) {
            document.getElementById("guide").scrollIntoView();
        }
        else {
            document.getElementById("roitTop").scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goLogin = () => {
        if (showLogin) {
            return history.push("/login");

        } else {
            return history.push("/projections");
        }
    }
    return (
        <div>
            <RIOTGuideView
                {...riotContent}
                goLogin={goLogin}
            />
        </div>
    )

}

export default connect()(RIOTGuideController);

