import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import UNFPALogo from '../../assets/images/UNFPA Logo.png'
import UNLogo from '../../assets/images/UN Logo.png'
import MERCKLogo from '../../assets/images/MERCK Logo.png'
import './Footer.scss'
import '../../index.scss'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <footer className='mineShaft-bg'>
        <div className='footer-sections1'>
          <div>
            <div>
              <img src={UNFPALogo} alt='UNFPA Logo' />
            </div>
          </div>
          <div className='row footer-row'>
            <div className='footerLink-container h7'>
              <Link className='footer-links' to='/'>
                HOME
              </Link>
              <Link className='footer-links' to='/about'>
                ABOUT
              </Link>
              <Link className='footer-links' to='/programmes'>
                PROGRAMMES
              </Link>
              <Link className='footer-links' to='/roi-tGuide'>
                ROI-T GUIDE
              </Link>
            </div>
            <div className='footerLink-container h7'>
              <Link className='footer-links' to='/roi-tGuide#FAQ'>
                FAQ
              </Link>
              <Link className='footer-links' to='/caseStudies'>
                CASE STUDIES
              </Link>
              <Link className='footer-links' to='/privacy'>
                PRIVACY
              </Link>
              <Link className='footer-links' to='/contact'>
                CONTACT
              </Link>
            </div>
          </div>
          <div className='partners'>
            <div className='white-txt h7 left-txt buffer-bottom1'>
              Our Partners and Donor
            </div>
            <div className='partner-img'>
              <div>
                <img src={UNLogo} alt='UN Logo' />
              </div>
              <div>
                <img src={MERCKLogo} alt='MERCK Logo' />
              </div>
            </div>
          </div>
        </div>
        <div className='footer-sections2'>
          <div className='white-txt h7'>© All rights reserved. 2021</div>
          <div className='white-txt h7 left-txt'>
            Credits to our research and creative partners-
            <br />
            <span>
              <a
                className='treePoppy-txt'
                href='https://www.goodbusinesslab.org/'
              >
                Good Business Lab
              </a>
            </span>{' '}
            and{' '}
            <span>
              <a className='treePoppy-txt' href='https://www.icarusnova.com/'>
                IcarusNova
              </a>
            </span><br /><br />
            <div className='white-txt h7 left-txt'>
              Homepage Photo Credits - Celeste Hibbert / UNFPA APRO
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
