//eslint-disable-next-line 
import React, { Component } from 'react';
import PrivacyView from '../../../../views/Users/Public/Privacy/PrivacyView';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';

function PrivacyController() {
    // const aboutContent = useSelector(state => state.loadCMS);
    let history = useHistory();
    // const aboutContent = useSelector(state => {
    //     if (state.loadCMS.cms[1]) {
    //         return state.loadCMS.cms[1]["cmsJSON"];
    //     } else {
    //         return state.loadCMS;
    //     }
    // });
    useEffect(() => {

        document.getElementById("privacyTop").scrollIntoView();

    }, []);
    const redirect = (path) => {
        return history.push(path);
    }
    return (
        <PrivacyView
            // {...aboutContent}
            redirect={redirect} />
    );
}
export default connect()(PrivacyController);