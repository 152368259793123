import React from 'react'
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import google from '../../assets/images/login/google.svg'
import microsoft from '../../assets/images/login/microsoft.svg'
import linkedin from '../../assets/images/login/linkedin.svg'


const GOOGLE_CLIENT_ID = "15076494351-cis6jp777h1u0uh3jk4npjj6brkjo3o6.apps.googleusercontent.com";
const LINKEDIN_CLIENT_ID = "86a2fmlm0n99v3";
const MICROSOFT_AZURE_CLIENTID = 'bbb420d2-3b55-4b4a-8b3a-5dff700a5575';

export const GoogleLoginProvider = (props) => {
  const onSuccess = (data) => {
    props.onSuccess && props.onSuccess({
      from: "GOOGLE",
      data: {
        email: data.profileObj.email,
        fullName: data.profileObj.name,
        profilePicture: data.profileObj.imageUrl,
        id: data.profileObj.googleId
      }
    })
  }
  const onFailure = (err) => {
    props.onError && props.onError({
      from: "GOOGLE",
      error: err
    })
  }
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      redirectUri="https://gbl-roi-tool.firebaseapp.com/callback"
      render={(gp) => {
        return <img onClick={gp.onClick} className={props.className || ""} src={google} alt="google login" />
      }}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export const MicrosoftProvider = (props) => {
  const authHandler = function (err, data) {
    if (err) {
      props.onError && props.onError({
        from: "MICROSOFT",
        error: err
      });
      return;
    }
    props.onSuccess && props.onSuccess({
      from: "MICROSOFT",
      data: {
        email: data.account.userName,
        fullName: data.account.name,
        profilePicture: '',
        id: data.account.accountIdentifier
      }
    })
  }
  return (
    <MicrosoftLogin
      response_type='code'
      redirectUri={`${window.location.origin}/linkedin`}
      className={props.className} clientId={MICROSOFT_AZURE_CLIENTID}
      authCallback={authHandler}>
      <img src={microsoft} alt="microsoft login" />
    </MicrosoftLogin>
  )
}

export const LinkedInProvider = (props) => {
  const onSuccess = (r) => {
    props.onSuccess && props.onSuccess({
      from: "LINKEDIN",
      data: {
        code: r.code
      }
    })
  }
  const onFailure = (e) => {
    props.onError && props.onError({
      from: "LINKEDIN",
      error: e
    });
  }
  return (
    <LinkedIn
      clientId={LINKEDIN_CLIENT_ID}
      onFailure={onFailure}
      onSuccess={onSuccess}
      scope="r_emailaddress"
      className={props.className}
      redirectUri={`${window.location.origin}/linkedin`}
      redirectPath='/linkedin'>
      <img src={linkedin} alt="linkedin login" />
    </LinkedIn>
  )
}