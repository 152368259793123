import React from 'react'
import './App.css'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getcms } from './actions/cmsActions'
import { Route } from 'react-router-dom'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import Home from './controllers/Users/Public/Home/HomeController'
import About from './controllers/Users/Public/About/AboutController'
import Programs from './controllers/Users/Public/Programs/ProgramsController'
import RiotGuide from './controllers/Users/Public/RIOTGuide/RIOTGuideController'
import CaseStudies from './controllers/Users/Public/CaseStudies/CaseStudiesController'
import Contact from './controllers/Users/Public/Contact/ContactController'
import Privacy from './controllers/Users/Public/Privacy/PrivacyController'
import Login from './controllers/Users/ROITool/Login/LoginController'
import Projections from './controllers/Users/ROITool/Projections/ProjectionsController'
import Programmes from './controllers/Users/ROITool/Programmes/ProgrammesController'
import Company from './controllers/Users/ROITool/Company/CompanyController'
import Input from './controllers/Users/ROITool/Input/InputController'
import Roi from './controllers/Users/ROITool/Roi/RoiController'
import GBLAdminController from './controllers/Admin/Login/GBLAdminController'
import AdminDashboardController from './controllers/Admin/CMS/AdminDashboard/AdminDashboardController'
import PageContentController from './controllers/Admin/CMS/PageContent/PageContentController'

function App() {
  //Load CMS when App Load
  const dispatch = useDispatch()
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(
      window.__RUNTIME_CONFIG__.REACT_APP_API_URL+'/api/cms/allPosts',
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        if (result) {
          dispatch(getcms(JSON.parse(result)))
        }
      })
      .catch(error => console.log('error', error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='App'>
      <div>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/programmes' component={Programs} />
        <Route exact path='/roi-tGuide' component={RiotGuide} />
        <Route exact path='/caseStudies' component={CaseStudies} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/projections' component={Projections} />
        <Route exact path='/select-programmes' component={Programmes} />
        <Route exact path='/company' component={Company} />
        <Route exact path='/input' component={Input} />
        <Route exact path='/roi' component={Roi} />
        <Route exact path='/gbla' component={GBLAdminController} />
        <Route
          exact
          path='/gbla/dashboard'
          component={AdminDashboardController}
        />
        <Route
          exact
          path='/gbla/pageContent'
          component={PageContentController}
        />
        <Route exact path='/linkedin' component={LinkedInPopUp} />
      </div>
    </div>
  )
}

export default App
