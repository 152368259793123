
import { Link } from 'react-router-dom';
import React, { Component } from "react";
import UNFPALogo from '../../assets/images/UNFPA Logo.png';
import ROITLogo from '../../assets/images/ROI-T Logo.png';
import './Header.scss';
import '../../index.scss'
import Button from '../Button/Button'
import hamburger from '../../assets/images/hamburger-btn.png'
import closeBtn from '../../assets/images/close-btn.png'
import downArrow from '../../assets/images/downArrowGrey.png'
import defaultPic from '../../assets/images/login/defaultPic.png';


class Header extends Component {

    constructor(props) {
        super(props);
        let local = JSON.parse(localStorage.getItem('udgbl'));
        let username = '';
        let showLogin = true;
        let base64LogoLocal = '';
        let accessToken = '';
        if (local) {
            username = local.username;
            accessToken = local.accessToken;
            showLogin = !(accessToken && accessToken !== "");
            base64LogoLocal = local.base64Logo;

        }
        this.state = {
            openMenu: false,
            local: local,
            username: username,
            showLogin: showLogin,
            showUserOptions: false,
            base64Logo: base64LogoLocal !== '' ? base64LogoLocal : defaultPic
        };

        if(this.props.admin && !localStorage.getItem("at"))
        {
            this.redirect("/")
        }
    }
    redirect(path) {
        if (window.location.pathname === '/projections') {
            if (path === '/projections#editProfile' || path === '/projections#changePassword') {
                window.location.href = path;
                window.location.reload(false);
            }
        } else {
            window.location.href = path;
        }
    }

    redirectToLogin = () => {
        window.location.href = '/login'
    }
    showUserOptionsFunc = () => {
        let { showUserOptions } = this.state
        showUserOptions = !showUserOptions
        this.setState({ showUserOptions })
    }
    logout = () => {
        localStorage.setItem('udgbl', null);
        this.redirectToLogin();
    }
    adminLogout = () => {
        localStorage.setItem("at",null)
        window.history.go(-1);
        this.redirect('/gbla')
    }

    render() {
        let currentPath = window.location.pathname;
        let state = this.state;

        return (
            <header className='row header-content'>
                <div className="row header-logos pointer" onClick={() => this.redirect('/')}>
                    <div>
                        <img src={ROITLogo} alt="ROIT Logo" /></div>
                    <div>
                        <img style={{ paddingLeft: "3rem" }} src={UNFPALogo} alt="UNFPA Logo" />
                    </div>
                </div>
                <div className="hamburger">
                    <button className="button-icon" onClick={() => this.setState({ openMenu: true })}>
                        <img src={hamburger} alt="Open Menu" />
                    </button>
                </div>
                <div className=" row mobile-hide" style={{ display: !this.props.admin ? "none" : "flex" }}>
                       <Button onClick={() => {
                            this.adminLogout()
                        }} className="h5 login-btn pointer"
                            buttonText="Logout" style={{ width: "15vw" }}
                        />
                </div>
                <div className=" row mobile-hide" style={{ display: this.props.admin ? "none" : "flex" }}>
                    <div className=" link-container">
                        <Link className={'header-links ' + (currentPath === "/" ? "h6" : "h7")} to="/">HOME</Link>
                        <Link className={'header-links ' + (currentPath === "/about" ? "h6" : "h7")} to="/about">ABOUT</Link>
                        <Link className={'header-links ' + (currentPath === "/programmes" ? "h6" : "h7")} to="/programmes">PROGRAMMES</Link>
                        <Link className={'header-links ' + (currentPath === "/roi-tGuide" ? "h6" : "h7")} to="/roi-tGuide">ROI-T GUIDE</Link>
                        <Link className={'header-links ' + (currentPath === "/caseStudies" ? "h6" : "h7")} to="/caseStudies">CASE STUDIES</Link>
                    </div>
                    <div>{this.state.showLogin ?

                        <Button onClick={() => {
                            this.redirectToLogin()
                        }} className="h5 login-btn pointer"
                            buttonText="Login | SignUp" style={{ width: "15vw" }}
                        />
                        :
                        <div className="row">

                            <img className="profilePic" src={state.base64Logo} alt="Your profile" />
                            <button className="profile-btn" onClick={() => {
                                this.showUserOptionsFunc();
                            }}>
                                <img id="profileDropDown" style={{ transform: this.state.showUserOptions ? "rotate(180deg)" : "rotate(0deg)" }} src={downArrow} alt="Click for user profile options" />
                            </button>
                        </div>
                    }
                    </div>


                </div>
                {
                    this.state.openMenu &&
                    <div className="float-menu-container">
                        <div className="flex-col white-bg h3 float-menu" >
                            <div className="close-btn "><button className="button-icon " onClick={() => this.setState({ openMenu: false })}><img src={closeBtn} alt="Close Menu" /></button></div>
                            <Link className='mobile-links ' to="/">HOME</Link>
                            <Link className='mobile-links ' to="/about">ABOUT</Link>
                            <Link className='mobile-links ' to="/programs">PROGRAMMES</Link>
                            <Link className='mobile-links ' to="/roi-tGuide">ROI-T GUIDE</Link>
                            <Link className='mobile-links' to="/caseStudies">CASE STUDIES</Link>
                            <Link className='mobile-links' to="/login">LOGIN/SIGNUP</Link>

                        </div>
                    </div>
                }
                {
                    this.state.showUserOptions && (
                        <div className='h5 userOptions emperor-bg left-txt'>
                            <button className='h5 white-txt left-txt profile-dropDown' onClick={() => { this.redirect('/projections') }}>

                                My Projections

                            </button>
                            <button
                                className='h5 white-txt left-txt profile-dropDown'
                                onClick={() => this.redirect('/projections#editProfile')}
                            >Edit profile
                            </button>
                            <button
                                className='h5 white-txt left-txt profile-dropDown'
                                onClick={() => this.redirect('/projections#changePassword')}
                            >Change Password
                            </button>
                            <button className='h5 white-txt left-txt profile-dropDown' onClick={() => this.logout()}>
                                Logout
                            </button>
                        </div>
                    )
                }
            </header >);

    }
}

Header.defaultProps = {
    admin: false

}

export default Header;